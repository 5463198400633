import type { AnimationMixer, AnimationAction } from 'three';

import { Assets } from '~core/client/assets';
import { Logger } from '~core/client/logger';

import type { Model } from '..';

import type { AnimatorPlayParams } from './types';

export class Animator {
  private mixer: AnimationMixer;

  public readonly actions: Map<string, AnimationAction> = new Map();

  public paused: boolean = false;

  public timeScale: number = 1.0;

  constructor(model: Model) {
    this.mixer = model.createAnimationMixer();

    Assets.getModel(model.type).animations.forEach((animation) => {
      const action = this.mixer.clipAction(animation);
      this.activateAction(action, animation.name);
    });
  }

  private activateAction(action: AnimationAction, name: string) {
    this.actions.set(name, action);

    this.setWeight(action, 0.0);
    action.play();
  }

  private setWeight(action: AnimationAction, weight: number) {
    action.enabled = true;
    action.setEffectiveWeight(weight);
  }

  public stopAll() {
    this.actions.forEach((action) => {
      this.setWeight(action, 0.0);
    });
  }

  public stop(name: string) {
    const action = this.actions.get(name);
    if (!action) {
      Logger.warn(`Undefined animation '${name}`);
      return;
    }

    this.setWeight(action, 0.0);
  }

  public play(name: string, {
    timeScale = 1.0,
    repeat = true,
    clamp = false,
  }: AnimatorPlayParams = {}) {
    const action = this.actions.get(name);
    if (!action) {
      Logger.warn(`Undefined animation '${name}'`);
      return;
    }

    this.setWeight(action, 1.0);

    action.time = 0;
    action.repetitions = repeat ? Infinity : 1;
    action.clampWhenFinished = clamp;
    action.setEffectiveTimeScale(timeScale);
  }

  public change(from: string, to: string, duration: number) {
    const actionFrom = this.actions.get(from);
    if (!actionFrom) {
      Logger.warn(`Undefined animation '${from}`);
      return;
    }

    const actionTo = this.actions.get(to);
    if (!actionTo) {
      Logger.warn(`Undefined animation '${to}`);
      return;
    }

    if (actionFrom.getEffectiveWeight() > 0) {
      this.executeCrossFade(actionFrom, actionTo, duration);
    }
  }

  private executeCrossFade(actionFrom: AnimationAction, actionTo: AnimationAction, duration: number) {
    this.setWeight(actionTo, 1.0);
    actionTo.time = 0;
    actionFrom.crossFadeTo(actionTo, duration, true);
  }

  public update(deltaTime: number): void {
    if (!this.paused) {
      this.mixer.update((deltaTime / 1000) * this.timeScale);
    }
  }
}
