import { SCENE_CANVAS_ID } from '../scene/const';

import { InputKeyboard } from './keyboard';
import { InputMouse } from './mouse';
import { InputTouch } from './touch';

export class Input {
  public static addListeners() {
    InputKeyboard.addListeners();
    InputMouse.addListeners();
    InputTouch.addListeners();
  }

  public static isWorldTarget(event: Event) {
    const element = event.target as HTMLElement;
    return element.id === SCENE_CANVAS_ID;
  }

  public static isNativeTarget(event: TouchEvent | MouseEvent) {
    const target = event.target as HTMLElement;
    return (
      target.nodeName === 'INPUT' ||
      (
        target.nodeName === 'BUTTON' &&
        target.getAttribute('type') === 'submit'
      )
    );
  }
}
