import { Assets } from '~core/client/assets';
import { AudioMode, AudioType } from '~core/client/audio/types';

import audioAmbient from './ambient.mp3';

Assets.addAudio(AudioType.Ambient, {
  path: audioAmbient,
  mode: AudioMode.Track2D,
  loop: true,
  volume: 0.1,
});
