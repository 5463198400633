import { Logger } from '~core/client/logger';
import { SystemMessage } from '~core/client/system-message';
import { SystemMessageType } from '~core/client/system-message/types';

export class ErrorListener {
  public static listen() {
    window.addEventListener('error', (event: ErrorEvent) => {
      event.preventDefault();

      const error = (
        event.error ??
        new Error(event.message || 'Unknown error details')
      );

      Logger.error('System error', error);
      this.showSystemMessage(error.message);
    });

    window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
      event.preventDefault();

      Logger.error('System error', event.reason);
      this.showSystemMessage(event.reason?.message);
    });
  }

  private static showSystemMessage(message?: string) {
    SystemMessage.render('system-error', {
      type: SystemMessageType.Error,
      sign: '☠️',
      title: 'System Error',
      message,
    });
  }
}
