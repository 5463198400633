import { Assets } from '~core/client/assets';
import { AudioMode, AudioType } from '~core/client/audio/types';

import audioShot1 from './shot1.mp3';
import audioShot2 from './shot2.mp3';
import audioShot3 from './shot3.mp3';

Assets.addAudio(AudioType.ShotFire, {
  path: [audioShot1, audioShot2, audioShot3],
  mode: AudioMode.Track3D,
  volume: 0.5,
  limit: 4,
});
