import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';

import type { Battle } from '~feature/client/battle';
import { ActiveTutorialStepsState, CurrentTutorialStepState } from '~feature/client/battle/tutorial/ui/state';
import { Resources } from '~feature/client/battle/ui/components/amounts/resources';
import { Card } from '~feature/client/battle/ui/components/card';
import { useSelfPlayerSchema } from '~feature/client/battle/ui/hooks/use-self-player-schema';
import { BUILDER_FLOW } from '~feature/shared/battle/builder/const';
import { BUILDINGS_SHARED_CONFIG } from '~feature/shared/battle/entity/building/const';
import { BuildingVariant } from '~feature/shared/battle/entity/building/types';
import { SkillVariant } from '~feature/shared/battle/entity/unit/player/skill/types';
import { TutorialUtils } from '~feature/shared/battle/tutorial/utils';

import IconLock from './icons/lock.svg';

type Props = {
  variant: BuildingVariant;
  onSelect: VoidFunction;
};

export const Variant: React.FC<Props> = ({ variant, onSelect }) => {
  const { builder, scene, state } = useRoom<Battle>();
  const player = useSelfPlayerSchema();
  const language = useLanguage();

  const resources = useSchemaValue(player, 'resources');
  const builderFlowIndex = useSchemaValue(player, 'builderFlowIndex');

  let cost = BUILDINGS_SHARED_CONFIG[variant].cost;

  if (variant === BuildingVariant.Wall) {
    const skill = player.skills.get(SkillVariant.DiscountWalls);
    if (skill) {
      cost *= skill.multiplier;
    }
  }

  const tutorialSteps = useRecoilValue(ActiveTutorialStepsState);
  const currentTutorialStep = useRecoilValue(CurrentTutorialStepState);

  const limitExceeded = builder.isLimitExceeded(variant, player);
  const restrictionLevel = builder.getRestrictionLevel(variant, player);
  const restricted = builder.isRestricted(variant, player);

  const strictTarget = (
    currentTutorialStep?.indexOf('Build') === 0 &&
    currentTutorialStep?.includes(variant)
  );
  const flowTargetVariant = BUILDER_FLOW[state.mode][builderFlowIndex];
  const rightFlow = !flowTargetVariant || flowTargetVariant === variant;
  const avaialble = !limitExceeded && !restricted;
  const hasResources = resources >= cost;
  const locked = !avaialble || !rightFlow || (!strictTarget && TutorialUtils.hasBuilderStep(tutorialSteps));

  const handleClickVariant = () => {
    builder.setVariant(variant);
    onSelect();
  };

  return (
    <Card
      onClick={handleClickVariant}
      disabled={locked || !hasResources}
      locked={locked}
      alarm={!locked && !hasResources}
      blink={strictTarget}
    >
      <Card.Graphic>
        {avaialble ? (
          <img src={scene.snaps.get(variant)} />
        ) : (
          <>
            <IconLock />
            {limitExceeded ? (
              <Text size='xs'>MAX</Text>
            ) : (
              <Section direction='horizontal' align='center' gap={4}>
                <Text size='xs' bold>{restrictionLevel}</Text>
                <Text size='xs'>LVL</Text>
              </Section>
            )}
          </>
        )}
      </Card.Graphic>
      <Card.Content>
        <Card.Title>{language(`${variant}Name`)}</Card.Title>
        <Resources value={cost} size='xs' negative={!locked && !hasResources} />
      </Card.Content>
    </Card>
  );
};
