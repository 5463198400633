import React from 'react';

import type { AmountProps } from '~core/client/ui/components/amount';
import { Amount } from '~core/client/ui/components/amount';

import IconExperience from './icons/experience.svg';

type Props = Omit<AmountProps, 'icon' | 'color'>;

export const Experience: React.FC<Props> = (props) => (
  <Amount
    {...props}
    icon={IconExperience}
    color='#b2f6ff'
  />
);
