// @ts-ignore
import { MeshLineMaterial } from 'three.meshline';

import { Assets } from '~core/client/assets';
import { MaterialType } from '~core/client/assets/materials/types';
import { ModelType } from '~core/client/assets/types';

import bin from './mechanic.bin';
import gltf from './mechanic.gltf';

Assets.addModel(ModelType.DroidMechanic, {
  gltf,
  bin: {
    origin: 'mechanic.bin',
    path: bin,
  },
});

Assets.addMaterial(MaterialType.RepairLine, new MeshLineMaterial({
  color: 0xffffff,
  lineWidth: 0.1,
}));
