// extracted by mini-css-extract-plugin
var _1 = "OKWV7ADinPi5DjJ_dsrw";
var _2 = "FuE7mVEIGfURfJ52_I3L";
var _3 = "NalFL8iGEGDgAXhJ4UUw";
var _4 = "yl1HD2EJ5mKhi2ti8zwr";
var _5 = "XG10X8Ydre7FywL1bLjZ";
var _6 = "qIJomEMKE9JUume97J4U";
var _7 = "BO3A_BFFJzTi6aHdPC91";
var _8 = "dNS_TvAF24t_fhUMt8ws";
export { _1 as "animation-swipe-left", _2 as "animation-swipe-up", _3 as "icon", _4 as "large", _5 as "left", _6 as "text", _7 as "up", _8 as "wrapper" }
export default { "animation-swipe-left": _1, "animation-swipe-up": _2, "icon": _3, "large": _4, "left": _5, "text": _6, "up": _7, "wrapper": _8 }
