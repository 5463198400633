import { Sphere } from '~core/client/render-item/shape/sphere';

import type { Battle } from '~feature/client/battle';
import type { ShotSchema } from '~feature/shared/battle/entity/shot/types';

import { Shot } from '../..';

import type { BulletConfig } from './types';

export abstract class Bullet extends Shot {
  declare public readonly renderItem: Sphere;

  constructor(battle: Battle, { size = 1.0, audioEffect, ...config }: BulletConfig, schema: ShotSchema) {
    const renderItem = new Sphere(battle.scene, {
      ...config,
      radius: size * 0.1,
      position: schema.position,
    });

    super(battle, {
      renderItem,
      audioEffect,
    }, schema);
  }
}
