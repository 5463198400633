import React, { useEffect, useState } from 'react';

import { Client } from '~core/client/client';
import { InputKeyboard } from '~core/client/input/keyboard';
import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { Button } from '~core/client/ui/components/button';
import { ButtonBack } from '~core/client/ui/components/button/back';
import { Confirmation } from '~core/client/ui/components/confirmation';
import { Section } from '~core/client/ui/components/section';
import { SettingsPanel } from '~core/client/ui/components/settings-panel';
import { useEvent } from '~core/client/ui/hooks/use-event';
import { RoomType } from '~core/shared/room/types';

import type { Battle } from '~feature/client/battle';
import { useScreen } from '~feature/client/battle/ui/hooks/use-screen';
import { BattleMode } from '~feature/shared/battle/types';

import { ScreenType } from '../../../../types';

import { MENU_TOGGLE_KEY } from './const';
import { MenuPageType } from './types';

import styles from './styles.module.scss';

export const MenuScreen: React.FC = () => {
  const battle = useRoom<Battle>();
  const language = useLanguage();
  const { visible, toggleScreen } = useScreen(ScreenType.Menu);

  const [page, setPage] = useState<MenuPageType>(MenuPageType.Main);

  const handleClickContinue = () => {
    toggleScreen(false);
  };

  const handleClickBack = () => {
    setPage(MenuPageType.Main);
  };

  const handleClickLeave = () => {
    Client.joinRoomByType(RoomType.Relay);
  };

  const handleClickRestart = () => {
    Client.setLoading();
    battle.restart(false);
    toggleScreen(false);
  };

  useEvent(InputKeyboard.onKeyDown, (event: KeyboardEvent) => {
    if (event.code === MENU_TOGGLE_KEY) {
      event.preventDefault();
      toggleScreen(!visible);
      setPage(MenuPageType.Main);
    }
  }, [toggleScreen, visible]);

  useEffect(() => {
    battle.scene.audio.muffle(visible);
  }, [visible]);

  return visible && (() => {
    switch (page) {
      case MenuPageType.Main:
        return (
          <Section direction="vertical" gap={8}>
            <Button
              onClick={handleClickContinue}
              view="accent"
            >
              {language('Continue')}
            </Button>
            {battle.state.mode === BattleMode.Single && (
              <Button onClick={() => setPage(MenuPageType.Restart)}>
                {language('Restart')}
              </Button>
            )}
            <Button onClick={() => setPage(MenuPageType.Settings)}>
              {language('Settings')}
            </Button>
            <br />
            <Button
              onClick={() => setPage(MenuPageType.Leave)}
              view="negative"
            >
              {language('MainMenu')}
            </Button>
          </Section>
        );
      case MenuPageType.Settings:
        return (
          <div className={styles.settings}>
            <Section direction="vertical" align="center" gap={48}>
              <SettingsPanel />
              <ButtonBack onClick={handleClickBack} />
            </Section>
          </div>
        );
      case MenuPageType.Restart:
        return (
          <Confirmation
            text={language('RestartConfirm')}
            onConfirm={handleClickRestart}
            onDecline={handleClickBack}
          />
        );
      case MenuPageType.Leave:
        return (
          <Confirmation
            text={language('LeaveConfirm')}
            onConfirm={handleClickLeave}
            onDecline={handleClickBack}
          />
        );
    }
  })();
};
