import { DroidVariant } from '~feature/shared/battle/entity/unit/npc/droid/types';
import { SkillVariant } from '~feature/shared/battle/entity/unit/player/skill/types';
import { InventoryItemType } from '~feature/shared/mixed/inventory/item/types';

export const INVENTORY_ITEMS: Record<string, InventoryItemType> = {};

Object.keys(DroidVariant).forEach((variant) => {
  INVENTORY_ITEMS[variant] = InventoryItemType.Droid;
});

Object.keys(SkillVariant).forEach((variant) => {
  INVENTORY_ITEMS[variant] = InventoryItemType.Skill;
});
