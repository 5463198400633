import React, { useCallback } from 'react';

import { AudioType } from '~core/client/audio/types';
import { useAudio } from '~core/client/audio/ui/hooks/use-audio';
import { useRequest } from '~core/client/room/ui/hooks/use-request';
import { Crystals } from '~core/client/ui/components/amount/crystals';
import { Icon } from '~core/client/ui/components/icon';
import { Interactive } from '~core/client/ui/components/interactive';

import { useUserData } from '~feature/client/relay/ui/hooks/use-user-data';
import { SKILL_SLOT_COST } from '~feature/shared/battle/entity/unit/player/skill/const';
import { InventoryRequest } from '~feature/shared/mixed/inventory/types';

import styles from './styles.module.scss';

import IconLock from './icons/lock.svg';

export const SlotBuy: React.FC = () => {
  const audio = useAudio();

  const buySlot = useRequest(InventoryRequest.BuySlot);

  const crystals = useUserData('crystals');

  const handleClick = useCallback(async () => {
    if (crystals < SKILL_SLOT_COST) {
      audio.play(AudioType.Error);
      return;
    }

    await buySlot.fetch();

    audio.play(AudioType.Purchase);
  }, []);

  return (
    <Interactive
      disabled={buySlot.loading}
      onClick={handleClick}
      className={styles.frame}
    >
      <Icon type={IconLock} className={styles.lock} />
      <Crystals value={SKILL_SLOT_COST} />
    </Interactive>
  );
};
