import React, { useMemo } from 'react';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';

import { CardsList } from '~feature/client/battle/ui/components/cards-list';
import type { UpgradeCategory } from '~feature/shared/battle/entity/unit/player/upgrades/types';
import { UpgradeVariant } from '~feature/shared/battle/entity/unit/player/upgrades/types';

import { CATEGORY_ICON } from './const';
import { Variant } from './variant';

type Props = {
  category: UpgradeCategory;
};

export const Category: React.FC<Props> = ({ category }) => {
  const language = useLanguage();

  const upgrades = useMemo(() => (
    Object.values(UpgradeVariant).filter((variant) => variant.indexOf(category) === 0)
  ), [category]);

  return (
    <CardsList icon={CATEGORY_ICON[category]} label={language(category)}>
      {upgrades.map((variant) => (
        <Variant key={variant} variant={variant} />
      ))}
    </CardsList>
  );
};
