import { Request } from '~core/client/router/request';
import { SystemMessage } from '~core/client/system-message';
import { SystemMessageType } from '~core/client/system-message/types';
import { VERSION_ROUTE_PATH } from '~core/shared/version/const';

import { VERSION_CHECK_UPDATING_INTERVAL } from './const';

import pkg from '../../../../package.json';

export class Version {
  public static async checkClientVersion(): Promise<boolean> {
    const clientVersion = pkg.version;
    const serverVersion = await this.getServerVersion();
    if (!serverVersion) {
      this.waitServerUpdating();
      return false;
    }

    const currentVersion = this.normalizeVersion(clientVersion);
    const targetVersion = this.normalizeVersion(serverVersion);

    for (let i = 0; i < 3; i++) {
      if (currentVersion[i] < targetVersion[i]) {
        SystemMessage.render('invalid-version', {
          type: SystemMessageType.Error,
          sign: '⚠️',
          title: 'Invalid Version',
          message: [
            `Target game version is ${serverVersion}, but your client version is ${clientVersion}`,
            'Please, clear cache and try again',
          ],
        });

        return false;
      } else if (currentVersion[i] > targetVersion[i]) {
        this.waitServerUpdating();
        SystemMessage.render('server-updating', {
          type: SystemMessageType.Info,
          sign: '🔄',
          title: 'Updating',
          message: [
            'Game server is updating',
            'Please, wait...',
          ],
        });

        return false;
      }
    }

    return true;
  }

  private static waitServerUpdating(): void {
    setTimeout(async () => {
      const valid = await this.checkClientVersion();
      if (valid) {
        location.reload();
      }
    }, VERSION_CHECK_UPDATING_INTERVAL);
  }

  private static normalizeVersion(rawVersion: string): number[] {
    const [version] = rawVersion.split('-');
    return version.split('.').map((part) => Number(part));
  }

  private static async getServerVersion(): Promise<Nullable<string>> {
    return Request.get<{ version: string; }>(VERSION_ROUTE_PATH)
      .then((res) => res.version)
      .catch(() => null);
  }
}
