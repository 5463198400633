import type { Schema } from '@colyseus/schema';
import { Client as OriginClient } from 'colyseus.js';
import type { Room as OriginRoom } from 'colyseus.js';

import { Device } from '~core/client/device';
import type { Room } from '~core/client/room';
import { Request } from '~core/client/router/request';
import { Storage } from '~core/client/storage';
import { RoomType } from '~core/shared/room/types';
import { SERVER_HOST } from '~core/shared/server/const';

// TODO: Fix path
// eslint-disable-next-line import/no-restricted-paths
import type { UserConfig } from '~feature/shared/mixed/user/types';

export class Client {
  private static client: OriginClient;

  private static room: Nullable<OriginRoom> = null;

  private static definedRooms: Map<RoomType, typeof Room<any>> = new Map();

  public static get sessionId() {
    if (!this.room) {
      throw Error('Undefined current room');
    }

    return this.room.sessionId;
  }

  public static connect() {
    const host = __MODE === 'production'
      ? SERVER_HOST.replace('https', 'wss')
      : `ws://${location.host}`;
    this.client = new OriginClient(host);

    // Polyfill to use local Request method instead of XHR
    this.client.http.constructor.prototype.request = async (
      method: 'post' | 'get',
      path: string,
      options: any = {},
    ) => ({
      data: await Request[method](`/${path}`, options.body),
    });
  }

  public static defineRoom<T extends Schema>(type: RoomType, room: typeof Room<T>) {
    this.definedRooms.set(type, room);
  }

  private static getDefinedRoom(type: RoomType) {
    const RoomInstance = this.definedRooms.get(type);
    if (!RoomInstance) {
      throw Error(`Undefined room type ${type}`);
    }

    return RoomInstance;
  }

  private static async leaveCurrentRoom() {
    Storage.remove('Screen');

    if (!this.room || !this.room.connection.isOpen) {
      return;
    }

    const PrevRoomInstance = this.getDefinedRoom(this.room.name as RoomType);
    if (PrevRoomInstance.allowReconnection) {
      this.removeReconnectionToken();
    }

    await this.room.leave(true);
  }

  private static setRoom(origin: OriginRoom) {
    const RoomInstance = this.getDefinedRoom(origin.name as RoomType);
    if (RoomInstance.allowReconnection) {
      this.setReconnectionToken(origin.reconnectionToken);
    }

    this.room = origin;

    return new RoomInstance(origin);
  }

  public static async findAndJoinRoom() {
    const lastRoomid = this.getLastRoomId();
    if (lastRoomid) {
      this.removeLastRoomId();

      const joined = await this.joinRoomById(lastRoomid);
      if (joined) {
        return;
      }
    }

    const token = this.getReconnectionToken();
    if (token) {
      const joined = await this.restoreRoom(token);
      if (joined) {
        return;
      }
    }

    await this.joinRoomByType(RoomType.Relay);
  }

  public static async restoreRoom(token: string) {
    try {
      this.setLoading();
      const room = await this.client.reconnect(token);
      this.setRoom(room);

      return true;
    } catch {
      return false;
    }
  }

  public static async joinRoomByType(type: RoomType) {
    this.setLoading();
    await this.leaveCurrentRoom();
    const room = await this.client.join(type, this.getJoinPayload());
    this.setRoom(room);
  }

  public static async joinRoomById(id: string) {
    try {
      this.setLoading();
      await this.leaveCurrentRoom();
      const room = await this.client.joinById(id, this.getJoinPayload());
      this.setRoom(room);

      return true;
    } catch {
      return false;
    }
  }

  private static getJoinPayload(): UserConfig {
    return {
      device: Device.type,
      token: this.getAuthToken(),
      newbie: this.isNewbie(),
    };
  }

  public static getAuthToken() {
    return Storage.get('AuthToken');
  }

  public static lookAuthPreset() {
    const query = new URLSearchParams(location.search);
    const token = query.get('authToken');
    if (!token) {
      return;
    }

    this.setAuthToken(token);
    window.close();
  }

  public static setAuthToken(token: string) {
    Storage.set('AuthToken', token);
  }

  public static removeAuthToken() {
    Storage.remove('AuthToken');
  }

  public static getReconnectionToken() {
    return Storage.get('ReconnectionToken');
  }

  private static setReconnectionToken(token: string) {
    Storage.set('ReconnectionToken', token);
  }

  public static removeReconnectionToken() {
    Storage.remove('ReconnectionToken');
  }

  private static getLastRoomId() {
    return Storage.get('LastRoomId');
  }

  public static setLastRoomId(token: string) {
    Storage.set('LastRoomId', token);
  }

  public static removeLastRoomId() {
    Storage.remove('LastRoomId');
  }

  public static isNewbie() {
    return Storage.get('Newbie') !== 'No';
  }

  public static unmarkNewbie() {
    Storage.set('Newbie', 'No');
  }

  public static isTutorialCompleted() {
    return Storage.get('TutorialCompleted') === 'Yes';
  }

  public static completeTutorial() {
    Storage.set('TutorialCompleted', 'Yes');
  }

  public static hideLoading() {
    const screen = document.getElementById('game-loading');
    if (!screen) {
      return;
    }

    screen.classList.add('hidding');
    setTimeout(() => screen.classList.add('hidden'), 1000);
  }

  public static setLoading(state: string = '') {
    const screen = document.getElementById('game-loading');
    if (!screen) {
      return;
    }

    screen.classList.remove('hidding', 'hidden');

    const status = screen.querySelector('.status');
    if (status) {
      status.innerHTML = state;
    }
  }
}
