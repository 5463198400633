import { MaterialType } from '~core/client/assets/materials/types';
import { Circle } from '~core/client/render-item/shape/circle';

import type { Player } from '~feature/client/battle/entity/unit/player';
import { BUILDER_RADIUS } from '~feature/shared/battle/builder/const';
import { UpgradeVariant } from '~feature/shared/battle/entity/unit/player/upgrades/types';

import './resources';

export class BuildArea extends Circle {
  private player: Player;

  constructor(player: Player) {
    super(player.battle.scene, {
      material: MaterialType.BuildArea,
      radius: BUILDER_RADIUS.defaultValue,
    });

    this.player = player;
    this.player.renderItem.object.add(this.object);

    this.updateRadius();

    this.player.schema.upgrades.onChange(() => {
      this.updateRadius();
    });
  }

  private updateRadius() {
    const radius = this.player.getUpgradableValue(BUILDER_RADIUS, UpgradeVariant.BuildRadius);
    this.setRadius(radius);
  }
}
