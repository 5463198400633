import { RepeatWrapping, TextureLoader } from 'three';

import type { RenderItemMaterial } from '../../render-item/types';

import type { MaterialType } from './types';

export class AssetsMaterials {
  private static loader: TextureLoader = new TextureLoader();

  private static list: Map<MaterialType, RenderItemMaterial> = new Map();

  public static add(type: MaterialType, material: RenderItemMaterial): void {
    if (this.list.has(type)) {
      throw Error(`Material '${type}' is already registered`);
    }

    material.name = type;
    this.list.set(type, material);
  }

  public static get<T extends RenderItemMaterial>(type: MaterialType): T {
    const material = this.list.get(type);
    if (!material) {
      throw Error(`Unregistered material '${type}'`);
    }

    return material as T;
  }

  public static createTexture(path: string) {
    const texture = this.loader.load(path);

    texture.colorSpace = 'srgb';
    texture.generateMipmaps = false;
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.flipY = false;

    return texture;
  }
}
