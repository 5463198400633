import React from 'react';

import { Icon } from '~core/client/ui/components/icon';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';

type Props = {
  icon: SVGIcon;
  label: string;
  children: React.ReactNode
};

export const CardsList: React.FC<Props> = ({ icon, label, children }) => (
  <Section direction="vertical" align="center" gap={32}>
    <Section direction="horizontal" align="center" gap={16}>
      <Icon type={icon} size={24} />
      <Text size='m' bold uppercase>{label}</Text>
    </Section>
    <Section direction="vertical" gap={6}>
      {children}
    </Section>
  </Section>
);

