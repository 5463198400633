import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import type { FormEvent, ForwardedRef } from 'react';

import { InputKeyboard } from '~core/client/input/keyboard';

import { useEvent } from '../../hooks/use-event';
import type { InputData } from '../input/types';

import { FORM_SUBMIT_KEY } from './const';
import { FormContext } from './context';
import type { FormData } from './types';

type Props = {
  children: React.ReactNode;
  onSubmit: (values: any) => void;
};

export const Form = forwardRef(({ children, onSubmit }: Props, ref: ForwardedRef<FormData>) => {
  const refInputs = useRef(new Map<string, InputData>());

  const values = () => (
    Array.from(refInputs.current.entries())
      .reduce((current, [name, input]) => ({
        ...current,
        [name]: input.value,
      }), {})
  );

  const validate = () => {
    let valid = true;
    Array.from(refInputs.current.values()).forEach((input) => {
      if (input.empty) {
        valid = false;
        input.showError();
      }
    });
    return valid;
  };

  const validateAndSubmit = useCallback(() => {
    if (validate()) {
      onSubmit(values());
    }
  }, [onSubmit]);

  const handleSubmit = useCallback((event: FormEvent) => {
    event.preventDefault();
    validateAndSubmit();
  }, [validateAndSubmit]);

  const addInput = (name: string, input: InputData) => {
    refInputs.current.set(name, input);
  };

  const removeInput = (name: string) => {
    refInputs.current.delete(name);
  };

  const form = useMemo(() => ({
    addInput,
    removeInput,
  }), []);

  useEvent(InputKeyboard.onKeyDown, (event: KeyboardEvent) => {
    if (event.key === FORM_SUBMIT_KEY) {
      event.preventDefault();
      validateAndSubmit();
    }
  }, [validateAndSubmit]);

  useImperativeHandle(ref, () => form, [form]);

  return (
    <FormContext.Provider value={form}>
      <form onSubmit={handleSubmit} autoComplete="false">
        {children}
      </form>
    </FormContext.Provider>
  );
});
