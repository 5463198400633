import { Assets } from '~core/client/assets';
import { ModelType } from '~core/client/assets/types';

import binCrystal from './crystal.bin';
import gltfCrystal from './crystal.gltf';

Assets.addModel(ModelType.Crystal, {
  gltf: gltfCrystal,
  bin: {
    origin: 'crystal.bin',
    path: binCrystal,
  },
});

