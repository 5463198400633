import { ModelType } from '~core/client/assets/types';
import { AudioType } from '~core/client/audio/types';

import type { Battle } from '~feature/client/battle';
import type { MobSchema } from '~feature/shared/battle/entity/unit/npc/mob/types';

import { Mob } from '../..';

import './resources';

export class MobBoss extends Mob {
  constructor(battle: Battle, schema: MobSchema) {
    super(battle, {
      model: ModelType.MobBoss,
    }, schema);
  }

  override onDead(): void {
    super.onDead();

    this.playAudio(AudioType.MobBossRoar);
  }
}

