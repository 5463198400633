import { Assets } from '~core/client/assets';
import { Client } from '~core/client/client';
import { Device } from '~core/client/device';
import { ErrorListener } from '~core/client/error-listener';
import { Input } from '~core/client/input';
import { Language } from '~core/client/language';
import { SDK } from '~core/client/sdk';
import { Version } from '~core/client/version';
import { RoomType } from '~core/shared/room/types';

import { Battle } from '~feature/client/battle';
import { Relay } from '~feature/client/relay';

(async () => {
  ErrorListener.listen();
  Client.lookAuthPreset();

  const language = Language.getType();
  Language.setDocumentTag(language);

  const support = Device.checkSupports();
  if (!support) {
    return;
  }

  const valid = await Version.checkClientVersion();
  if (!valid) {
    return;
  }

  Device.addPlatformLabel();
  Device.addListeners();

  await Promise.all([
    SDK.load(),
    Assets.load((progress) => {
      Client.setLoading(`${Math.ceil(progress * 100)}%`);
    }),
  ]);

  Client.defineRoom(RoomType.Relay, Relay);
  Client.defineRoom(RoomType.Battle, Battle);

  Client.connect();
  Client.findAndJoinRoom();

  Input.addListeners();

  SDK.completeLoading();
})();
