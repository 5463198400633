import { Assets } from '~core/client/assets';
import { AudioType, AudioMode } from '~core/client/audio/types';

import audioPurchase from './purchase.mp3';
import audioSelect from './select.mp3';

Assets.addAudio(AudioType.Select, {
  path: audioSelect,
  mode: AudioMode.Track2D,
  volume: 1.3,
});

Assets.addAudio(AudioType.Purchase, {
  path: audioPurchase,
  mode: AudioMode.Track2D,
  volume: 0.8,
});
