import React from 'react';

import { Boost } from '~feature/client/battle/entity/ui/components/boost';
import { Indicator } from '~feature/client/battle/entity/ui/components/indicator';
import { MobMessage } from '~feature/shared/battle/entity/unit/npc/mob/types';

import { useMob } from '../../hooks/use-mob';

import { StateIcon } from './state-icon';

export const Indicators: React.FC = () => {
  const mob = useMob();

  return (
    <>
      <Indicator
        entity={mob}
        messageShow={MobMessage.Boost}
        messageHide={MobMessage.Unboost}
      >
        <Boost opponent={!mob.selfOwn} />
      </Indicator>
      <StateIcon />
    </>
  );
};
