import { Assets } from '~core/client/assets';
import { ModelType } from '~core/client/assets/types';

import bin from './spawner-small.bin';
import gltf from './spawner-small.gltf';

Assets.addModel(ModelType.BuildingSpawnerSmall, {
  gltf,
  bin: {
    origin: 'spawner-small.bin',
    path: bin,
  },
});
