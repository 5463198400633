import type { Vector3Like } from 'three';
import { Vector3 } from 'three';

import { INTERPOLATION_LERP } from './const';

export class Interpolation {
  private readonly purpose: Vector3 = new Vector3();

  public setPurpose(vector: Vector3Like) {
    this.purpose.copy(vector);
  }

  public apply(target: Vector3, delta: number) {
    const factor = INTERPOLATION_LERP * delta;
    target.lerp(this.purpose, factor);
  }
}
