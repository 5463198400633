import type React from 'react';

import { InventoryItemType } from '~feature/shared/mixed/inventory/item/types';

import { InventoryTabDroid } from './droid';
import { InventoryTabSkill } from './skill';

export const INVENTORY_TAB_CONTENT: Record<InventoryItemType, React.FC> = {
  [InventoryItemType.Skill]: InventoryTabSkill,
  [InventoryItemType.Droid]: InventoryTabDroid,
};
