import { MaterialType } from '~core/client/assets/materials/types';
import { ModelType } from '~core/client/assets/types';
import { Model } from '~core/client/render-item/model';
import { SceneLayer } from '~core/client/scene/types';

import type { CrystalSchema } from '~feature/shared/battle/terrain/crystal/types';

import type { Battle } from '../..';

import { Indicator } from './indicator';

import './resources';

export class Crystal {
  public readonly battle: Battle;

  public readonly schema: CrystalSchema;

  public readonly renderItem: Model;

  private readonly indicator: Indicator;

  constructor(battle: Battle, schema: CrystalSchema) {
    this.battle = battle;
    this.schema = schema;

    this.renderItem = new Model(battle.scene, {
      model: ModelType.Crystal,
      material: MaterialType.Crystal,
      position: schema.position,
      scale: schema.big ? 1.5 : 0,
    });
    this.renderItem.eachMeshes((mesh) => {
      mesh.layers.set(SceneLayer.Misc);
    });

    this.renderItem.animator.play('spin', { timeScale: 0.75 });

    this.indicator = new Indicator(this);

    this.listenSchemaVisible();

    this.schema.onRemove(() => {
      this.destroy();
    });

    this.battle.terrain.crystals.add(this);
  }

  public destroy(): void {
    this.indicator.destroy();
    this.renderItem.destroy();

    this.battle.terrain.crystals.delete(this);
  }

  private listenSchemaVisible() {
    const selfId = this.battle.getSelfPlayerSchema().id;

    this.schema.visibleFor.onChange(() => {
      const visible = Boolean(this.schema.visibleFor.get(selfId));
      this.renderItem.setVisible(visible);
    });
  }
}
