import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { Section } from '~core/client/ui/components/section';

import type { Battle } from '~feature/client/battle';
import { useEntityUpdate } from '~feature/client/battle/entity/ui/hooks/use-entity-update';
import { ActiveTutorialStepsState } from '~feature/client/battle/tutorial/ui/state';
import { useSelfPlayerSchema } from '~feature/client/battle/ui/hooks/use-self-player-schema';
import { BuildingMessage, BuildingVariant } from '~feature/shared/battle/entity/building/types';
import { BuildingUtils } from '~feature/shared/battle/entity/building/utils';
import type { TutorialStep } from '~feature/shared/battle/tutorial/types';

import { BUILDING_HOTKEY_UPGRADE, BUILDING_HOTKEY_REPAIR } from '../../../../const';
import { useBuilding } from '../../../hooks/use-building';

import { Control } from './control';

import IconDestroy from './icons/destroy.svg';
import IconRepair from './icons/repair.svg';
import IconUpgrade from './icons/upgrade.svg';

export const Controls: React.FC = () => {
  const battle = useRoom<Battle>();
  const building = useBuilding();
  const player = useSelfPlayerSchema();

  const activeTutorialSteps = useRecoilValue(ActiveTutorialStepsState);

  const [upgradeCost, setUpgradeCost] = useState(0);
  const [upgradeDisabled, setUpgradeDisabled] = useState(true);
  const [upgradeLevel, setUpgradeLevel] = useState(0);

  const [repairCost, setRepairCost] = useState(0);
  const [repairDisabled, setRepairDisabled] = useState(true);

  const blink = activeTutorialSteps.has(`Upgrade${building.schema.variant}` as TutorialStep);

  useEntityUpdate(building, () => {
    setUpgradeCost(BuildingUtils.getUpgradeCost(building.schema, player));
    setUpgradeDisabled(BuildingUtils.isMaxLevel(building.schema));
    setUpgradeLevel(BuildingUtils.getLevelToUpgrade(building.schema, battle.state));

    setRepairCost(BuildingUtils.getRepairCost(building.schema, player));
    setRepairDisabled(building.schema.live.health === building.schema.live.maxHealth);
  });

  return (
    <Section direction="horizontal" gap={6}>
      <Control
        type='action'
        message={BuildingMessage.DoUpgrade}
        icon={IconUpgrade}
        hotkey={BUILDING_HOTKEY_UPGRADE}
        cost={upgradeCost}
        disabled={upgradeDisabled}
        minLevel={upgradeLevel}
        blink={blink}
      />
      <Control
        type='action'
        message={BuildingMessage.DoRepair}
        icon={IconRepair}
        hotkey={BUILDING_HOTKEY_REPAIR}
        cost={repairCost}
        disabled={repairDisabled}
      />
      {building.schema.variant !== BuildingVariant.Base && (
        <Control
          type='break'
          message={BuildingMessage.DoBreak}
          icon={IconDestroy}
        />
      )}
    </Section>
  );
};
