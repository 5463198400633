import React, { useState } from 'react';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { Section } from '~core/client/ui/components/section';
import { Tabs } from '~core/client/ui/components/tabs';

import { InventoryItemType } from '~feature/shared/mixed/inventory/item/types';

import { Sheet } from '../../sheet';

import { CrystalsCurrent } from './crystals-current';
import { CrystalsReward } from './crystals-reward';
import { INVENTORY_TAB_CONTENT } from './tabs/const';

import './resources';

export const PageInventory: React.FC = () => {
  const language = useLanguage();

  const [tab, setTab] = useState(InventoryItemType.Skill);

  const Content = INVENTORY_TAB_CONTENT[tab];
  const tabs = Object.values(InventoryItemType).map((type) => ({
    key: type,
    label: language(`Category${type}`),
  }));

  return (
    <Sheet header={(
      <>
        <Tabs
          tabs={tabs}
          active={tab}
          size='l'
          onChange={(tab) => setTab(tab as InventoryItemType)}
        />
        <Section direction='horizontal' gap={32}>
          <CrystalsCurrent />
          <CrystalsReward />
        </Section>
      </>
    )}>
      <Content />
    </Sheet>
  );
};
