import type { Battle } from '~feature/client/battle';
import type { Shot } from '~feature/client/battle/entity/shot';
import { ShotFactory } from '~feature/client/battle/entity/shot/factory';
import type { BuildingTowerSchema } from '~feature/shared/battle/entity/building/variants/tower/types';

import { Building } from '../..';
import type { BuildingVariantConfig } from '../../types';

export class BuildingTower extends Building {
  declare readonly schema: BuildingTowerSchema;

  private readonly shot: Shot;

  constructor(battle: Battle, config: BuildingVariantConfig, schema: BuildingTowerSchema) {
    super(battle, config, schema);

    this.shot = ShotFactory.create(battle, schema.weapon.shot);
  }

  override destroy(): void {
    super.destroy();

    this.shot.destroy();
  }
}
