import { Assets } from '~core/client/assets';
import { AudioType, AudioMode } from '~core/client/audio/types';

import audioAlert from './alert.mp3';

Assets.addAudio(AudioType.Alert, {
  path: audioAlert,
  mode: AudioMode.Track2D,
  volume: 0.5,
  loop: true,
});

