import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import { Settings } from '~core/client/settings';
import type { SettingsType } from '~core/client/settings/types';
import { Interactive } from '~core/client/ui/components/interactive';

import { Param } from '..';

import styles from './styles.module.scss';

type Props = {
  type: SettingsType;
  defaultValue: boolean;
  store?: boolean;
  onChange: (value: boolean) => void;
};

export const ParamBoolean: React.FC<Props> = ({
  type,
  defaultValue,
  store = true,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleSwitch = useCallback(() => {
    setValue(!value);
    onChange(!value);

    if (store) {
      Settings.setBoolean(type, !value);
    }
  }, [value]);

  return (
    <Param type={type}>
      <Interactive
        onClick={handleSwitch}
        className={cn(styles.switch, {
          [styles.active]: value,
        })}
      >
        <div className={styles.thumb} />
      </Interactive>
    </Param>
  );
};
