import { SkinnedMesh } from 'three';

import { Assets } from '~core/client/assets';
import type { ModelType } from '~core/client/assets/types';
import type { Scene } from '~core/client/scene';

import { RenderItem } from '..';

import { Animator } from './animator';
import { MODEL_ROOT } from './const';
import type { ModelConfig } from './types';

export class Model extends RenderItem {
  public readonly type: ModelType;

  public readonly animator: Animator;

  constructor(scene: Scene, { model, scale, material, ...config }: ModelConfig) {
    super(scene, {
      ...config,
      object: Assets.getClonedModel(model),
      scale: scale ? {
        x: scale,
        y: scale,
        z: scale,
      } : undefined,
    });

    this.setMaterial(material);

    this.type = model;
    this.animator = new Animator(this);
  }

  override destroy(): void {
    this.eachMeshes((mesh) => {
      if (mesh instanceof SkinnedMesh) {
        mesh.skeleton.dispose();
      }
    });

    super.destroy();
  }

  override update(): void {
    super.update();

    this.animator.update(this.scene.deltaTime);
  }

  public getRoot() {
    return this.object.children.find((object) => object.name === MODEL_ROOT);
  }
}
