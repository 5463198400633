import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { AudioType } from '~core/client/audio/types';
import { useAudio } from '~core/client/audio/ui/hooks/use-audio';
import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { Icon } from '~core/client/ui/components/icon';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';

import { CurrentTutorialStepState } from '~feature/client/battle/tutorial/ui/state';
import { useSelfPlayerSchema } from '~feature/client/battle/ui/hooks/use-self-player-schema';

import { ALERT_TIMEOUT } from './const';

import styles from './styles.module.scss';

import IconAlert from './icons/alert.svg';

import './resources';

export const Alert: React.FC = () => {
  const audio = useAudio();
  const language = useLanguage();
  const player = useSelfPlayerSchema();
  const currentTutorialStep = useRecoilValue(CurrentTutorialStepState);

  const ammo = useSchemaValue(player, 'ammo');

  const [text, setText] = useState<Nullable<string>>(null);

  const refTimeout = useRef<Nullable<Timer>>();

  const stopTimeout = () => {
    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
      refTimeout.current = null;
    }
  };

  const showAlert = (value: string) => {
    stopTimeout();

    if (text !== value) {
      audio.play(AudioType.Alert);
      setText(value);
    }
  };

  const hideAlert = () => {
    stopTimeout();
    audio.stop(AudioType.Alert);
    setText(null);
  };

  useEffect(() => {
    if (currentTutorialStep) {
      return;
    }

    if (ammo === 0) {
      showAlert(language('NotEnoughAmmo'));
    } else if (!refTimeout.current) {
      refTimeout.current = setTimeout(hideAlert, ALERT_TIMEOUT);
    }
  }, [ammo]);

  useEffect(() => {
    return () => {
      hideAlert();
    };
  }, []);

  return text && (
    <Section direction='horizontal' align='center' gap={16} className={styles.container}>
      <Icon type={IconAlert} size={24} />
      <Text size='m' bold uppercase spacing>{text}</Text>
    </Section>
  );
};
