import { MaterialType } from '~core/client/assets/materials/types';
import { Model } from '~core/client/render-item/model';

import type { Battle } from '~feature/client/battle';

import { CORPSE_DESTROY_TIMEOUT } from './const';
import type { CorpseConfig } from './types';

export class Corpse extends Model {
  private readonly battle: Battle;

  constructor(battle: Battle, config: CorpseConfig) {
    super(battle.scene, {
      ...config,
      material: MaterialType.Unit,
    });

    this.battle = battle;

    this.animator.play('die', {
      repeat: false,
      clamp: true,
    });

    this.battle.timeouts.add(() => {
      this.destroy();
    }, CORPSE_DESTROY_TIMEOUT);
  }
}
