import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { MathUtils } from 'three';

import { Device } from '~core/client/device';
import { InputMouse } from '~core/client/input/mouse';
import { useEvent } from '~core/client/ui/hooks/use-event';
import { DeviceType } from '~core/shared/device/types';

import { useDraggable } from '../../hooks/use-draggable';
import { useEventWhen } from '../../hooks/use-event-when';

import { SCROLLBAR_DRAG_FACTOR, SCROLLBAR_SMOOTH } from './const';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
};

export const Scrollbar: React.FC<Props> = ({ children }) => {
  const [visible, setVisible] = useState(false);

  const refWrapper = useRef<HTMLDivElement>(null);
  const refContent = useRef<HTMLDivElement>(null);
  const refThumb = useRef<HTMLDivElement>(null);

  const refBeginPosition = useRef(0);

  const { dragging } = useDraggable({
    active: visible,
    target: refWrapper,
    control: refThumb,
    onStart: () => {
      refBeginPosition.current = getCurrentPosition();
    },
    onDrag: (distance: number) => {
      const wrapper = refWrapper.current;
      if (!wrapper) {
        return;
      }

      const factor = Device.type == DeviceType.Mobile ? SCROLLBAR_DRAG_FACTOR : 1.0;
      const offset = (distance * factor) / wrapper.clientHeight;
      const newPosition = refBeginPosition.current + offset;
      updatePosition(newPosition);
    },
  });

  const getRatio = () => {
    const wrapper = refWrapper.current;
    const content = refContent.current;

    return wrapper && content
      ? Math.max(wrapper.clientHeight / content.clientHeight, 0)
      : 0;
  };

  const updateSize = (ratio: number) => {
    const thumb = refThumb.current;
    if (!thumb) {
      return;
    }

    thumb.style.height = `${ratio * 100}%`;
  };

  const getCurrentPosition = () => {
    const thumb = refThumb.current;
    return thumb?.style.top ? parseFloat(thumb.style.top) / 100 : 0;
  };

  const updatePosition = (position: number, ratio: number = getRatio()) => {
    const content = refContent.current;
    const thumb = refThumb.current;
    if (!content || !thumb) {
      return;
    }

    const normalPosition = MathUtils.clamp(position, 0, 1 - ratio);

    thumb.style.top = `${normalPosition * 100}%`;
    content.style.top = `${-normalPosition * (1 / ratio) * 100}%`;
  };

  const handleScreenResize = () => {
    const wrapper = refWrapper.current;
    const content = refContent.current;
    if (!wrapper || !content) {
      return;
    }

    const currentVisible = content.clientHeight > wrapper.clientHeight;
    setVisible(currentVisible);

    if (currentVisible) {
      const ratio = getRatio();
      const currentPosition = getCurrentPosition();
      updateSize(ratio);
      updatePosition(currentPosition, ratio);
    }
  };

  useEvent(Device.onScreenResize, handleScreenResize, []);

  useEventWhen(InputMouse.onMouseWheel, () => visible, (event: WheelEvent) => {
    const wrapper = refWrapper.current;
    if (!wrapper || !event.composedPath().includes(wrapper)) {
      return;
    }

    const currentPosition = getCurrentPosition();
    const newPosition = currentPosition + (event.deltaY / SCROLLBAR_SMOOTH);
    updatePosition(newPosition);
  }, [visible]);

  useEffect(() => {
    const content = refContent.current;
    if (!content) {
      return;
    }

    handleScreenResize();

    const observer = new MutationObserver(() => {
      handleScreenResize();
    });

    observer.observe(content, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div ref={refWrapper} className={styles.wrapper}>
      <div ref={refContent} className={styles.content}>
        {children}
      </div>
      <div className={cn(styles.track, {
        [styles.visible]: visible,
      })}>
        <div ref={refThumb} className={styles.thumb}>
          <div className={cn(styles.shape, {
            [styles.dragging]: dragging,
          })} />
        </div>
      </div>
    </div>
  );
};
