import { Assets } from '~core/client/assets';
import { AudioMode, AudioType } from '~core/client/audio/types';

import audioPickup from './pickup.mp3';

Assets.addAudio(AudioType.Pickup, {
  path: audioPickup,
  mode: AudioMode.Track2D,
  volume: 0.8,
});
