// extracted by mini-css-extract-plugin
var _1 = "rgRJ6ipOtd8SxFcZ3gwP";
var _2 = "JXrULUVU33anxEBNgTOy";
var _3 = "rJt6yP4UYXE73KRipBZp";
var _4 = "TOfkeQAAA3QxKVA3jXjD";
var _5 = "CnpL0a2YXn8FawqKroUq";
var _6 = "iiNN7b2xcMX_B1ZX1okQ";
var _7 = "FB1okgQf3ov4VQjlveob";
var _8 = "RlV4oGMolV8kWGtwE9ll";
var _9 = "i9jFmgVUGIyfAMX0KaZi";
var _a = "lNwMtY8ZFXGrT0mk4GVV";
var _b = "Qi_XXPaA6BkwfbzVZ7D9";
var _c = "rWTwwyRcK41Bak_CAQ9r";
var _d = "eC21EF8vqqoROcNTPOOF";
var _e = "TKqHTVYzK3qAoFOxrr7b";
var _f = "coyws_JbcZ7Y039CzlZy";
var _10 = "fEF6Ibn8pIUCzoyTYodG";
var _11 = "wZnpINaiFl11qalCGAAB";
var _12 = "KeOqutoEEQ12IQcWMZWT";
var _13 = "JIGs03TnbZfHYhZz75kv";
var _14 = "uFMc4lcHxPiXGpIfLUoA";
var _15 = "I4sShsyDuO61syqjEVRp";
var _16 = "d8OaeKlcTRJz2wDRNEJy";
var _17 = "YKBR4SSK0ZwccGHAa_QQ";
export { _1 as "align-center", _2 as "align-left", _3 as "align-right", _4 as "bold", _5 as "mono", _6 as "narrow", _7 as "size-2xl", _8 as "size-2xs", _9 as "size-3xl", _a as "size-l", _b as "size-m", _c as "size-s", _d as "size-xl", _e as "size-xs", _f as "spacing", _10 as "text", _11 as "uppercase", _12 as "view-brilliant", _13 as "view-dark", _14 as "view-primary", _15 as "view-secondary", _16 as "view-transparent", _17 as "wrap" }
export default { "align-center": _1, "align-left": _2, "align-right": _3, "bold": _4, "mono": _5, "narrow": _6, "size-2xl": _7, "size-2xs": _8, "size-3xl": _9, "size-l": _a, "size-m": _b, "size-s": _c, "size-xl": _d, "size-xs": _e, "spacing": _f, "text": _10, "uppercase": _11, "view-brilliant": _12, "view-dark": _13, "view-primary": _14, "view-secondary": _15, "view-transparent": _16, "wrap": _17 }
