import { MeshSetChild } from '~core/client/render-item/mesh-set/child';

import type { TileSchema } from '~feature/shared/battle/terrain/tile/types';

import './resources';

export class Tile extends MeshSetChild {
  constructor(schema: TileSchema) {
    super({
      position: schema.position,
    });
  }

  public static getGroupKey(schema: TileSchema) {
    const indexes = schema.visibleIndexes;
    if (indexes.length === 0) {
      return null;
    }

    return `${schema.biome}|${schema.index}|${indexes.join('-')}`;
  }
}
