import React, { useCallback } from 'react';

import { Client } from '~core/client/client';
import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRequest } from '~core/client/room/ui/hooks/use-request';
import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { Button } from '~core/client/ui/components/button';

import type { Relay } from '~feature/client/relay';
import { UserAuthRequest } from '~feature/shared/mixed/user/auth/types';

import IconLogout from './icons/logout.svg';

export const ButtonLogout: React.FC = () => {
  const room = useRoom<Relay>();
  const language = useLanguage();

  const logout = useRequest(UserAuthRequest.Logout);

  const handleClick = useCallback(() => {
    logout.fetch().then(() => {
      Client.removeAuthToken();
      room.refreshUI();
    });
  }, []);

  return (
    <Button icon={IconLogout} size='s' view='transparent' onClick={handleClick} >
      {language('LeaveAccount')}
    </Button>
  );
};
