import React, { useRef } from 'react';
import type { Vector3Like } from 'three';

import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { useEvent } from '~core/client/ui/hooks/use-event';

import styles from './styles.module.scss';

type Props = {
  position: Vector3Like;
  children: React.ReactNode;
};

export const TranslatorManual: React.FC<Props> = ({ position, children }) => {
  const room = useRoom();

  const refContainer = useRef<HTMLDivElement>(null);

  useEvent(room.scene.onRender, () => {
    const container = refContainer.current;
    if (!container) {
      return;
    }

    const screenPosition = room.scene.camera.getScreenPosition(position);
    container.style.left = `${screenPosition.x}px`;
    container.style.top = `${screenPosition.y}px`;
  }, []);

  return (
    <div ref={refContainer} className={styles.position}>
      {children}
    </div>
  );
};
