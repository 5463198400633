import { Events } from 'make-event';
import { Vector3 } from 'three';
import type { Vector3Like } from 'three';

import { MaterialType } from '~core/client/assets/materials/types';
import { ModelType } from '~core/client/assets/types';
import { AudioType } from '~core/client/audio/types';
import { Device } from '~core/client/device';
import { InputKeyboard } from '~core/client/input/keyboard';
import { Logger } from '~core/client/logger';
import type { Messages } from '~core/client/messages';
import type { Prediction } from '~core/client/prediction';
import type { Progression } from '~core/shared/progression';
import { Throttle } from '~core/shared/time/throttle';
import { VectorUtils } from '~core/shared/vector-utils';

import { Light } from '~feature/client/battle/terrain/fog/light';
import type { AttackAreaParams } from '~feature/shared/battle/entity/unit/attack-area/types';
import type { SkillVariant } from '~feature/shared/battle/entity/unit/player/skill/types';
import type { PlayerMessagePayload, PlayerSchema } from '~feature/shared/battle/entity/unit/player/types';
import { PlayerCheat, PlayerMessage } from '~feature/shared/battle/entity/unit/player/types';
import type { UpgradeVariant } from '~feature/shared/battle/entity/unit/player/upgrades/types';
import { UnitUtils } from '~feature/shared/battle/entity/unit/utils';
import { EntityUtils } from '~feature/shared/battle/entity/utils';
import type { CrystalPickupPayload } from '~feature/shared/battle/terrain/crystal/types';
import { BattleStage } from '~feature/shared/battle/types';

import { Unit } from '..';
import type { Battle } from '../../..';

import { PLAYER_ATTACK_KEY, PLAYER_MOVEMENT_DIRECTIONS, PLAYER_STEP_DELAY } from './const';
import type { PlayerMovementKey } from './types';
import { PlayerDirection } from './types';
import { PlayerUI } from './ui';

import './resources';

export class Player extends Unit {
  declare public readonly schema: PlayerSchema;

  declare protected readonly messages: Messages<PlayerMessagePayload>;

  declare protected prediction: Prediction;

  private readonly directions: Set<PlayerDirection> = new Set();

  public readonly movingVector: Vector3 = new Vector3();

  private lastKeys: string = '';

  private light: Nullable<Light> = null;

  private playStepsThrottle: Throttle = new Throttle(PLAYER_STEP_DELAY);

  public readonly onCrystalPickup = Events.make<CrystalPickupPayload>();

  constructor(battle: Battle, schema: PlayerSchema) {
    super(battle, {
      model: ModelType.Player,
    }, schema);

    this.renderItem.animator.play('idle');

    const material = this.selfOwn ? MaterialType.Self : MaterialType.Opponent;
    this.renderItem.changeMaterial('BodyMesh', material);

    this.setUI(PlayerUI);
    this.createMarker({
      material: this.selfOwn ? MaterialType.MarkerSelfPlayer : undefined,
      scale: 1.25,
    });

    if (this.selfOwn) {
      if (this.battle.state.stage === BattleStage.Started) {
        this.messages.send(PlayerMessage.Move, {
          tick: 0,
          delta: 0,
        });
      }

      this.enablePrediction();
      this.createLight();

      this.battle.scene.audio.setTarget(this.renderItem);
      this.battle.scene.camera.setTarget(this.renderItem);

      this.onKeyDown = this.onKeyDown.bind(this);
      InputKeyboard.onKeyDown(this.onKeyDown);

      this.onKeyUp = this.onKeyUp.bind(this);
      InputKeyboard.onKeyUp(this.onKeyUp);

      this.onScreenHide = this.onScreenHide.bind(this);
      Device.onScreenHide(this.onScreenHide);
    } else {
      this.enableInterpolation();
      this.createIndicator();
    }

    this.schema.live.listen('health', (health) => {
      if (health <= 0) {
        this.onDead();
      }
    });

    this.onBattleFinish = this.onBattleFinish.bind(this);
    this.battle.onFinish(this.onBattleFinish);
  }

  override destroy(): void {
    super.destroy();

    if (this.selfOwn) {
      InputKeyboard.onKeyDown.unsubscribe(this.onKeyDown);
      InputKeyboard.onKeyUp.unsubscribe(this.onKeyUp);
      Device.onScreenHide.unsubscribe(this.onScreenHide);
    }

    this.removeLight();

    this.battle.onFinish.unsubscribe(this.onBattleFinish);

    this.onCrystalPickup.clear();
  }

  override onSceneUpdate(): void {
    super.onSceneUpdate();

    if (this.selfOwn) {
      this.handleMovement();
    }

    if (this.moving) {
      this.playStepsThrottle.call(() => {
        this.playSteps();
      });
    }
  }

  private createLight() {
    if (this.light) {
      Logger.warn('Player light is already created');
      return;
    }

    this.light = new Light(this.battle, {
      radius: this.schema.visibleDistance,
      target: this.renderItem,
    });
  }

  private removeLight() {
    if (!this.light) {
      return;
    }

    this.light.destroy();
    this.light = null;
  }

  public useSkill(variant: SkillVariant) {
    this.messages.send(PlayerMessage.UseSkill, { variant });
  }

  public upgrade(variant: UpgradeVariant) {
    this.messages.send(PlayerMessage.Upgrade, { variant });
  }

  public attack() {
    this.messages.send(PlayerMessage.Attack, void {});
  }

  private pickupCrystal(payload: CrystalPickupPayload) {
    this.battle.scene.audio.play2D(AudioType.Pickup);
    this.onCrystalPickup.invoke(payload);
  }

  private playSteps(): void {
    this.playAudio(AudioType.PlayerStep);
  }

  public getUpgradableValue(progression: Progression, type: UpgradeVariant) {
    const level = this.schema.upgrades.get(type) ?? 1;
    return progression.get(level);
  }

  private handleMovement() {
    this.setVelocity(this.movingVector);

    const delta = this.battle.scene.delta;
    if (this.velocity.lengthSq() > 0) {
      this.move(this.velocity, delta);
    }

    const tick = this.prediction.addState(this.velocity, delta);
    this.messages.send(PlayerMessage.Move, { tick, delta });
  }

  private move(velocity: Vector3Like, delta: number) {
    const nextPosition = EntityUtils.getNextPosition(this.renderItem.position, velocity, this.schema.speed, delta);
    const lookUpPosition = UnitUtils.getLookUpPosition(nextPosition, this.velocity, this.schema.size);
    if (this.handleCollide(lookUpPosition)) {
      return;
    }

    this.setPosition(nextPosition);
  }

  private handleCollide(position: Vector3): boolean {
    const point = position.clone().round();
    return this.battle.state.terrain.matrix.has(VectorUtils.encode2d(point));
  }

  public setMovingVector(vector: Vector3Like) {
    this.movingVector.copy(vector);
    this.messages.send(PlayerMessage.ChangeMovingVector, {
      vector: {
        x: vector.x,
        y: vector.z,
      },
    });
  }

  private getVectorByDirections() {
    const velocity = new Vector3();

    if (this.directions.has(PlayerDirection.Up)) {
      velocity.x -= 1;
      velocity.z -= 1;
    } else if (this.directions.has(PlayerDirection.Down)) {
      velocity.x += 1;
      velocity.z += 1;
    }

    if (this.directions.has(PlayerDirection.Left)) {
      velocity.x -= 1;
      velocity.z += 1;
    } else if (this.directions.has(PlayerDirection.Right)) {
      velocity.x += 1;
      velocity.z -= 1;
    }

    if (velocity.length() > 0) {
      velocity.normalize();
    }

    return velocity;
  }

  protected applyPrediction(): void {
    this.prediction.useState(this.schema.tickMove, (velocity, delta) => {
      this.move(velocity, delta);
    });
  }

  private stopMoving() {
    this.directions.clear();

    this.setMovingVector(
      this.getVectorByDirections(),
    );
  }

  private toggleMoveDirection(direction: PlayerDirection, state: boolean) {
    if (state) {
      this.directions.add(direction);
    } else {
      this.directions.delete(direction);
    }

    this.setMovingVector(
      this.getVectorByDirections(),
    );
  }

  override handleMessages() {
    super.handleMessages();

    if (this.selfOwn) {
      this.messages.on(PlayerMessage.PickupCrystal, (payload) => {
        this.pickupCrystal(payload);
      });
    }
  }

  private onScreenHide(): void {
    if (this.battle.state.stage !== BattleStage.Started) {
      return;
    }

    this.stopMoving();
  }

  override onDamage() {
    this.playAudio(AudioType.PlayerHit);
  }

  private onDead() {
    this.renderItem.animator.stopAll();
    this.renderItem.animator.play('die', {
      repeat: false,
      clamp: true,
    });
  }

  private onBattleFinish() {
    if (this.selfOwn) {
      this.stopMoving();
    }
  }

  private onKeyDown(event: KeyboardEvent) {
    if (this.battle.state.stage !== BattleStage.Started) {
      return;
    }

    if (event.code.length === 4) {
      if (this.lastKeys.length === 4) {
        this.lastKeys = this.lastKeys.substring(1);
      }
      this.lastKeys += event.code[3];
      if (this.lastKeys.length === 4) {
        this.useCheatCode(this.lastKeys);
      }
    }

    if (event.code === PLAYER_ATTACK_KEY) {
      event.preventDefault();

      this.attack();
    } else {
      const direction = PLAYER_MOVEMENT_DIRECTIONS[event.code as PlayerMovementKey];
      if (direction) {
        event.preventDefault();

        if (!this.directions.has(direction)) {
          this.toggleMoveDirection(direction, true);
        }
      }
    }
  }

  private onKeyUp(event: KeyboardEvent) {
    if (this.battle.state.stage !== BattleStage.Started) {
      return;
    }

    const direction = PLAYER_MOVEMENT_DIRECTIONS[event.code as PlayerMovementKey];
    if (!direction) {
      return;
    }

    event.preventDefault();

    if (this.directions.has(direction)) {
      this.toggleMoveDirection(direction, false);
    }
  }

  override onDisplayAttackArea(params: AttackAreaParams) {
    super.onDisplayAttackArea(params);

    this.renderItem.animator.play('attack', {
      repeat: false,
      timeScale: 2.0,
    });

    this.playAudio(AudioType.PlayerAttack);
  }

  protected onChangeMoveState(moving: boolean): void {
    if (moving) {
      this.renderItem.animator.change('idle', 'run', 0.25);
    } else {
      this.renderItem.animator.change('run', 'idle', 0.25);
    }
  }

  private useCheatCode(code: string) {
    if (Object.values(PlayerCheat).includes(code as PlayerCheat)) {
      this.messages.send(PlayerMessage.UseCheatCode, {
        code: code as PlayerCheat,
      });
    }
  }
}
