import { BuildingCategory } from '~feature/shared/battle/entity/building/types';

import IconDefense from './icons/defense.svg';
import IconResources from './icons/resources.svg';
import IconSpawner from './icons/spawner.svg';
import IconTower from './icons/tower.svg';

export const CATEGORY_ICON: Record<BuildingCategory, React.FC> = {
  [BuildingCategory.Spawners]: IconSpawner,
  [BuildingCategory.Defense]: IconDefense,
  [BuildingCategory.Resources]: IconResources,
  [BuildingCategory.Towers]: IconTower,
};

