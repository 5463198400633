import { useEffect, useMemo, useRef, useState } from 'react';

import { useForm } from '../../form/hooks/use-form';
import type { InputData } from '../types';

export function useInput<T>(name: string, defaultValue: T): InputData<T> {
  const form = useForm();

  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(false);

  const refTimeout = useRef<Nullable<Timer>>(null);

  const hideError = () => {
    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
    }

    refTimeout.current = setTimeout(() => {
      setError(false);
      refTimeout.current = null;
    }, 1000);
  };

  const showError = () => {
    setError(true);
    hideError();
  };

  const reset = () => {
    setValue(defaultValue);
  };

  const input = useMemo(() => ({
    value,
    setValue,
    reset,
    showError,
    invalid: error,
    empty: !value,
  }), [value, error]);

  useEffect(() => {
    form.addInput(name, input);
    return () => {
      form.removeInput(name);
    };
  }, [input]);

  useEffect(() => {
    return () => {
      if (refTimeout.current) {
        clearTimeout(refTimeout.current);
      }
    };
  }, []);

  return input;
}
