import { Assets } from '~core/client/assets';
import { ModelType } from '~core/client/assets/types';

import bin from './tower-frost.bin';
import gltf from './tower-frost.gltf';

Assets.addModel(ModelType.BuildingTowerFrost, {
  gltf,
  bin: {
    origin: 'tower-frost.bin',
    path: bin,
  },
});
