// extracted by mini-css-extract-plugin
var _1 = "KCbNP1dJS0NnxcxxyKDk";
var _2 = "JhIOfOYFDkULsqOc_055";
var _3 = "vSmYSpeKw7Lm4mC8mZ6a";
var _4 = "XWhcNwti4Peo_P0jhR5o";
var _5 = "qyCfTbkzA0iTMZISQsqa";
var _6 = "cVkRtyZoXOesmSLYwxpv";
var _7 = "VxSWdv5kMnpWYVa5F9JP";
var _8 = "C8XR8gKxz2Z7sartoYT_";
export { _1 as "between", _2 as "center", _3 as "horizontal", _4 as "left", _5 as "right", _6 as "stretch", _7 as "vertical", _8 as "wrapper" }
export default { "between": _1, "center": _2, "horizontal": _3, "left": _4, "right": _5, "stretch": _6, "vertical": _7, "wrapper": _8 }
