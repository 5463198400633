// extracted by mini-css-extract-plugin
var _1 = "JzUauy_zipBz2tZZxkfc";
var _2 = "gqtdoaFJrQZ3qXLqjhtS";
var _3 = "uP8Vqj1XEPUAVVSsQtr6";
var _4 = "wASuRbHi_xhNqJENjn9e";
var _5 = "jODbwJ32x2f7CDvPJyjQ";
var _6 = "AQZohq0gofkliKVoEJW5";
var _7 = "AogBO_fkB3DBhsk5Fq_w";
var _8 = "V6FD58xIUki8kxqo1N22";
export { _1 as "buttons", _2 as "center", _3 as "column", _4 as "front", _5 as "grid", _6 as "left", _7 as "right", _8 as "screen" }
export default { "buttons": _1, "center": _2, "column": _3, "front": _4, "grid": _5, "left": _6, "right": _7, "screen": _8 }
