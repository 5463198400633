import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { ProgressBar } from '~feature/client/battle/entity/ui/components/progress-bar';

import { usePlayer } from '../../hooks/use-player';

export const StaminaBar: React.FC = () => {
  const player = usePlayer();

  const stamina = useSchemaValue(player.schema, 'stamina');

  return (stamina < 100) && (
    <ProgressBar
      value={stamina}
      maxValue={100}
    />
  );
};
