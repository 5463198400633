import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { ProgressBar } from '~feature/client/battle/entity/ui/components/progress-bar';

import { useBuilding } from '../../../hooks/use-building';

export const HealthBar: React.FC = () => {
  const building = useBuilding();

  const health = useSchemaValue(building.schema.live, 'health');
  const maxHealth = useSchemaValue(building.schema.live, 'maxHealth');

  return (
    <ProgressBar
      value={health}
      maxValue={maxHealth}
      color={building.selfOwn ? '#4ed83e' : '#ff5151'}
    />
  );
};
