import type { Battle } from '~feature/client/battle';
import type { DroidSchema } from '~feature/shared/battle/entity/unit/npc/droid/types';

import { DROID_INSTANCES } from './const';

export class DroidFactory {
  public static create(battle: Battle, schema: DroidSchema) {
    const DroidInstance = DROID_INSTANCES[schema.variant];

    return new DroidInstance(battle, schema);
  }
}
