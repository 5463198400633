import { Vector2 } from 'three';

import { TERRAIN_SIZE } from '~feature/shared/battle/terrain/const';

export const FOG_PLANE_SIZE = new Vector2(TERRAIN_SIZE.x * 2, TERRAIN_SIZE.z * 2);

export const FOG_MAX_LIGHTS = 64;

export const FOG_INTENSITY = 0.65;

export const FOG_FADE = 0.01;
