import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { Crystals } from '~core/client/ui/components/amount/crystals';
import { Score } from '~core/client/ui/components/amount/score';
import { Waves } from '~core/client/ui/components/amount/waves';
import { Section } from '~core/client/ui/components/section';
import { ValueCard } from '~core/client/ui/components/value-card';

import type { Battle } from '~feature/client/battle';
import { useSelfPlayerSchema } from '~feature/client/battle/ui/hooks/use-self-player-schema';

export const Reward: React.FC = () => {
  const battle = useRoom<Battle>();
  const language = useLanguage();

  const player = useSelfPlayerSchema();

  const score = useSchemaValue(player, 'score');
  const crystals = useSchemaValue(player, 'crystals');
  const waveNumber = battle.state.wave?.number;

  return (
    <Section direction="horizontal" gap={8}>
      <ValueCard label={language('Crystals')}>
        <Crystals value={crystals} size='l' animated />
      </ValueCard>
      <ValueCard label={language('Experience')}>
        <Score value={score} size='l' animated />
      </ValueCard>
      {waveNumber && (
        <ValueCard label={language('Wave')}>
          <Waves value={waveNumber} size='l' />
        </ValueCard>
      )}
    </Section>
  );
};
