import { ModelType } from '~core/client/assets/types';

import type { Battle } from '~feature/client/battle';
import type { BuildingTowerElectroSchema } from '~feature/shared/battle/entity/building/variants/tower/electro/types';

import { BuildingTower } from '..';

import './resources';

export class BuildingTowerElectro extends BuildingTower {
  constructor(battle: Battle, schema: BuildingTowerElectroSchema) {
    super(battle, {
      model: ModelType.BuildingTowerElectro,
    }, schema);
  }
}
