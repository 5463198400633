import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect, useRef, useState } from 'react';

import { InputKeyboard } from '~core/client/input/keyboard';
import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { Button } from '~core/client/ui/components/button';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';
import { useEvent } from '~core/client/ui/hooks/use-event';

import type { Battle } from '~feature/client/battle';
import { useScreen } from '~feature/client/battle/ui/hooks/use-screen';
import { useSelfPlayerSchema } from '~feature/client/battle/ui/hooks/use-self-player-schema';
import type { BuildingVariant } from '~feature/shared/battle/entity/building/types';
import { BattleMode } from '~feature/shared/battle/types';

import { ScreenType } from '../../../../types';
import { MENU_TOGGLE_KEY } from '../menu-screen/const';

import { AdsReward } from './ads-reward';

import styles from './styles.module.scss';

export const NewBuildingsScreen: React.FC = () => {
  const battle = useRoom<Battle>();
  if (battle.state.mode === BattleMode.Online) {
    return null;
  }

  const language = useLanguage();

  const player = useSelfPlayerSchema();
  const level = useSchemaValue(player, 'level');

  const { visible, openScreen, closeScreen } = useScreen(ScreenType.NewBuildings);

  const refAvailables = useRef<BuildingVariant[]>([]);
  const refPrevLevel = useRef(level);

  const [variant, setVariant] = useState<Nullable<BuildingVariant>>(null);

  useEvent(InputKeyboard.onKeyDown, (event: KeyboardEvent) => {
    if (!visible || event.code !== MENU_TOGGLE_KEY) {
      return;
    }

    event.preventDefault();
    closeScreen();
  }, [visible]);

  useEffect(() => {
    const availables = battle.builder.getAvailableBuildings();

    if (level > refPrevLevel.current) {
      const newVariant = availables.find((variant) => !refAvailables.current.includes(variant));
      if (newVariant) {
        setVariant(newVariant);
        openScreen();
      }
    }

    refPrevLevel.current = level;
    refAvailables.current = availables;
  }, [level]);

  useEffect(() => {
    battle.scene.audio.muffle(visible);
    battle.setPause(visible);
  }, [visible]);

  // Case when current screen has been saved in storage, but variant is undefined
  useEffect(() => {
    if (visible && !variant) {
      closeScreen();
    }
  }, []);

  return (visible && variant) && (
    <Section direction='vertical' align='center' gap={80}>
      <Text size='2xl' align='center' bold uppercase>{language('NewBuildingsAvailable')}</Text>
      <Section direction='horizontal' align='center' gap={32}>
        <div className={styles.preview}>
          <img src={battle.scene.snaps.get(variant)} />
        </div>
        <Section direction='vertical' gap={8}>
          <Text view='primary' size='xl' bold uppercase>{language(`${variant}Name`)}</Text>
          <Text size='m'>{language(`${variant}Description`)}</Text>
        </Section>
      </Section>
      <Section direction='vertical' gap={16}>
        <Button view='accent' onClick={closeScreen}>{language('Continue')}</Button>
        <AdsReward onComplete={closeScreen} />
      </Section>
    </Section>
  );
};
