import React from 'react';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';

import { Button } from '../button';

type Props = {
  text: string;
  onConfirm: VoidFunction;
  onDecline: VoidFunction;
};

export const Confirmation: React.FC<Props> = ({
  text,
  onConfirm,
  onDecline,
}) => {
  const language = useLanguage();

  return (
    <Section direction="vertical" gap={24} align="center">
      <Text size="l" bold>
        {text}
      </Text>
      <Section direction="horizontal" gap={16}>
        <Button onClick={onConfirm} view="accent">
          {language('Yes')}
        </Button>
        <Button onClick={onDecline} view="negative">
          {language('No')}
        </Button>
      </Section>
    </Section>
  );
};
