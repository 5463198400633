import React from 'react';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { Input } from '~core/client/ui/components/input';

import IconPassword from './icons/password.svg';

export const InputPassword: React.FC = () => {
  const language = useLanguage();

  return (
    <Input
      name='password'
      label={language('Password')}
      icon={IconPassword}
      type="password"
      maxLength={32}
    />
  );
};
