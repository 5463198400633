import { useContext } from 'react';

import type { Building } from '../..';
import { BuildingContext } from '../context';

export function useBuilding<T extends Building>() {
  const building = useContext(BuildingContext);
  if (!building) {
    throw Error('Undefined building context');
  }

  return building as T;
}
