// extracted by mini-css-extract-plugin
var _1 = "bjWBUoGZM0anmq3MIN7U";
var _2 = "vnb1WTJzoyS2GtQcXj1B";
var _3 = "TeBkOw8F1wKJwzCBrWDu";
var _4 = "kM3FRnjUvwMHOkZKO1sA";
var _5 = "VYxb29Udq613c_97KfuP";
var _6 = "pjF4yyh3LNjZTrUBLv_s";
var _7 = "_4hsOKGNeDp7e5maeRKa";
export { _1 as "container", _2 as "error", _3 as "info", _4 as "message", _5 as "overlay", _6 as "sign", _7 as "title" }
export default { "container": _1, "error": _2, "info": _3, "message": _4, "overlay": _5, "sign": _6, "title": _7 }
