import React from 'react';

import { useUserData } from '../../hooks/use-user-data';

import { LoginButton } from './login-button';
import { UserInfo } from './user-info';

export const UserPanel: React.FC = () => {
  const authorized = useUserData('authorized');

  return authorized ? <UserInfo /> : <LoginButton />;
};
