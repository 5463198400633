import { Assets } from '~core/client/assets';
import { ModelType } from '~core/client/assets/types';

import bin from './demon.bin';
import gltf from './demon.gltf';

Assets.addModel(ModelType.MobDemon, {
  gltf,
  bin: {
    origin: 'demon.bin',
    path: bin,
  },
});
