import { Request } from '~core/client/router/request';
import { LOGGER_ROUTE_PATH } from '~core/shared/logger/const';
import type { LoggerClientPayload } from '~core/shared/logger/types';
import { LoggerUtils } from '~core/shared/logger/utils';

export class Logger {
  private static lastError: Nullable<string> = null;

  public static debug(message: string) {
    console.debug(message);
  }

  public static warn(message: string) {
    console.warn(message);

    this.send({
      type: 'warn',
      message,
      userAgent: navigator.userAgent,
    });
  }

  public static error(message: string, error?: Error) {
    const fullMessage = LoggerUtils.formatErrorMessage(message, error);
    if (fullMessage === this.lastError) {
      return;
    }

    this.lastError = fullMessage;

    if (error?.stack && __MODE === 'development') {
      console.error(error?.stack);
    } else {
      console.error(fullMessage);
    }

    this.send({
      type: 'error',
      message: fullMessage,
      stack: error?.stack,
      userAgent: navigator.userAgent,
    });
  }

  private static send(payload: LoggerClientPayload) {
    Request.post(LOGGER_ROUTE_PATH, payload)
      .catch(() => {});
  }
}
