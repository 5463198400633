import { Color, MeshBasicMaterial } from 'three';

import { Assets } from '~core/client/assets';
import { MaterialType } from '~core/client/assets/materials/types';

Assets.addMaterial(MaterialType.BuildArea, new MeshBasicMaterial({
  color: new Color(0xffffff),
  reflectivity: 0,
  transparent: true,
  opacity: 0.1,
  depthTest: false,
  depthWrite: false,
}));
