import { SERVER_HOST } from '~core/shared/server/const';

export class Request {
  public static get host() {
    return __MODE === 'production' ? SERVER_HOST : '';
  }

  public static async get<T>(path: string) {
    return fetch(this.host + path, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res) => (
        Request.handleResponse(res) as T
      ));
  }

  public static async post(path: string, data: any) {
    return fetch(this.host + path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: typeof data === 'string'
        ? data
        : JSON.stringify(data),
    })
      .then((res) => (
        Request.handleResponse(res)
      ));
  }

  private static async handleResponse(res: Response) {
    if (res.status === 204) {
      return null;
    }

    const data = await res.json();
    if (res.status >= 400) {
      throw Error(data.error);
    }

    return data;
  }

  public static getNativeError(error?: Error) {
    const message = error?.message;
    if (!message) {
      return 'Unknown error';
    }

    if (message === 'Failed to fetch') {
      return 'Network error';
    }

    return message.replace(/^.*\d+:\s/, '');
  }
}
