import type { Battle } from '~feature/client/battle';
import type { DroidSchema } from '~feature/shared/battle/entity/unit/npc/droid/types';

import { NPC } from '..';

import type { DroidConfig } from './types';
import { DroidUI } from './ui';

export abstract class Droid extends NPC {
  declare public readonly schema: DroidSchema;

  constructor(battle: Battle, config: DroidConfig, schema: DroidSchema) {
    super(battle, config, schema);

    this.setUI(DroidUI);

    this.renderItem.animator.play('fly', {
      timeScale: 0.25,
    });
  }
}
