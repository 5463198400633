import { DROID_ICON } from '~feature/client/battle/entity/unit/npc/droid/ui/components/droid-icon/const';
import { SKILL_ICON } from '~feature/client/battle/entity/unit/player/ui/components/skill-icon/const';
import { DROIDS_SHARED_CONFIG } from '~feature/shared/battle/entity/unit/npc/droid/const';
import { SKILLS_SHARED_CONFIG } from '~feature/shared/battle/entity/unit/player/skill/const';
import { InventoryItemType } from '~feature/shared/mixed/inventory/item/types';

export const INVENTORY_ITEM_ICON = {
  ...DROID_ICON,
  ...SKILL_ICON,
};

export const INVENTORY_CATEGORY_ITEMS: Record<InventoryItemType, string[]> = {
  [InventoryItemType.Droid]: Object.keys(DROIDS_SHARED_CONFIG),
  [InventoryItemType.Skill]: Object.keys(SKILLS_SHARED_CONFIG),
};
