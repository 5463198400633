import { useRef, useEffect } from 'react';

import { Device } from '~core/client/device';
import { useEvent } from '~core/client/ui/hooks/use-event';

export function useRelativeScreen<T extends HTMLDivElement>() {
  const refScreen = useRef<T>(null);
  const refTimeout = useRef<Nullable<Timer>>(null);

  const handleScreenResize = () => {
    const screen = refScreen.current;
    if (!screen) {
      return;
    }

    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
    }

    refTimeout.current = setTimeout(() => {
      refTimeout.current = null;

      screen.style.removeProperty('transform');
      screen.style.removeProperty('transformOrigin');
      screen.style.removeProperty('width');
      screen.style.removeProperty('height');

      const originalWidth = screen.clientWidth;
      const originalHeight = screen.clientHeight;
      const zoom = Device.getScale();

      screen.style.transform = `scale(${zoom})`;
      screen.style.transformOrigin = '0 0';
      screen.style.width = `${originalWidth / zoom}px`;
      screen.style.height = `${originalHeight / zoom}px`;
    }, 50);
  };

  useEvent(Device.onScreenResize, handleScreenResize, []);

  useEffect(() => {
    handleScreenResize();

    return () => {
      if (refTimeout.current) {
        clearTimeout(refTimeout.current);
      }
    };
  }, []);

  return refScreen;
}
