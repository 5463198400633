import React, { useEffect, useRef, useState } from 'react';

import { Text } from '~core/client/ui/components/text';

import type { LiveSchema } from '~feature/shared/battle/entity/live/types';

import { DAMAGE_LABEL_DELAY } from './const';
import type { DamageRecord } from './types';

import styles from './styles.module.scss';

type Props = {
  live: LiveSchema
};

export const DamageLabel: React.FC<Props> = ({ live }) => {
  const [damageStack, setDamageStack] = useState<DamageRecord[]>([]);

  const prevHealth = useRef(0);
  const hideTimeout = useRef<Timer>();

  useEffect(() => {
    const unlisten = live.listen('health', (newHealth) => {
      const amount = prevHealth.current - newHealth;
      if (amount > 0) {
        setDamageStack((current) => {
          const records = [...current];
          const lastRecord = records[records.length - 1];
          const now = performance.now();

          if (lastRecord && now - lastRecord.timestamp <= DAMAGE_LABEL_DELAY) {
            lastRecord.amount = amount;
          } else {
            records.push({
              amount,
              timestamp: now,
            });
          }

          return records;
        });

        if (hideTimeout.current) {
          clearTimeout(hideTimeout.current);
        }
        hideTimeout.current = setTimeout(() => {
          setDamageStack([]);
        }, DAMAGE_LABEL_DELAY);
      }
      prevHealth.current = newHealth;
    });

    return () => {
      unlisten();

      if (hideTimeout.current) {
        clearTimeout(hideTimeout.current);
      }
    };
  }, []);

  return (
    <div>
      {damageStack.map(({ amount, timestamp }) => (
        <Text size='xs' mono key={timestamp} className={styles.label}>
          {amount}
        </Text>
      ))}
    </div>
  );
};
