import { Events } from 'make-event';

import { MaterialType } from '~core/client/assets/materials/types';
import { AudioType } from '~core/client/audio/types';
import { InputKeyboard } from '~core/client/input/keyboard';
import type { DictionaryPhrase } from '~core/client/language/types';
import { Logger } from '~core/client/logger';
import type { Messages } from '~core/client/messages';
import { Model } from '~core/client/render-item/model';
import type { Cube } from '~core/client/render-item/shape/cube';

import { BattleSceneLayer } from '~feature/client/battle/scene/types';
import { Light } from '~feature/client/battle/terrain/fog/light';
import { BUILDING_DEFAULT_RADIUS } from '~feature/shared/battle/entity/building/const';
import { BuildingVariant, type BuildingMessage, type BuildingMessagePayload, type BuildingSchema } from '~feature/shared/battle/entity/building/types';
import { BattleMode, BattleStage } from '~feature/shared/battle/types';

import { Entity } from '..';
import type { Battle } from '../..';

import { ActionArea } from './action-area';
import { BUILDING_HOTKEYS, BUILDING_MODEL_UPGRADE_PREFIX, BUILDING_REPAIR_ALERT_FACTOR } from './const';
import { Platform } from './platform';
import type { BuildingVariantConfig } from './types';
import { BuildingPanelVisible } from './types';
import { BuildingUI } from './ui';

import './resources';

export abstract class Building extends Entity {
  declare public readonly schema: BuildingSchema;

  declare protected readonly messages: Messages<BuildingMessagePayload>;

  declare public readonly renderItem: Model;

  private light: Nullable<Light> = null;

  private actionArea: Nullable<ActionArea> = null;

  private platform: Nullable<Cube> = null;

  public panelVisible: BuildingPanelVisible = BuildingPanelVisible.None;

  public alerts: Set<DictionaryPhrase> = new Set<DictionaryPhrase>();

  public readonly onChangeAlert = Events.make<Set<DictionaryPhrase>>();
  public readonly onChangePanelVisible = Events.make<BuildingPanelVisible>();

  constructor(battle: Battle, config: BuildingVariantConfig, schema: BuildingSchema) {
    const renderItem = new Model(battle.scene, {
      ...config,
      material: MaterialType.Building,
    });
    renderItem.eachMeshes((mesh) => {
      mesh.layers.set(BattleSceneLayer.Building);
    });

    super(battle, renderItem, schema);

    this.setUI(BuildingUI);

    if (this.battle.state.mode === BattleMode.Online) {
      this.createPlatform();
    }

    const base = schema.variant === BuildingVariant.Base;
    this.createMarker({
      material: base ? MaterialType.MarkerBase : undefined,
      scale: base ? 1.5 : 1.0,
    });

    this.listenSchemaReadyState();
    this.listenSchemaUpgradeLevel();

    if (this.selfOwn) {
      this.createLight();
      this.listenSchemaRadius();
      this.listenSchemaHealth();
    }

    this.battle.scene.buildingsLinks.set(this.schema.id, this);

    this.onBattleFinish = this.onBattleFinish.bind(this);
    this.battle.onFinish(this.onBattleFinish);
  }

  override destroy(): void {
    super.destroy();

    this.removePlatform();

    if (this.battle.state.stage === BattleStage.Started) {
      this.playAudio(AudioType.BuildingBreak);
    }

    if (this.selfOwn) {
      this.removeLight();
      this.removeActionArea();

      InputKeyboard.onKeyDown.unsubscribe(this.onKeyDown);
    }

    if (this.battle.scene.selectedBuilding === this) {
      this.battle.scene.selectedBuilding = null;
    }
    if (this.battle.scene.hoveredBuilding === this) {
      this.battle.scene.hoveredBuilding = null;
    }

    this.battle.scene.buildingsLinks.delete(this.schema.id);

    this.battle.onFinish.unsubscribe(this.onBattleFinish);

    this.onChangePanelVisible.clear();
    this.onChangeAlert.clear();
  }

  private onBattleFinish() {
    this.setPanelVisible(BuildingPanelVisible.None);

    this.renderItem.animator.paused = true;
  }

  private createLight() {
    if (this.light) {
      Logger.warn('Building light is already created');
      return;
    }

    this.light = new Light(this.battle, {
      radius: this.schema.radius ?? BUILDING_DEFAULT_RADIUS,
      target: this.renderItem,
    });
  }

  private updateLight(radius: Nullable<number>) {
    this.light?.setRadius(radius ?? BUILDING_DEFAULT_RADIUS);
  }

  private removeLight() {
    if (!this.light) {
      return;
    }

    this.light.destroy();
    this.light = null;
  }

  private createPlatform() {
    if (this.platform) {
      Logger.warn('Building platform is already created');
      return;
    }

    this.platform = new Platform(this);
  }

  private removePlatform() {
    if (!this.platform) {
      return;
    }

    this.platform.destroy();
    this.platform = null;
  }

  protected onReady() {
    if (this.selfOwn) {
      this.onKeyDown = this.onKeyDown.bind(this);
      InputKeyboard.onKeyDown(this.onKeyDown);
    }
  }

  private listenSchemaReadyState() {
    const unlisten = this.schema.listen('buildProgress', (buildProgress) => {
      if (buildProgress >= 100) {
        this.onReady();
        setTimeout(() => unlisten());
      }
    });
  }

  private listenSchemaUpgradeLevel() {
    const root = this.renderItem.getRoot();
    if (!root) {
      Logger.warn(`Invalid root node of model for building '${this.schema.variant}'`);
      return;
    }

    this.schema.listen('level', (level) => {
      root.children.forEach((object) => {
        if (object.name.indexOf(BUILDING_MODEL_UPGRADE_PREFIX) === 0) {
          const targetLevel = Number(object.name.replace(BUILDING_MODEL_UPGRADE_PREFIX, ''));
          if (targetLevel >= 1 && targetLevel <= this.schema.maxLevel) {
            object.visible = level >= targetLevel;
          }
        }
      });
    });
  }

  private listenSchemaHealth() {
    this.schema.live.listen('health', (health) => {
      const factor = health / this.schema.live.maxHealth;
      if (factor <= BUILDING_REPAIR_ALERT_FACTOR) {
        this.addAlert('NeedRepair');
      } else {
        this.removeAlert('NeedRepair');
      }
    });
  }

  private listenSchemaRadius() {
    this.schema.listen('radius', (radius) => {
      this.updateLight(radius);

      if (radius) {
        if (this.actionArea) {
          this.updateActionArea(radius);
        } else {
          this.createActionArea(radius);
        }
      } else {
        this.removeActionArea();
      }
    });
  }

  private createActionArea(radius: number) {
    if (this.actionArea) {
      Logger.warn('Building action area is already created');
      return;
    }

    this.actionArea = new ActionArea(this, radius);
  }

  private updateActionArea(radius: number) {
    this.actionArea?.setRadius(radius);
  }

  private removeActionArea() {
    if (!this.actionArea) {
      return;
    }

    this.actionArea.destroy();
    this.actionArea = null;
  }

  private setPanelVisible(state: BuildingPanelVisible) {
    this.panelVisible = state;

    if (this.actionArea) {
      const actionAreaVisible = state !== BuildingPanelVisible.None;
      this.actionArea.setVisible(actionAreaVisible);
    }

    this.onChangePanelVisible.invoke(state);
  }

  protected onKeyDown(event: KeyboardEvent): void {
    if (this.battle.state.paused) {
      return;
    }

    if (this.battle.scene.selectedBuilding) {
      if (this.battle.scene.selectedBuilding !== this) {
        return;
      }
    } else if (this.battle.scene.hoveredBuilding !== this) {
      return;
    }

    const message = BUILDING_HOTKEYS[event.code];
    if (message) {
      event.preventDefault();
      this.doAction(message);
    }
  }

  public doAction(action: BuildingMessage) {
    this.messages.send(action, void {});
  }

  public click(): boolean {
    if (
      !this.selfOwn ||
      this.schema.buildProgress < 100 ||
      this.battle.builder.isBuild()
    ) {
      return false;
    }

    this.setPanelVisible(BuildingPanelVisible.Full);

    this.battle.scene.audio.play2D(AudioType.Click);

    return true;
  }

  public clickOutside() {
    if (this.panelVisible === BuildingPanelVisible.Full) {
      this.setPanelVisible(BuildingPanelVisible.None);
    }
  }

  public hover() {
    if (
      this.battle.builder.isBuild()
      || this.panelVisible !== BuildingPanelVisible.None
    ) {
      return;
    }

    this.setPanelVisible(BuildingPanelVisible.Compact);
  }

  public unhover() {
    if (this.panelVisible !== BuildingPanelVisible.Compact) {
      return;
    }

    this.setPanelVisible(BuildingPanelVisible.None);
  }

  protected addAlert(label: DictionaryPhrase): void {
    if (this.alerts.has(label)) {
      return;
    }

    this.alerts.add(label);
    this.onChangeAlert.invoke(this.alerts);
  }

  protected removeAlert(label: DictionaryPhrase): void {
    if (!this.alerts.has(label)) {
      return;
    }

    this.alerts.delete(label);
    this.onChangeAlert.invoke(this.alerts);
  }

  override onDamage() {
    this.playAudio(AudioType.BuildingHit);
  }
}
