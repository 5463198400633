import { CircleGeometry, Vector3 } from 'three';

import { Shape } from '..';
import type { Scene } from '../../../scene';

import type { CircleConfig } from './types';

const _CircleGeometry = new CircleGeometry();

export class Circle extends Shape {
  constructor(scene: Scene, {
    radius = 1.0,
    ...config
  }: CircleConfig) {
    super(scene, {
      ...config,
      geometry: _CircleGeometry,
      rotation: new Vector3(-0.5 * Math.PI, 0, 0),
    });

    if (radius !== 1.0) {
      this.setRadius(radius);
    }

    this.object.renderOrder = 1;
  }

  public setRadius(radius: number) {
    this.setScale({ x: radius, y: radius, z: 1.0 });
  }
}
