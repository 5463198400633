import { BattleDifficult } from '~feature/shared/battle/types';

import IconHard from './icons/hard.svg';
import IconNormal from './icons/normal.svg';
import IconTutorial from './icons/tutorial.svg';

export const DIFFICULT_ICON: Record<BattleDifficult, React.FC> = {
  [BattleDifficult.Tutorial]: IconTutorial,
  [BattleDifficult.Normal]: IconNormal,
  [BattleDifficult.Hard]: IconHard,
};
