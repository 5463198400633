import { Assets } from '~core/client/assets';
import { ModelType } from '~core/client/assets/types';

import bin from './spawner-medium.bin';
import gltf from './spawner-medium.gltf';

Assets.addModel(ModelType.BuildingSpawnerMedium, {
  gltf,
  bin: {
    origin: 'spawner-medium.bin',
    path: bin,
  },
});
