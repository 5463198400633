import type { NPCSchema } from '~feature/shared/battle/entity/unit/npc/types';

import { Unit } from '..';
import type { Battle } from '../../..';

import type { NPCConfig } from './types';

export abstract class NPC extends Unit {
  declare public readonly schema: NPCSchema;

  constructor(battle: Battle, config: NPCConfig, schema: NPCSchema) {
    super(battle, config, schema);

    this.enableInterpolation();
  }
}
