import { CubeGeometry } from '~core/shared/geometry/cube';

import { Shape } from '..';
import type { Scene } from '../../../scene';

import type { CubeConfig } from './types';

const _CubeGeometry = CubeGeometry.make();

export class Cube extends Shape {
  constructor(scene: Scene, config: CubeConfig) {
    super(scene, {
      ...config,
      geometry: _CubeGeometry,
    });
  }
}
