import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';

import type { Relay } from '~feature/client/relay';

import styles from './styles.module.scss';

import IconPlayers from './icons/players.svg';

export const PlayersOnline: React.FC = () => {
  const room = useRoom<Relay>();
  const language = useLanguage();

  const online = useSchemaValue(room.state, 'online');

  return (
    <Section
      direction="horizontal"
      align="center"
      gap={16}
      className={styles.container}
    >
      <IconPlayers />
      <Section direction="horizontal" align="center" gap={6}>
        <Text size="s" bold>
          {online}
        </Text>
        <Text size="xs" uppercase>
          {online === 1 ? language('PlayerOnline') : language('PlayersOnline')}
        </Text>
      </Section>
    </Section>
  );
};
