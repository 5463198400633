import type { ShotSchema } from '~feature/shared/battle/entity/shot/types';

import type { Battle } from '../../..';

import { SHOT_INSTANCES } from './const';

export class ShotFactory {
  public static create(battle: Battle, schema: ShotSchema) {
    const ShotInstance = SHOT_INSTANCES[schema.variant];

    return new ShotInstance(battle, schema);
  }
}
