import {
  BINARY_EXTENSION_CHUNK_TYPES,
  BINARY_EXTENSION_HEADER_LENGTH,
  BINARY_EXTENSION_HEADER_MAGIC,
} from './const';
import type { ExtensionHeader } from './types';

export class GLTFBinary {
  private readonly header: ExtensionHeader;

  private readonly content: Nullable<string> = null;

  constructor(data: ArrayBuffer) {
    const headerView = new DataView(data, 0, BINARY_EXTENSION_HEADER_LENGTH);
    const textDecoder = new TextDecoder();

    this.header = {
      magic: textDecoder.decode(new Uint8Array(data.slice(0, 4))),
      version: headerView.getUint32(4, true),
      length: headerView.getUint32(8, true),
    };

    if (this.header.magic !== BINARY_EXTENSION_HEADER_MAGIC) {
      throw new Error('Unsupported binary header');
    }

    if (this.header.version < 2.0) {
      throw new Error('Legacy binary file detected');
    }

    const chunkContentsLength = this.header.length - BINARY_EXTENSION_HEADER_LENGTH;
    const chunkView = new DataView(data, BINARY_EXTENSION_HEADER_LENGTH);
    let chunkIndex = 0;

    while (chunkIndex < chunkContentsLength) {
      const chunkLength = chunkView.getUint32(chunkIndex, true);
      chunkIndex += 4;

      const chunkType = chunkView.getUint32(chunkIndex, true);
      chunkIndex += 4;

      switch (chunkType) {
        case BINARY_EXTENSION_CHUNK_TYPES.JSON: {
          const byteOffset = BINARY_EXTENSION_HEADER_LENGTH + chunkIndex;
          const contentArray = new Uint8Array(data, byteOffset, chunkLength);
          this.content = textDecoder.decode(contentArray);
          break;
        }
        case BINARY_EXTENSION_CHUNK_TYPES.BIN: {
          const byteOffset = BINARY_EXTENSION_HEADER_LENGTH + chunkIndex;
          data.slice(byteOffset, byteOffset + chunkLength);
          break;
        }
      }

      chunkIndex += chunkLength;
    }
  }

  public getContent() {
    if (!this.content) {
      throw new Error('Binary content not found');
    }
    return this.content;
  }
}
