import { Assets } from '~core/client/assets';
import { AudioType, AudioMode } from '~core/client/audio/types';

import audioJoin from './join.mp3';

Assets.addAudio(AudioType.Join, {
  path: audioJoin,
  mode: AudioMode.Track2D,
  volume: 1.0,
});
