import { Events } from 'make-event';

import { Input } from '..';

import { InputTouchChannel } from './channel';
import { INPUT_TOUCH_MIN_SWIPE_DISTANCE } from './const';
import { InputTouchSwipeDirection } from './types';
import type { InputTouchSwipeEvent } from './types';

export class InputTouch {
  private static readonly channels: Map<number, InputTouchChannel> = new Map();

  public static readonly onTouch = Events.make<InputTouchChannel>();
  public static readonly onTouchWorld = Events.make<InputTouchChannel>();
  public static readonly onTouchSwipe = Events.make<InputTouchSwipeEvent>();

  public static addListeners() {
    this.listenTouchStart();
    this.listenTouchEnd();
    this.listenTouchMove();
  }

  private static getFreeTouch(): Nullable<InputTouchChannel> {
    const list = Array.from(this.channels.values()).reverse();
    return list.find((touch) => !touch.taken) ?? null;
  }

  private static listenTouchStart() {
    document.addEventListener('touchstart', (event: TouchEvent) => {
      if (!Input.isNativeTarget(event)) {
        event.preventDefault();
      }

      const isWorldTarget = Input.isWorldTarget(event);

      Array.from(event.changedTouches).forEach((touch) => {
        const channel = new InputTouchChannel({ touch, event });
        this.channels.set(touch.identifier, channel);
        this.onTouch.invoke(channel);
        if (isWorldTarget) {
          this.onTouchWorld.invoke(channel);
        }
      });
    }, {
      passive: false,
    });
  }

  private static listenTouchEnd() {
    document.addEventListener('touchend', (event: TouchEvent) => {
      if (!Input.isNativeTarget(event)) {
        event.preventDefault();
      }

      const touch = this.getFreeTouch();
      if (touch) {
        this.handleTouchSwipe(touch);
      }

      Array.from(event.changedTouches).forEach((touch) => {
        const channel = this.channels.get(touch.identifier);
        if (channel) {
          channel.onRelease.invoke();
          this.channels.delete(touch.identifier);
        }
      });
    });
  }

  private static listenTouchMove() {
    document.addEventListener('touchmove', (event: TouchEvent) => {
      if (!Input.isNativeTarget(event)) {
        event.preventDefault();
      }

      Array.from(event.touches).forEach((touch) => {
        const channel = this.channels.get(touch.identifier);
        if (channel) {
          channel.updatePosition(touch);
          channel.checkShifting();
          channel.onMove.invoke();
        }
      });
    });
  }

  private static handleTouchSwipe(touch: InputTouchChannel) {
    const shift = touch.beginPosition.clone().sub(touch.position);
    if (shift.length() < INPUT_TOUCH_MIN_SWIPE_DISTANCE) {
      return;
    }

    let direction: InputTouchSwipeDirection;
    if (Math.abs(shift.x) > Math.abs(shift.y)) {
      if (shift.x > 0) {
        direction = InputTouchSwipeDirection.Left;
      } else {
        direction = InputTouchSwipeDirection.Right;
      }
    } else {
      if (shift.y > 0) {
        direction = InputTouchSwipeDirection.Up;
      } else {
        direction = InputTouchSwipeDirection.Down;
      }
    }

    this.onTouchSwipe.invoke({
      position: touch.beginPosition,
      direction,
    });
  }
}
