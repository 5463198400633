export type SceneConfig = {
  light: number;
  backgroundColor: number;
};

export enum SceneResolution {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum SceneLayer {
  Misc = 1,
}
