export enum WebSocketCloseCode {
  Normal = 4000,
  Error = 4002,
  Restart = 6000,
  Crash = 6001,
}

export enum WebSocketErrorCode {
  ServerError = 5000,
  ConnectionFailed = 5001,
}
