import { useSchemaListener } from 'colyseus-schema-hooks';
import { useState } from 'react';

import { useBatch } from '~core/client/ui/hooks/use-batch';

import type { InventoryItemType } from '~feature/shared/mixed/inventory/item/types';
import { InventoryUtils } from '~feature/shared/mixed/inventory/utils';

import { useUserData } from '../../../../hooks/use-user-data';

export function useInventoryItem(type: InventoryItemType, slot: number) {
  const inventory = useUserData('inventory');

  const getItem = () => (
    InventoryUtils.getItem(inventory, type, slot)
  );

  const [item, setItem] = useState(getItem);

  const update = useBatch(() => {
    setItem(getItem());
  });

  useSchemaListener(inventory.slots, {
    onAdd: update,
    onChange: update,
    onRemove: update,
  });

  return item;
}
