import React from 'react';

import { SettingsPanel } from '~core/client/ui/components/settings-panel';

import { Sheet } from '../../sheet';

export const PageSettings: React.FC = () => (
  <Sheet>
    <SettingsPanel />
  </Sheet>
);

