import React, { useState } from 'react';

import { Settings } from '~core/client/settings';
import type { SettingsType } from '~core/client/settings/types';
import { Section } from '~core/client/ui/components/section';

import { Param } from '..';
import { Option } from '../option';

import styles from './styles.module.scss';

type Props = {
  type: SettingsType;
  defaultValue: number;
  options: number[];
  save?: boolean;
  onChange: (value: number) => void;
};

export const ParamInteger: React.FC<Props> = ({
  type,
  defaultValue,
  options,
  save = true,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (newValue: number) => {
    setValue(newValue);
    onChange(newValue);

    if (save) {
      Settings.setInteger(type, newValue);
    }
  };

  return (
    <Param type={type}>
      <Section direction="horizontal" gap={1} className={styles.wrapper}>
        {options.map((option) => (
          <Option
            key={option}
            value={option}
            active={value === option}
            onClick={() => handleChange(option)}
          />
        ))}
      </Section>
    </Param>
  );
};
