import { InstancedMesh } from 'three';

import type { Scene } from '../../scene';

import type { MeshSetConfig } from './types';

export class MeshSet extends InstancedMesh {
  constructor(scene: Scene, { geometry, material, children }: MeshSetConfig) {
    super(geometry, material, children.length);

    children.forEach((child, index) => {
      child.put(this, index);
    });

    scene.add(this);
  }

  public destroy(): void {
    this.removeFromParent();
    this.dispose();
  }
}

