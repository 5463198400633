import { MaterialType } from '~core/client/assets/materials/types';
import { AudioType } from '~core/client/audio/types';

import type { Battle } from '~feature/client/battle';
import type { ShotSchema } from '~feature/shared/battle/entity/shot/types';

import { Bullet } from '..';

import './resources';

export class BulletSimple extends Bullet {
  constructor(battle: Battle, schema: ShotSchema) {
    super(battle, {
      size: 0.5,
      material: MaterialType.BulletSimple,
      audioEffect: AudioType.ShotSimple,
    }, schema);
  }
}

