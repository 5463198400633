import { Assets } from '~core/client/assets';
import { AudioType, AudioMode } from '~core/client/audio/types';

import audioComplete from './complete.mp3';
import audioStart from './start.mp3';
import audioTick from './tick.mp3';

Assets.addAudio(AudioType.WaveTick, {
  path: audioTick,
  mode: AudioMode.Track2D,
  volume: 0.5,
});

Assets.addAudio(AudioType.WaveStart, {
  path: audioStart,
  mode: AudioMode.Track2D,
  volume: 0.4,
});

Assets.addAudio(AudioType.WaveComplete, {
  path: audioComplete,
  mode: AudioMode.Track2D,
  volume: 0.7,
});
