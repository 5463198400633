import cn from 'classnames';
import React from 'react';

import { Icon } from '~core/client/ui/components/icon';
import { Interactive } from '~core/client/ui/components/interactive';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  icon?: SVGIcon;
  size?: 's' | 'm' | 'l';
  view?: 'transparent' | 'default' | 'accent' | 'negative' | 'dark';
  loading?: boolean;
  onClick?: VoidFunction;
};

export const Button: React.FC<Props> = ({
  children,
  onClick,
  disabled,
  loading,
  icon,
  view = 'default',
  size = 'm',
}) => (
  <Interactive
    tag='button'
    disabled={disabled || loading}
    onClick={onClick}
    className={cn(styles.button, styles[`size-${size}`], styles[`view-${view}`], {
      [styles.loading]: loading,
      [styles.disabled]: disabled,
    })}
  >
    <Icon type={icon} className={styles.icon} />
    {children}
  </Interactive>
);
