import { Assets } from '~core/client/assets';
import { ModelType } from '~core/client/assets/types';

import bin from './generator.bin';
import gltf from './generator.gltf';

Assets.addModel(ModelType.BuildingGenerator, {
  gltf,
  bin: {
    origin: 'generator.bin',
    path: bin,
  },
});
