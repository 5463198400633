// @ts-nocheck

import {
  AnimationClip,
  Bone,
  BufferAttribute,
  BufferGeometry,
  ColorManagement,
  FileLoader,
  Group,
  InterleavedBuffer,
  InterleavedBufferAttribute,
  InterpolateLinear,
  LinearSRGBColorSpace,
  LoaderUtils,
  Material,
  Matrix4,
  Mesh,
  NumberKeyframeTrack,
  Object3D,
  PropertyBinding,
  QuaternionKeyframeTrack,
  Skeleton,
  SkinnedMesh,
  Texture,
  VectorKeyframeTrack,
} from 'three';

import { Logger } from '~core/client/logger';
import { Request } from '~core/client/router/request';

import { AssetsModels } from '..';
import type { GLTFBinary } from '../binary';
import type { LoaderParsedData } from '../loader/types';

import { WEBGL_TYPE_SIZES, WEBGL_COMPONENT_TYPES, ATTRIBUTES, PATH_PROPERTIES, INTERPOLATION } from './const';
import type { GLTFParserOptions } from './types';

const _Matrix4 = new Matrix4();

export class GLTFParser {
  public readonly fileLoader: FileLoader;

  private readonly json: LoaderParsedData;

  private readonly path: string;

  private readonly cache: Map = new Map();

  private readonly associations: Map = new Map();

  private extensions: Record<string, GLTFBinary> = {};

  constructor(
    json: LoaderParsedData,
    { path, manager, crossOrigin }: GLTFParserOptions,
  ) {
    this.json = json;
    this.path = path;

    this.primitiveCache = {};
    this.nodeCache = {};
    this.sourceCache = {};
    this.meshCache = { refs: {}, uses: {} };

    this.nodeNamesUsed = {};

    this.fileLoader = new FileLoader(manager);
    this.fileLoader.setResponseType('arraybuffer');

    if (crossOrigin === 'use-credentials') {
      this.fileLoader.setWithCredentials(true);
    }
  }

  setExtensions(extensions: Record<string, GLTFBinary>) {
    this.extensions = extensions;
  }

  parse(onLoad, onError) {
    const json = this.json;

    this.cache.clear();
    this.nodeCache = {};

    this._markDefs();

    Promise.all([
      this.getDependencies('scene'),
      this.getDependencies('animation'),
    ])
      .then((dependencies) => {
        const result = {
          scene: dependencies[0][json.scene || 0],
          scenes: dependencies[0],
          animations: dependencies[1],
          asset: json.asset,
          parser: this,
        };

        onLoad(result);
      })
      .catch(onError);
  }

  _markDefs() {
    const nodeDefs = this.json.nodes || [];
    const skinDefs = this.json.skins || [];
    const meshDefs = this.json.meshes || [];

    for (
      let skinIndex = 0, skinLength = skinDefs.length;
      skinIndex < skinLength;
      skinIndex++
    ) {
      const joints = skinDefs[skinIndex].joints;

      for (let i = 0, il = joints.length; i < il; i++) {
        nodeDefs[joints[i]].isBone = true;
      }
    }

    for (
      let nodeIndex = 0, nodeLength = nodeDefs.length;
      nodeIndex < nodeLength;
      nodeIndex++
    ) {
      const nodeDef = nodeDefs[nodeIndex];

      if (nodeDef.mesh !== undefined) {
        this._addNodeRef(this.meshCache, nodeDef.mesh);
        if (nodeDef.skin !== undefined) {
          meshDefs[nodeDef.mesh].isSkinnedMesh = true;
        }
      }
    }
  }

  _addNodeRef(cache, index) {
    if (index === undefined) {
      return;
    }

    if (cache.refs[index] === undefined) {
      cache.refs[index] = cache.uses[index] = 0;
    }

    cache.refs[index]++;
  }

  _getNodeRef(cache, index, object) {
    if (cache.refs[index] <= 1) {
      return object;
    }

    const ref = object.clone();

    const updateMappings = (original, clone) => {
      const mappings = this.associations.get(original);
      if (mappings != null) {
        this.associations.set(clone, mappings);
      }

      for (const [i, child] of original.children.entries()) {
        updateMappings(child, clone.children[i]);
      }
    };

    updateMappings(object, ref);

    ref.name += '_instance_' + cache.uses[index]++;

    return ref;
  }

  getDependency(type, index) {
    const cacheKey = type + ':' + index;
    let dependency = this.cache.get(cacheKey);
    if (dependency) {
      return dependency;
    }

    switch (type) {
      case 'scene':
        dependency = this.loadScene(index);
        break;

      case 'node':
        dependency = this.loadNode(index);
        break;

      case 'mesh':
        dependency = this.loadMesh(index);
        break;

      case 'accessor':
        dependency = this.loadAccessor(index);
        break;

      case 'bufferView':
        dependency = this.loadBufferView(index);
        break;

      case 'buffer':
        dependency = this.loadBuffer(index);
        break;

      case 'material':
      case 'texture':
        // SKIP
        break;

      case 'skin':
        dependency = this.loadSkin(index);
        break;

      case 'animation':
        dependency = this.loadAnimation(index);
        break;

      default:
        if (!dependency) {
          throw new Error(`Unknown children type '${type}' of model`);
        }

        break;
    }

    this.cache.set(cacheKey, dependency);

    return dependency;
  }

  getDependencies(type) {
    let dependencies = this.cache.get(type);
    if (!dependencies) {
      const defs = this.json[type + (type === 'mesh' ? 'es' : 's')] || [];
      dependencies = Promise.all(
        defs.map((def, index) => {
          return this.getDependency(type, index);
        }),
      );

      this.cache.set(type, dependencies);
    }

    return dependencies;
  }

  loadBuffer(bufferIndex) {
    const bufferDef = this.json.buffers[bufferIndex];
    const fileName = AssetsModels.binaries.get(bufferDef.uri);
    if (!fileName) {
      throw new Error(`Failed to prepare buffer for ${bufferDef.uri}. URL isn\`t registered`);
    }

    if (bufferDef.type && bufferDef.type !== 'arraybuffer') {
      throw new Error(`Failed to prepare buffer for ${bufferDef.uri}. Type isn\`t supported`);
    }

    return new Promise((resolve, reject) => {
      const url = LoaderUtils.resolveURL(fileName, this.path);
      this.fileLoader.load(url, resolve, undefined, (error) => {
        const reason = Request.getNativeError(error);
        reject(new Error(`Failed to load buffer ${fileName}. ${reason}`));
      });
    });
  }

  loadBufferView(bufferViewIndex) {
    const bufferViewDef = this.json.bufferViews[bufferViewIndex];

    return this.getDependency('buffer', bufferViewDef.buffer).then((buffer) => {
      const byteLength = bufferViewDef.byteLength || 0;
      const byteOffset = bufferViewDef.byteOffset || 0;
      return buffer.slice(byteOffset, byteOffset + byteLength);
    });
  }

  loadAccessor(accessorIndex) {
    const json = this.json;
    const accessorDef = this.json.accessors[accessorIndex];

    if (
      accessorDef.bufferView === undefined &&
      accessorDef.sparse === undefined
    ) {
      const itemSize = WEBGL_TYPE_SIZES[accessorDef.type];
      const TypedArray = WEBGL_COMPONENT_TYPES[accessorDef.componentType];
      const normalized = accessorDef.normalized === true;

      const array = new TypedArray(accessorDef.count * itemSize);
      return Promise.resolve(new BufferAttribute(array, itemSize, normalized));
    }

    const pendingBufferViews = [];

    if (accessorDef.bufferView !== undefined) {
      pendingBufferViews.push(
        this.getDependency('bufferView', accessorDef.bufferView),
      );
    } else {
      pendingBufferViews.push(null);
    }

    if (accessorDef.sparse !== undefined) {
      pendingBufferViews.push(
        this.getDependency('bufferView', accessorDef.sparse.indices.bufferView),
      );
      pendingBufferViews.push(
        this.getDependency('bufferView', accessorDef.sparse.values.bufferView),
      );
    }

    return Promise.all(pendingBufferViews).then((bufferViews) => {
      const bufferView = bufferViews[0];

      const itemSize = WEBGL_TYPE_SIZES[accessorDef.type];
      const TypedArray = WEBGL_COMPONENT_TYPES[accessorDef.componentType];

      const elementBytes = TypedArray.BYTES_PER_ELEMENT;
      const itemBytes = elementBytes * itemSize;
      const byteOffset = accessorDef.byteOffset || 0;
      const byteStride =
        accessorDef.bufferView !== undefined
          ? json.bufferViews[accessorDef.bufferView].byteStride
          : undefined;
      const normalized = accessorDef.normalized === true;
      let array, bufferAttribute;

      if (byteStride && byteStride !== itemBytes) {
        const ibSlice = Math.floor(byteOffset / byteStride);
        const ibCacheKey =
          'InterleavedBuffer:' +
          accessorDef.bufferView +
          ':' +
          accessorDef.componentType +
          ':' +
          ibSlice +
          ':' +
          accessorDef.count;
        let ib = this.cache.get(ibCacheKey);

        if (!ib) {
          array = new TypedArray(
            bufferView,
            ibSlice * byteStride,
            (accessorDef.count * byteStride) / elementBytes,
          );

          ib = new InterleavedBuffer(array, byteStride / elementBytes);

          this.cache.set(ibCacheKey, ib);
        }

        bufferAttribute = new InterleavedBufferAttribute(
          ib,
          itemSize,
          (byteOffset % byteStride) / elementBytes,
          normalized,
        );
      } else {
        if (bufferView === null) {
          array = new TypedArray(accessorDef.count * itemSize);
        } else {
          array = new TypedArray(
            bufferView,
            byteOffset,
            accessorDef.count * itemSize,
          );
        }

        bufferAttribute = new BufferAttribute(array, itemSize, normalized);
      }

      if (accessorDef.sparse !== undefined) {
        const itemSizeIndices = WEBGL_TYPE_SIZES.SCALAR;
        const TypedArrayIndices =
          WEBGL_COMPONENT_TYPES[accessorDef.sparse.indices.componentType];

        const byteOffsetIndices = accessorDef.sparse.indices.byteOffset || 0;
        const byteOffsetValues = accessorDef.sparse.values.byteOffset || 0;

        const sparseIndices = new TypedArrayIndices(
          bufferViews[1],
          byteOffsetIndices,
          accessorDef.sparse.count * itemSizeIndices,
        );
        const sparseValues = new TypedArray(
          bufferViews[2],
          byteOffsetValues,
          accessorDef.sparse.count * itemSize,
        );

        if (bufferView !== null) {
          bufferAttribute = new BufferAttribute(
            bufferAttribute.array.slice(),
            bufferAttribute.itemSize,
            bufferAttribute.normalized,
          );
        }

        for (let i = 0, il = sparseIndices.length; i < il; i++) {
          const index = sparseIndices[i];

          bufferAttribute.setX(index, sparseValues[i * itemSize]);
          if (itemSize >= 2)
            bufferAttribute.setY(index, sparseValues[i * itemSize + 1]);
          if (itemSize >= 3)
            bufferAttribute.setZ(index, sparseValues[i * itemSize + 2]);
          if (itemSize >= 4)
            bufferAttribute.setW(index, sparseValues[i * itemSize + 3]);
          if (itemSize >= 5)
            throw new Error('Unsupported item size in sparse BufferAttribute');
        }
      }

      return bufferAttribute;
    });
  }

  private createUniqueName(originalName) {
    const sanitizedName = PropertyBinding.sanitizeNodeName(originalName || '');
    if (sanitizedName in this.nodeNamesUsed) {
      return sanitizedName + '_' + ++this.nodeNamesUsed[sanitizedName];
    } else {
      this.nodeNamesUsed[sanitizedName] = 0;
      return sanitizedName;
    }
  }

  private addPrimitiveAttributes(geometry, primitiveDef) {
    const attributes = primitiveDef.attributes;
    const pending = [];

    for (const gltfAttributeName in attributes) {
      const threeAttributeName = ATTRIBUTES[gltfAttributeName] || gltfAttributeName.toLowerCase();
      if (threeAttributeName in geometry.attributes) {
        continue;
      }

      const accessor = this
        .getDependency('accessor', attributes[gltfAttributeName])
        .then((accessor) => {
          geometry.setAttribute(threeAttributeName, accessor);
        });

      pending.push(accessor);
    }

    if (primitiveDef.indices !== undefined && !geometry.index) {
      const accessor = this
        .getDependency('accessor', primitiveDef.indices)
        .then((accessor) => {
          geometry.setIndex(accessor);
        });

      pending.push(accessor);
    }

    if (
      ColorManagement.workingColorSpace !== LinearSRGBColorSpace &&
      'COLOR_0' in attributes
    ) {
      Logger.warn(`Converting vertex colors from 'srgb-linear' to '${ColorManagement.workingColorSpace}' not supported`);
    }

    return Promise.all(pending).then(() => geometry);
  }

  private createPrimitiveKey(primitiveDef) {
    let geometryKey =
      primitiveDef.indices +
      ':' +
      this.createAttributesKey(primitiveDef.attributes) +
      ':' +
      primitiveDef.mode;

    if (primitiveDef.targets !== undefined) {
      for (let i = 0, il = primitiveDef.targets.length; i < il; i++) {
        geometryKey += ':' + this.createAttributesKey(primitiveDef.targets[i]);
      }
    }

    return geometryKey;
  }

  private createAttributesKey(attributes) {
    return Object.keys(attributes).sort().reduce((current, key) => (
      current + key + ':' + attributes[key] + ';'
    ), '');
  }

  loadGeometries(primitives) {
    const cache = this.primitiveCache;
    const pending = [];

    for (let i = 0, il = primitives.length; i < il; i++) {
      const primitive = primitives[i];
      const cacheKey = this.createPrimitiveKey(primitive);
      const cached = cache[cacheKey];
      if (cached) {
        pending.push(cached.promise);
      } else {
        const geometryPromise = this.addPrimitiveAttributes(
          new BufferGeometry(),
          primitive,
        );

        cache[cacheKey] = {
          primitive: primitive,
          promise: geometryPromise,
        };

        pending.push(geometryPromise);
      }
    }

    return Promise.all(pending);
  }

  loadMesh(meshIndex) {
    const json = this.json;
    const meshDef = json.meshes[meshIndex];
    const primitives = meshDef.primitives;

    return this.loadGeometries(primitives).then((geometries) => {
      const meshes = [];

      for (let i = 0, il = geometries.length; i < il; i++) {
        const geometry = geometries[i];
        const primitive = primitives[i];

        if (primitive.mode) {
          throw new Error(`Primitive mode '${primitive.mode}' not supported`);
        }

        const mesh = meshDef.isSkinnedMesh
          ? new SkinnedMesh(geometry)
          : new Mesh(geometry);

        if (mesh.isSkinnedMesh) {
          mesh.normalizeSkinWeights();
        }

        mesh.name = this.createUniqueName(meshDef.name || 'mesh_' + meshIndex);

        meshes.push(mesh);
      }

      for (let i = 0, il = meshes.length; i < il; i++) {
        this.associations.set(meshes[i], {
          meshes: meshIndex,
          primitives: i,
        });
      }

      if (meshes.length === 1) {
        return meshes[0];
      }

      const group = new Group();
      this.associations.set(group, {
        meshes: meshIndex,
      });
      for (let i = 0, il = meshes.length; i < il; i++) {
        group.add(meshes[i]);
      }

      return group;
    });
  }

  loadSkin(skinIndex) {
    const skinDef = this.json.skins[skinIndex];
    const pending = [];

    for (let i = 0, il = skinDef.joints.length; i < il; i++) {
      pending.push(this.loadNodeShallow(skinDef.joints[i]));
    }

    if (skinDef.inverseBindMatrices !== undefined) {
      pending.push(this.getDependency('accessor', skinDef.inverseBindMatrices));
    } else {
      pending.push(null);
    }

    return Promise.all(pending).then((results) => {
      const inverseBindMatrices = results.pop();
      const jointNodes = results;

      const bones = [];
      const boneInverses = [];

      for (let i = 0, il = jointNodes.length; i < il; i++) {
        const jointNode = jointNodes[i];
        if (jointNode) {
          bones.push(jointNode);

          const mat = new Matrix4();

          if (inverseBindMatrices !== null) {
            mat.fromArray(inverseBindMatrices.array, i * 16);
          }

          boneInverses.push(mat);
        } else {
          Logger.warn(`Joint '${skinDef.joints[i]}' could not be found`);
        }
      }

      return new Skeleton(bones, boneInverses);
    });
  }

  loadAnimation(animationIndex) {
    const json = this.json;

    const animationDef = json.animations[animationIndex];
    const animationName = animationDef.name
      ? animationDef.name
      : 'animation_' + animationIndex;

    const pendingNodes = [];
    const pendingInputAccessors = [];
    const pendingOutputAccessors = [];
    const pendingSamplers = [];
    const pendingTargets = [];

    for (let i = 0, il = animationDef.channels.length; i < il; i++) {
      const channel = animationDef.channels[i];
      const sampler = animationDef.samplers[channel.sampler];
      const target = channel.target;
      const name = target.node;
      const input =
        animationDef.parameters !== undefined
          ? animationDef.parameters[sampler.input]
          : sampler.input;
      const output =
        animationDef.parameters !== undefined
          ? animationDef.parameters[sampler.output]
          : sampler.output;

      if (target.node === undefined) continue;

      pendingNodes.push(this.getDependency('node', name));
      pendingInputAccessors.push(this.getDependency('accessor', input));
      pendingOutputAccessors.push(this.getDependency('accessor', output));
      pendingSamplers.push(sampler);
      pendingTargets.push(target);
    }

    return Promise.all([
      Promise.all(pendingNodes),
      Promise.all(pendingInputAccessors),
      Promise.all(pendingOutputAccessors),
      Promise.all(pendingSamplers),
      Promise.all(pendingTargets),
    ]).then((dependencies) => {
      const nodes = dependencies[0];
      const inputAccessors = dependencies[1];
      const outputAccessors = dependencies[2];
      const samplers = dependencies[3];
      const targets = dependencies[4];

      const tracks = [];

      for (let i = 0, il = nodes.length; i < il; i++) {
        const node = nodes[i];
        const inputAccessor = inputAccessors[i];
        const outputAccessor = outputAccessors[i];
        const sampler = samplers[i];
        const target = targets[i];

        if (node === undefined) continue;

        if (node.updateMatrix) {
          node.updateMatrix();
        }

        const createdTracks = this.createAnimationTracks(
          node,
          inputAccessor,
          outputAccessor,
          sampler,
          target,
        );

        if (createdTracks) {
          for (let k = 0; k < createdTracks.length; k++) {
            tracks.push(createdTracks[k]);
          }
        }
      }

      return new AnimationClip(animationName, undefined, tracks);
    });
  }

  createNodeMesh(nodeIndex) {
    const json = this.json;
    const nodeDef = json.nodes[nodeIndex];
    if (nodeDef.mesh === undefined) {
      return null;
    }

    return this.getDependency('mesh', nodeDef.mesh).then((mesh) => {
      const node = this._getNodeRef(this.meshCache, nodeDef.mesh, mesh);
      if (nodeDef.weights !== undefined) {
        node.traverse((o) => {
          if (!o.isMesh) {
            return;
          }

          for (let i = 0, il = nodeDef.weights.length; i < il; i++) {
            o.morphTargetInfluences[i] = nodeDef.weights[i];
          }
        });
      }

      return node;
    });
  }

  loadNode(nodeIndex) {
    const json = this.json;

    const nodeDef = json.nodes[nodeIndex];

    const nodePending = this.loadNodeShallow(nodeIndex);

    const childPending = [];
    const childrenDef = nodeDef.children || [];

    for (let i = 0, il = childrenDef.length; i < il; i++) {
      childPending.push(this.getDependency('node', childrenDef[i]));
    }

    const skeletonPending =
      nodeDef.skin === undefined
        ? Promise.resolve(null)
        : this.getDependency('skin', nodeDef.skin);

    return Promise.all([
      nodePending,
      Promise.all(childPending),
      skeletonPending,
    ]).then((results) => {
      const node = results[0];
      const children = results[1];
      const skeleton = results[2];

      if (skeleton !== null) {
        node.traverse((mesh) => {
          if (mesh.isSkinnedMesh) {
            mesh.bind(skeleton, _Matrix4);
          }
        });
      }

      for (let i = 0, il = children.length; i < il; i++) {
        node.add(children[i]);
      }

      return node;
    });
  }

  private loadNodeShallow(nodeIndex) {
    const json = this.json;

    if (this.nodeCache[nodeIndex] !== undefined) {
      return this.nodeCache[nodeIndex];
    }

    const nodeDef = json.nodes[nodeIndex];
    const nodeName = nodeDef.name ? this.createUniqueName(nodeDef.name) : '';

    const pending = [];

    const meshPromise = this.createNodeMesh(nodeIndex);
    if (meshPromise) {
      pending.push(meshPromise);
    }

    this.nodeCache[nodeIndex] = Promise.all(pending).then((objects) => {
      let node;
      if (nodeDef.isBone === true) {
        node = new Bone();
      } else if (objects.length > 1) {
        node = new Group();
      } else if (objects.length === 1) {
        node = objects[0];
      } else {
        node = new Object3D();
      }

      if (node !== objects[0]) {
        for (let i = 0, il = objects.length; i < il; i++) {
          node.add(objects[i]);
        }
      }

      if (nodeName) {
        node.name = nodeName;
      }

      if (nodeDef.matrix !== undefined) {
        const matrix = new Matrix4();
        matrix.fromArray(nodeDef.matrix);
        node.applyMatrix4(matrix);
      } else {
        if (nodeDef.translation !== undefined) {
          node.position.fromArray(nodeDef.translation);
        }
        if (nodeDef.rotation !== undefined) {
          node.quaternion.fromArray(nodeDef.rotation);
        }
        if (nodeDef.scale !== undefined) {
          node.scale.fromArray(nodeDef.scale);
        }
      }

      if (!this.associations.has(node)) {
        this.associations.set(node, {});
      }

      this.associations.get(node).nodes = nodeIndex;

      return node;
    });

    return this.nodeCache[nodeIndex];
  }

  loadScene(sceneIndex) {
    const sceneDef = this.json.scenes[sceneIndex];
    const scene = new Group();
    if (sceneDef.name) {
      scene.name = this.createUniqueName(sceneDef.name);
    }

    const nodeIds = sceneDef.nodes || [];
    const pending = [];

    for (let i = 0, il = nodeIds.length; i < il; i++) {
      pending.push(this.getDependency('node', nodeIds[i]));
    }

    return Promise.all(pending).then((nodes) => {
      for (let i = 0, il = nodes.length; i < il; i++) {
        scene.add(nodes[i]);
      }

      const reduceAssociations = (node) => {
        const reducedAssociations = new Map();
        for (const [key, value] of this.associations) {
          if (key instanceof Material || key instanceof Texture) {
            reducedAssociations.set(key, value);
          }
        }

        node.traverse((node) => {
          const mappings = this.associations.get(node);
          if (mappings != null) {
            reducedAssociations.set(node, mappings);
          }
        });

        return reducedAssociations;
      };

      this.associations = reduceAssociations(scene);

      return scene;
    });
  }

  private createAnimationTracks(node, inputAccessor, outputAccessor, sampler, target) {
    const tracks = [];

    const targetName = node.name ? node.name : node.uuid;
    const targetNames = [];

    if (PATH_PROPERTIES[target.path] === PATH_PROPERTIES.weights) {
      node.traverse((object) => {
        if (object.morphTargetInfluences) {
          targetNames.push(object.name ? object.name : object.uuid);
        }
      });
    } else {
      targetNames.push(targetName);
    }

    let TypedKeyframeTrack;

    switch (PATH_PROPERTIES[target.path]) {
      case PATH_PROPERTIES.weights:
        TypedKeyframeTrack = NumberKeyframeTrack;
        break;

      case PATH_PROPERTIES.rotation:
        TypedKeyframeTrack = QuaternionKeyframeTrack;
        break;

      case PATH_PROPERTIES.position:
      case PATH_PROPERTIES.scale:
        TypedKeyframeTrack = VectorKeyframeTrack;
        break;

      default:
        switch (outputAccessor.itemSize) {
          case 1:
            TypedKeyframeTrack = NumberKeyframeTrack;
            break;
          case 2:
          case 3:
          default:
            TypedKeyframeTrack = VectorKeyframeTrack;
            break;
        }

        break;
    }

    const interpolation =
      sampler.interpolation !== undefined
        ? INTERPOLATION[sampler.interpolation]
        : InterpolateLinear;

    for (let j = 0, jl = targetNames.length; j < jl; j++) {
      const track = new TypedKeyframeTrack(
        targetNames[j] + '.' + PATH_PROPERTIES[target.path],
        inputAccessor.array,
        outputAccessor.array,
        interpolation,
      );

      tracks.push(track);
    }

    return tracks;
  }
}
