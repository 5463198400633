// extracted by mini-css-extract-plugin
var _1 = "CSoZj3LSZaWecxQ3Nxw8";
var _2 = "gpqLbDTOSso63svrP8Jq";
var _3 = "gmUocF0V8TE7vsLtwn5B";
var _4 = "LKcP_iSaViTzWSWXcKXO";
var _5 = "WjXPQqWSLhQtNEYj8XTn";
var _6 = "cPtZTjDhMGb4r8B_H5u6";
var _7 = "SansUT_i6sbGQoEg5o4B";
export { _1 as "container", _2 as "label", _3 as "size-l", _4 as "size-m", _5 as "view-default", _6 as "view-light", _7 as "view-transparent" }
export default { "container": _1, "label": _2, "size-l": _3, "size-m": _4, "view-default": _5, "view-light": _6, "view-transparent": _7 }
