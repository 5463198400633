// extracted by mini-css-extract-plugin
var _1 = "vW8O8WYEa7dh8liFqBeX";
var _2 = "w8uQeiqOqyyIAHOpnRj4";
var _3 = "ehrl20gLZzJZVC8ro6pK";
var _4 = "wbS77GF6Ezo0KNHEWoPZ";
var _5 = "KTEWcw_ZLGRFaL8L8m_z";
var _6 = "NeMyulL1zTDvjYslmIwB";
var _7 = "eVxykb6OXJv1rt7b5iv_";
var _8 = "ZSZ5XW6WbNA9ZHw7nUMY";
export { _1 as "active", _2 as "amount", _3 as "animation-blink", _4 as "cost", _5 as "less", _6 as "postfix", _7 as "stage", _8 as "stages" }
export default { "active": _1, "amount": _2, "animation-blink": _3, "cost": _4, "less": _5, "postfix": _6, "stage": _7, "stages": _8 }
