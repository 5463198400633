import { ShaderMaterial, Vector2 } from 'three';

import { Assets } from '~core/client/assets';
import { MaterialType } from '~core/client/assets/materials/types';

import { FOG_MAX_LIGHTS } from '../../const';

import { SHADER_FOG_FRAGMENT } from './shader/fragment';
import { SHADER_FOG_VERTEX } from './shader/vertex';

Assets.addMaterial(MaterialType.Fog, new ShaderMaterial({
  vertexShader: SHADER_FOG_VERTEX,
  fragmentShader: SHADER_FOG_FRAGMENT,
  depthTest: false,
  transparent: true,
  uniforms: {
    maskRadius: { value: Array(FOG_MAX_LIGHTS).fill(1.0) },
    maskPosition: { value: Array(FOG_MAX_LIGHTS).fill(new Vector2()) },
    lightsCount: { value: 0 },
  },
}));
