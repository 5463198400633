import type { Schema } from '@colyseus/schema';

import type { VectorSchema } from '~core/shared/vector-schema';

export interface DecorationSchema extends Schema {
  readonly position: VectorSchema;
  readonly type: DecorationType;
  readonly index: number;
}

export enum DecorationType {
  Rock = 'Rock',
  Tree = 'Tree',
  Mushroom = 'Mushroom',
}
