import React from 'react';

import type { AmountProps } from '~core/client/ui/components/amount';
import { Amount } from '~core/client/ui/components/amount';

import IconResources from './icons/resources.svg';

type Props = Omit<AmountProps, 'icon' | 'color'>;

export const Resources: React.FC<Props> = (props) => (
  <Amount
    {...props}
    icon={IconResources}
    color='#4ae49d'
  />
);
