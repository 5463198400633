import { useSchemaValue } from 'colyseus-schema-hooks';

import { Client } from '~core/client/client';
import { useRoom } from '~core/client/room/ui/hooks/use-room';

import type { UserSchema } from '~feature/shared/mixed/user/types';

import type { Relay } from '../..';

export function useUserData<K extends PropOf<UserSchema>>(prop: K) {
  const room = useRoom<Relay>();

  const user = room.state.users.get(Client.sessionId);
  if (!user) {
    throw Error('Undefined current user');
  }

  return useSchemaValue(user, prop);
}
