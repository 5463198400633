import { SkillVariant } from '~feature/shared/battle/entity/unit/player/skill/types';

import iconAttackRecovery from './icons/attack-recovery.svg';
import iconBoostGenerators from './icons/boost-generators.svg';
import iconBoostSpeed from './icons/boost-speed.svg';
import iconCallingMobs from './icons/calling-mobs.svg';
import iconDamageReflection from './icons/damage-reflection.svg';
import iconDiscountRepair from './icons/discount-repair.svg';
import iconDiscountWalls from './icons/discount-walls.svg';
import iconDistantVision from './icons/distant-vision.svg';
import iconFrostWave from './icons/frost-wave.svg';
import iconHighDamage from './icons/high-damage.svg';
import iconInspirationDroid from './icons/inspiration-droid.svg';
import iconInspirationMobs from './icons/inspiration-mobs.svg';
import iconInspirationTowers from './icons/inspiration-towers.svg';
import iconSacrifice from './icons/sacrifice.svg';
import iconVampire from './icons/vampire.svg';

export const SKILL_ICON: Record<SkillVariant, SVGIcon> = {
  [SkillVariant.DiscountWalls]: iconDiscountWalls,
  [SkillVariant.Sacrifice]: iconSacrifice,
  [SkillVariant.BoostGenerators]: iconBoostGenerators,
  [SkillVariant.DamageReflection]: iconDamageReflection,
  [SkillVariant.BoostSpeed]: iconBoostSpeed,
  [SkillVariant.InspirationDroid]: iconInspirationDroid,
  [SkillVariant.InspirationTowers]: iconInspirationTowers,
  [SkillVariant.InspirationMobs]: iconInspirationMobs,
  [SkillVariant.HighDamage]: iconHighDamage,
  [SkillVariant.CallingMobs]: iconCallingMobs,
  [SkillVariant.Vampire]: iconVampire,
  [SkillVariant.AttackRecovery]: iconAttackRecovery,
  [SkillVariant.FrostWave]: iconFrostWave,
  [SkillVariant.DistantVision]: iconDistantVision,
  [SkillVariant.DiscountRepair]: iconDiscountRepair,
};
