import React from 'react';

import { Icon } from '~core/client/ui/components/icon';

import styles from './styles.module.scss';

import IconLogotype from './icons/logotype.svg';

export const Logotype: React.FC = () => (
  <div className={styles.logotype}>
    IZO
    <Icon type={IconLogotype} className={styles.icon} />
    WAVE
  </div>
);
