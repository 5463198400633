import React from 'react';

import { Section } from '~core/client/ui/components/section';

import { MENU_ITEMS } from './const';
import { MenuItem } from './item';

export const Menu: React.FC = () => (
  <Section direction="vertical" gap={8}>
    {MENU_ITEMS.map(({ page, icon, authorizedOnly }) => (
      <MenuItem key={page} type={page} icon={icon} authorizedOnly={authorizedOnly} />
    ))}
  </Section>
);
