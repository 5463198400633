import { Events } from 'make-event';
import { Vector2 } from 'three';

import { Device } from '~core/client/device';

import { INPUT_TOUCH_SHIFT_DISTANCE } from '../const';

import type { InputTouchChannelData } from './types';

export class InputTouchChannel {
  public readonly position: Vector2 = new Vector2();

  public readonly normalizedPosition: Vector2 = new Vector2();

  public readonly beginPosition: Vector2 = new Vector2();

  public readonly identifier: number;

  public readonly target: Nullable<EventTarget>;

  public readonly targets: EventTarget[];

  public readonly onMove = Events.make();
  public readonly onRelease = Events.make();

  public taken: boolean = false;

  public shifted: boolean = false;

  constructor({ touch, event }: InputTouchChannelData) {
    this.target = event.target;
    this.targets = event.composedPath();
    this.identifier = touch.identifier;
    this.updatePosition(touch);
    this.beginPosition.copy(this.position);
  }

  public takeUp() {
    this.taken = true;
  }

  public updatePosition(touch: Touch) {
    const positionOnScreen = Device.getPositionOnScreen({
      x: touch.clientX,
      y: touch.clientY,
    });

    this.position.copy(positionOnScreen);
    this.normalizedPosition.copy(
      Device.normalizePosition(this.position),
    );
  }

  public checkShifting() {
    if (!this.shifted) {
      const distance = this.beginPosition.distanceTo(this.position);
      this.shifted = distance > INPUT_TOUCH_SHIFT_DISTANCE;
    }
  }
}
