import React from 'react';

import { Text } from '~core/client/ui/components/text';

import styles from './styles.module.scss';

import pkg from '../../../../../../../package.json';

export const VersionTag: React.FC = () => {
  const [version, tag] = pkg.version.split('-');

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Text size='s' bold>
          {version}
        </Text>
        {['beta', 'alpha'].includes(tag) && (
          <Text size='xs' uppercase>
            Early Access
          </Text>
        )}
      </div>
    </div>
  );
};
