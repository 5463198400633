import cn from 'classnames';
import React from 'react';

import { Interactive } from '~core/client/ui/components/interactive';

import { Content, type ContentProps } from './content';
import { Graphic } from './graphic';
import type { GraphicProps } from './graphic';
import { Title, type TitleProps } from './title';

import styles from './styles.module.scss';

type Props = {
  alarm?: boolean;
  locked?: boolean;
  blink?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  onClick: VoidFunction;
};

export const Card: React.FC<Props> & {
  Graphic: React.FC<GraphicProps>
  Title: React.FC<TitleProps>
  Content: React.FC<ContentProps>
} = ({ alarm, locked, blink, disabled, children, className, onClick }) => (
  <Interactive
    onClick={onClick}
    disabled={disabled}
    withAudio
    className={cn(styles.container, className, {
      [styles.alarm]: alarm,
      [styles.blink]: blink,
      [styles.disabled]: disabled,
      [styles.locked]: locked,
    })}
  >
    {children}
  </Interactive>
);

Card.Graphic = Graphic;
Card.Title = Title;
Card.Content = Content;
