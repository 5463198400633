import React, { useEffect, useState } from 'react';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { Scrollbar } from '~core/client/ui/components/scrollbar';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';

import type { LeaderboardData, LeaderboardRecord } from '~feature/shared/relay/leaderboard/types';
import { LeaderboardRequest } from '~feature/shared/relay/leaderboard/types';

import type { Relay } from '../../../..';
import { useUserData } from '../../../hooks/use-user-data';
import { Sheet } from '../../sheet';
import { PageType } from '../types';

import { Leader } from './leader';
import { Record } from './record';

import styles from './styles.module.scss';

export const PageLeaderboard: React.FC = () => {
  const room = useRoom<Relay>();
  const authorized = useUserData('authorized');
  const language = useLanguage();

  const [currentPlace, setCurrentPlace] = useState(0);
  const [total, setTotal] = useState(0);
  const [leaders, setLeaders] = useState<LeaderboardRecord[]>([]);
  const [others, setOthers] = useState<LeaderboardRecord[]>([]);

  useEffect(() => {
    room
      .sendRequest<LeaderboardData>(LeaderboardRequest.GetTable)
      .then((result) => {
        setCurrentPlace(result.currentPlace);
        setTotal(result.total);
        setLeaders(result.records.splice(0, 3));
        setOthers(result.records);
      });
  }, []);

  return (
    <Sheet header={(
      <>
        <Text size='2xl' bold uppercase>{language(PageType.Leaderboard)}</Text>
        {(authorized && currentPlace && total) && (
          <Section direction='horizontal' align='center' gap={10}>
            <Text size='m' uppercase>{language('YouRanked')}</Text>
            <Text size='xl' view='primary' bold>{currentPlace}</Text>
            <Text size='m' uppercase>{language('PlaceOf')}</Text>
            <Text size='xl' view='primary' bold>{total}</Text>
          </Section>
        )}
      </>
    )}>
      <Section direction='vertical' className={styles.wrapper}>
        <div className={styles.leaders}>
          {leaders.map((leader) => (
            <Leader key={leader.place} {...leader} />
          ))}
        </div>
        <Scrollbar>
          <div className={styles.table}>
            <table>
              <tbody>
                {others.map((other) => (
                  <Record key={other.place} {...other} />
                ))}
              </tbody>
            </table>
          </div>
        </Scrollbar>
      </Section>
    </Sheet>
  );
};
