import { Color, MeshLambertMaterial } from 'three';

import { Assets } from '~core/client/assets';
import { MaterialType } from '~core/client/assets/materials/types';

Assets.addMaterial(MaterialType.MarkerBase, new MeshLambertMaterial({
  color: new Color(0x00e9ff),
  reflectivity: 0,
}));

Assets.addMaterial(MaterialType.MarkerSelf, new MeshLambertMaterial({
  color: new Color(0x00ff00),
  reflectivity: 0,
}));

Assets.addMaterial(MaterialType.MarkerSelfPlayer, new MeshLambertMaterial({
  color: new Color(0xffffff),
  reflectivity: 0,
}));

Assets.addMaterial(MaterialType.MarkerOpponent, new MeshLambertMaterial({
  color: new Color(0xff0000),
  reflectivity: 0,
}));
