import type { Room } from 'colyseus.js';

import { Logger } from '~core/client/logger';

export class MessagesBuffer {
  private readonly messages: Map<string, Map<string, (...args: any[]) => void>> = new Map();

  public clear(channel?: string) {
    if (channel) {
      this.messages.forEach((callbacks) => {
        callbacks.delete(channel);
      });
    } else {
      this.messages.clear();
    }
  }

  public onMessage(
    origin: Room,
    channel: string,
    message: string,
    callback: (...args: any[]) => void,
  ) {
    let callbacks = this.messages.get(message);
    if (callbacks) {
      callbacks.set(channel, callback);
      return;
    } else {
      callbacks = new Map([[channel, callback]]);
      this.messages.set(message, callbacks);
    }

    origin.onMessage(message, ({ _channel, ...payload }) => {
      if (!_channel) {
        Logger.error(`Undefined channel from server message '${message}'`);
        return;
      }

      const callback = callbacks.get(_channel);
      if (!callback) {
        // if (__MODE === 'development') {
        //   Logger.warn(`Unknown channel '${_channel}' from server message '${message}'`);
        // }
        return;
      }

      callback(payload);
    });
  }
}
