import { Assets } from '~core/client/assets';
import { ModelType } from '~core/client/assets/types';

import bin from './medic.bin';
import gltf from './medic.gltf';

Assets.addModel(ModelType.DroidMedic, {
  gltf,
  bin: {
    origin: 'medic.bin',
    path: bin,
  },
});
