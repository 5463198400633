import { LoadingManager } from 'three';
import type { BufferGeometry, Object3D } from 'three';

import type { AudioSourceConfig, AudioType } from '~core/client/audio/types';
import type { RenderItemMaterial } from '~core/client/render-item/types';

import { AssetsAudios } from './audios';
import { AssetsMaterials } from './materials';
import type { MaterialType } from './materials/types';
import { AssetsModels } from './models';
import type { LoaderModel } from './models/loader/types';
import type { ModelType, ModelBinaryData } from './types';

export class Assets {
  public static async load(onProgress: (progress: number) => void) {
    const manager = new LoadingManager();
    manager.onProgress = (url, loaded, total) => {
      onProgress(loaded / total);
    };

    await Promise.all([
      ...AssetsModels.load(manager),
      ...AssetsAudios.load(manager),
    ]);
  }

  /**
   * Models
   */

  public static addModel(type: ModelType, config: ModelBinaryData) {
    return AssetsModels.add(type, config);
  }

  public static getModel(type: ModelType): LoaderModel {
    return AssetsModels.get(type);
  }

  public static getClonedModel(type: ModelType): Object3D {
    return AssetsModels.clone(type);
  }

  public static getModelGeometry(type: ModelType): Nullable<BufferGeometry> {
    return AssetsModels.getGeometry(type);
  }

  /**
   * Materials
   */

  public static addMaterial(type: MaterialType, material: RenderItemMaterial) {
    return AssetsMaterials.add(type, material);
  }

  public static getMaterial<T extends RenderItemMaterial>(type: MaterialType): T {
    return AssetsMaterials.get(type);
  }

  public static createTexture(path: string) {
    return AssetsMaterials.createTexture(path);
  }

  /**
   * Audio
   */

  public static addAudio(type: AudioType, config: AudioSourceConfig) {
    return AssetsAudios.add(type, config);
  }

  public static getAudio() {
    return AssetsAudios.get();
  }
}
