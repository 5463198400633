import React from 'react';

import { AudioType } from '~core/client/audio/types';
import { useAudio } from '~core/client/audio/ui/hooks/use-audio';
import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { Text } from '~core/client/ui/components/text';

import { Boost } from '~feature/client/battle/entity/ui/components/boost';
import { Indicator } from '~feature/client/battle/entity/ui/components/indicator';
import { BuildingMessage } from '~feature/shared/battle/entity/building/types';
import { EntityMessage } from '~feature/shared/battle/entity/types';

import { useBuilding } from '../../hooks/use-building';

import IconHeal from './icons/heal.svg';

export const Indicators: React.FC = () => {
  const building = useBuilding();
  const audio = useAudio();
  const language = useLanguage();

  const onUpgrade = () => {
    if (building.selfOwn) {
      audio.play(AudioType.BuildingUpgrade);
    }
  };

  const onHeal = () => {
    if (building.selfOwn) {
      audio.play(AudioType.BuildingRepair);
    }
  };

  return (
    <>
      <Indicator
        entity={building}
        messageShow={BuildingMessage.Boost}
        messageHide={BuildingMessage.Unboost}
      >
        <Boost opponent={!building.selfOwn} />
      </Indicator>
      <Indicator
        entity={building}
        messageShow={BuildingMessage.Upgrade}
        onDisplay={onUpgrade}
      >
        <Text size='xs' bold uppercase>
          {language('Upgraded')}
        </Text>
      </Indicator>
      <Indicator
        entity={building}
        messageShow={EntityMessage.Heal}
        onDisplay={onHeal}
      >
        <IconHeal />
      </Indicator>
    </>
  );
};
