import { Easing, Tween } from '@tweenjs/tween.js';

import { MaterialType } from '~core/client/assets/materials/types';
import { Circle } from '~core/client/render-item/shape/circle';

import type { AttackAreaParams, AttackAreaTween } from '~feature/shared/battle/entity/unit/attack-area/types';

import type { Unit } from '..';

import { ATTACK_AREA_MATERIAL } from './const';

import './resources';

export class AttackArea extends Circle {
  private tween: Nullable<AttackAreaTween> = null;

  constructor(owner: Unit) {
    super(owner.battle.scene, {
      material: MaterialType.AttackAreaDefault,
      radius: 0.0,
      visible: false,
    });

    owner.renderItem.object.add(this.object);
  }

  override update(): void {
    super.update();

    this.tween?.update();
  }

  public display({ radius, duration, type }: AttackAreaParams) {
    if (this.tween) {
      this.hide();
    }

    const material = ATTACK_AREA_MATERIAL[type];
    this.setMaterial(material, true);
    this.setVisible(true);

    this.tween = new Tween({ radius: 0 }, false)
      .to({ radius }, duration)
      .easing(Easing.Quadratic.In)
      .onUpdate(({ radius }) => {
        this.setRadius(radius);
      })
      .onComplete(() => {
        this.hide();
      })
      .start();
  }

  public hide() {
    if (!this.tween) {
      return;
    }

    this.tween.stop();
    this.tween = null;

    this.setVisible(false);
    this.setRadius(0);
  }
}
