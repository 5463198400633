import type { Event } from 'make-event';
import { useEffect } from 'react';

export function useEvent<T>(
  event: Event<T>,
  callback: (payload: T) => void,
  deps: any[],
) {
  useEffect(() => {
    const unsubscribe = event(callback);
    return () => {
      unsubscribe();
    };
  }, deps);
}
