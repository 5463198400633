import { Assets } from '~core/client/assets';
import { ModelType } from '~core/client/assets/types';

import bin from './spawner-large.bin';
import gltf from './spawner-large.gltf';

Assets.addModel(ModelType.BuildingSpawnerLarge, {
  gltf,
  bin: {
    origin: 'spawner-large.bin',
    path: bin,
  },
});
