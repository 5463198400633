import React from 'react';

import { Icon } from '~core/client/ui/components/icon';
import { Interactive } from '~core/client/ui/components/interactive';

import styles from './styles.module.scss';

type Props = {
  icon: SVGIcon;
  onClick?: () => void | boolean;
  link?: string;
};

export const ControlItem: React.FC<Props> = ({ icon, link, onClick }) => (
  <Interactive onClick={onClick} link={link} className={styles.button}>
    <Icon type={icon} className={styles.icon} />
  </Interactive>
);
