import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { ButtonBack } from '~core/client/ui/components/button/back';
import { Icon } from '~core/client/ui/components/icon';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';

import type { Battle } from '~feature/client/battle';
import { useOpponentPlayerSchema } from '~feature/client/battle/ui/hooks/use-opponent-player-schema';

import { OpponentWaitingState } from '../state';

import styles from './styles.module.scss';

import IconWait from './icons/wait.svg';

export const Waiting: React.FC = () => {
  const battle = useRoom<Battle>();
  const language = useLanguage();

  const opponentPlayer = useOpponentPlayerSchema();
  const opponentConnected = useSchemaValue(opponentPlayer, 'connected');

  const [, serOpponentWaiting] = useRecoilState(OpponentWaitingState);

  const handleClickBack = () => {
    serOpponentWaiting(false);
  };

  useEffect(() => {
    battle.requestRestart();
    return () => {
      battle.declineRestart();
    };
  }, []);

  return (
    <Section direction='vertical' align='center' gap={64}>
      {opponentConnected ? (
        <Section direction='vertical' align='center' gap={32}>
          <Icon type={IconWait} className={styles.icon} />
          <Text size='2xl' bold uppercase>
            {language('WaitingOpponent')}
          </Text>
        </Section>
      ) : (
        <Text size='2xl' bold uppercase>
          {language('OpponentDisconnected')}
        </Text>
      )}
      <ButtonBack onClick={handleClickBack} />
    </Section>
  );
};
