import type { NPCSchema } from '~feature/shared/battle/entity/unit/npc/types';
import type { UnitMessagePayload } from '~feature/shared/battle/entity/unit/types';

export interface DroidSchema extends NPCSchema {
  readonly variant: DroidVariant
}

export enum DroidVariant {
  Combat = 'Combat',
  Mechanic = 'Mechanic',
  Medic = 'Medic',
}

export type DroidSharedConfig = {
  cost: number;
};

/**
 * Messages
 */

export enum DroidMessage {
  Boost = 'Droid:Boost',
  Unboost = 'Droid:Unboost',
}

export type DroidMessagePayload = UnitMessagePayload & {
  [DroidMessage.Boost]: void;
  [DroidMessage.Unboost]: void;
};
