import { Assets } from '~core/client/assets';
import { AudioType, AudioMode } from '~core/client/audio/types';

import audioBuild from './build.mp3';

Assets.addAudio(AudioType.Build, {
  path: audioBuild,
  mode: AudioMode.Track2D,
  volume: 1.0,
});
