import React, { useCallback, useRef, useState } from 'react';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRequest } from '~core/client/room/ui/hooks/use-request';
import { ErrorPlate } from '~core/client/ui/components/error-plate';
import { useError } from '~core/client/ui/components/error-plate/hooks/use-error';
import { Form } from '~core/client/ui/components/form';
import type { FormData } from '~core/client/ui/components/form/types';
import { Icon } from '~core/client/ui/components/icon';
import { Input } from '~core/client/ui/components/input';
import { Interactive } from '~core/client/ui/components/interactive';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';

import { useUserData } from '~feature/client/relay/ui/hooks/use-user-data';
import { UserRequest } from '~feature/shared/mixed/user/types';

import type { FormChangeNameFields } from './types';

import styles from './styles.module.scss';

import IconCancel from './icons/cancel.svg';
import IconEdit from './icons/edit.svg';
import IconSave from './icons/save.svg';

export const Name: React.FC = () => {
  const error = useError();
  const language = useLanguage();

  const setUsername = useRequest(UserRequest.SetUsername);

  const name = useUserData('name');

  const [edit, setEdit] = useState(false);

  const refForm = useRef<FormData>(null);

  const closeEdit = () => {
    setEdit(false);
    error.remove();
  };

  const handleClickEdit = () => {
    setEdit(true);
  };

  const handleClickCancel = () => {
    closeEdit();
  };

  const handleSubmit = useCallback((values: FormChangeNameFields) => {
    if (values.name === name) {
      closeEdit();
      return;
    }

    setUsername.fetch({
      name: values.name,
    }).then(() => {
      closeEdit();
    }).catch(({ message }) => {
      error.show(language(message));
    });
  }, [name]);

  return edit ? (
    <Form ref={refForm} onSubmit={handleSubmit}>
      <Section direction="vertical" gap={16}>
        <ErrorPlate error={error} size='s' />
        <Section direction="horizontal" gap={16}>
          <Input
            name="name"
            label={'Name'}
            defaultValue={name}
            className={styles.input}
            validate={(value) => /^[a-zA-Z0-9_-]{0,16}$/.test(value)}
            maxLength={16}
          />
          <Section direction="horizontal" gap={8}>
            <Interactive tag="button" className={styles.button}>
              <Icon type={IconSave} size={20} />
            </Interactive>
            <Interactive onClick={handleClickCancel} className={styles.button}>
              <Icon type={IconCancel} size={20} />
            </Interactive>
          </Section>
        </Section>
      </Section>
    </Form>
  ) : (
    <Section direction="horizontal" gap={16}>
      <Text size="3xl" bold className={styles.text}>
        {name}
      </Text>
      <Interactive onClick={handleClickEdit} className={styles.button}>
        <Icon type={IconEdit} size={20} />
      </Interactive>
    </Section>
  );
};
