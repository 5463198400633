import { Progression } from '~core/shared/progression';

import { BuildingVariant } from '../entity/building/types';
import { UPGRADE_MAX_LEVEL } from '../entity/unit/player/upgrades/const';
import { TutorialStep } from '../tutorial/types';
import { BattleMode } from '../types';

export const BUILDER_RADIUS = new Progression({
  defaultValue: 3.0,
  maxValue: 5.0,
  maxLevel: UPGRADE_MAX_LEVEL,
});

export const BUILDER_RESTRICTION_LEVEL_GRADE: Record<BattleMode, number> = {
  [BattleMode.Online]: 3,
  [BattleMode.Single]: 4,
};

export const BUILDER_TUTORIAL_TARGET: Partial<Record<TutorialStep, BuildingVariant>> = {
  [TutorialStep.BuildAmmunition]: BuildingVariant.Ammunition,
  [TutorialStep.BuildSecondAmmunition]: BuildingVariant.Ammunition,
  [TutorialStep.BuildGenerator]: BuildingVariant.Generator,
  [TutorialStep.BuildSecondGenerator]: BuildingVariant.Generator,
  [TutorialStep.BuildTowerFire]: BuildingVariant.TowerFire,
  [TutorialStep.BuildTowerFrost]: BuildingVariant.TowerFrost,
  [TutorialStep.BuildRadar]: BuildingVariant.Radar,
};

export const BUILDER_FLOW: Record<BattleMode, BuildingVariant[]> = {
  [BattleMode.Single]: [
    BuildingVariant.Generator,
    BuildingVariant.TowerFire,
  ],
  [BattleMode.Online]: [
    BuildingVariant.Generator,
    BuildingVariant.TowerFire,
    BuildingVariant.SpawnerSmall,
  ],
};
