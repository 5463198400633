import React from 'react';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { Crystals } from '~core/client/ui/components/amount/crystals';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';

import { useUserData } from '~feature/client/relay/ui/hooks/use-user-data';

import styles from './styles.module.scss';

export const CrystalsCurrent: React.FC = () => {
  const language = useLanguage();

  const crystals = useUserData('crystals');

  return (
    <Section direction='horizontal' align='center' gap={12}>
      <Text size='m' uppercase className={styles.text}>
        {language('YouHave')}
      </Text>
      <Crystals value={crystals} size='m' />
      <Text size='m' uppercase className={styles.text}>
        {language('Crystals')}
      </Text>
    </Section>
  );
};
