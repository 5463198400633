import { Mesh } from 'three';

import { Assets } from '~core/client/assets';
import type { Scene } from '~core/client/scene';
import { SceneLayer } from '~core/client/scene/types';

import { RenderItem } from '..';

import type { ShapeConfig } from './types';

export abstract class Shape extends RenderItem {
  declare public object: Mesh;

  public readonly scene: Scene;

  constructor(scene: Scene, {
    geometry,
    material,
    ...config
  }: ShapeConfig) {
    const object = new Mesh(geometry, Assets.getMaterial(material));
    super(scene, { ...config, object });

    this.object.layers.set(SceneLayer.Misc);
  }
}
