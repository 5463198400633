import { Assets } from '~core/client/assets';
import { ModelType } from '~core/client/assets/types';

import bin from './trap.bin';
import gltf from './trap.gltf';

Assets.addModel(ModelType.BuildingTrap, {
  gltf,
  bin: {
    origin: 'trap.bin',
    path: bin,
  },
});
