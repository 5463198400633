import { Assets } from '~core/client/assets';
import { AudioMode, AudioType } from '~core/client/audio/types';

import audioLaser from './laser.mp3';

Assets.addAudio(AudioType.ShotLaser, {
  path: audioLaser,
  mode: AudioMode.Track3D,
  volume: 0.3,
  limit: 4,
});
