import { Assets } from '~core/client/assets';
import { AudioType, AudioMode } from '~core/client/audio/types';

import audioClick from './click.mp3';
import audioError from './error.mp3';

Assets.addAudio(AudioType.Error, {
  path: audioError,
  mode: AudioMode.Track2D,
  volume: 0.4,
});

Assets.addAudio(AudioType.Click, {
  path: audioClick,
  mode: AudioMode.Track2D,
  volume: 0.5,
});
