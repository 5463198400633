import cn from 'classnames';
import React, { useCallback } from 'react';

import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { Interactive } from '~core/client/ui/components/interactive';
import { useEventValue } from '~core/client/ui/hooks/use-event-value';

import type { Battle } from '~feature/client/battle';
import { Translator } from '~feature/client/battle/ui/components/translator';

import styles from './styles.module.scss';

import IconConfirm from './icons/confirm.svg';
import IconDecline from './icons/decline.svg';

export const BuildControls: React.FC = () => {
  const { builder } = useRoom<Battle>();

  const variant = useEventValue(builder.onChangeVariant, builder.variant);
  const validState = useEventValue(builder.onChangeValidState, builder.validState);

  const preview = variant && builder.previewBuilding;

  const handleClickConfirm = useCallback(() => {
    builder.build();
  }, []);

  const handleClickDecline = useCallback(() => {
    builder.setVariant(null);
  }, []);

  return preview && (
    <Translator renderItem={preview} body={
      <div className={styles.wrapper}>
        <Interactive onClick={handleClickConfirm} className={cn(styles.button, styles.confirm, {
          [styles.disabled]: !validState,
        })}>
          <IconConfirm />
        </Interactive>
        <Interactive onClick={handleClickDecline} className={cn(styles.button, styles.decline)}>
          <IconDecline />
        </Interactive>
      </div>
    } />
  );
};
