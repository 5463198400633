import React, { useEffect } from 'react';

import { useEvent } from '~core/client/ui/hooks/use-event';
import { useStateMap } from '~core/client/ui/hooks/use-state-map';
import { Utils } from '~core/shared/utils';

import { Resources } from '~feature/client/battle/ui/components/amounts/resources';
import { useSelfPlayer } from '~feature/client/battle/ui/hooks/use-self-player';

import { TranslatorManual } from '../translator/manual';

import type { CrystalPickupData } from './types';

import styles from './styles.module.scss';

export const CrystalNotice: React.FC = () => {
  const player = useSelfPlayer();

  const notices = useStateMap<string, CrystalPickupData>();

  useEvent(player.onCrystalPickup, (payload) => {
    const uuid = Utils.uuid();
    notices.set(uuid, {
      ...payload,
      timeout: setTimeout(() => {
        notices.delete(uuid);
      }, 1500),
    });
  }, []);

  useEffect(() => {
    return () => {
      notices.forEach((key, notice) => {
        clearTimeout(notice.timeout);
      });
    };
  }, []);

  return notices.map((key, notice) => (
    <TranslatorManual key={key} position={notice.position}>
      <div className={styles.notice}>
        <Resources value={notice.resources} size='s' />
      </div>
    </TranslatorManual>
  ));
};
