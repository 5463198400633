import { MaterialType } from '~core/client/assets/materials/types';
import { ModelType } from '~core/client/assets/types';

import type { Battle } from '~feature/client/battle';
import type { MobStingraySchema } from '~feature/shared/battle/entity/unit/npc/mob/variants/stingray/type';

import { Mob } from '../..';

import IconUnvisible from './icons/unvisible.svg';

import './resources';

export class MobStingray extends Mob {
  declare public schema: MobStingraySchema;

  constructor(battle: Battle, schema: MobStingraySchema) {
    super(battle, {
      model: ModelType.MobStingray,
    }, schema);
  }

  override onSceneUpdate(): void {
    super.onSceneUpdate();

    if (!this.selfOwn) {
      this.updateUnvisibleState();
    }
  }

  private isUnvisible() {
    return !this.selfOwn && this.schema.unvisible;
  }

  private updateUnvisibleState() {
    if (this.isUnvisible()) {
      this.setBaseMaterial(MaterialType.UnitTransparent);
      this.setStateIcon(IconUnvisible);
    } else {
      this.setBaseMaterial(MaterialType.Unit);
      this.removeStateIcon();
    }
  }
}

