// @ts-ignore
import { MeshLineMaterial } from 'three.meshline';

import { Assets } from '~core/client/assets';
import { MaterialType } from '~core/client/assets/materials/types';

Assets.addMaterial(MaterialType.LaserLine, new MeshLineMaterial({
  color: 0xc068d8,
  lineWidth: 0.075,
}));
