import { ModelType } from '~core/client/assets/types';

import type { Battle } from '~feature/client/battle';
import type { BuildingTowerLaserSchema } from '~feature/shared/battle/entity/building/variants/tower/laser/types';

import { BuildingTower } from '..';

import './resources';

export class BuildingTowerLaser extends BuildingTower {
  constructor(battle: Battle, schema: BuildingTowerLaserSchema) {
    super(battle, {
      model: ModelType.BuildingTowerLaser,
    }, schema);
  }
}
