import { MaterialType } from '~core/client/assets/materials/types';
import { Plane } from '~core/client/render-item/shape/plane';

import type { Unit } from '..';

import './resources';

export class Indicator extends Plane {
  constructor(unit: Unit) {
    const size = unit.schema.size.z;

    super(unit.battle.scene, {
      position: { x: 0.0, y: 0.01, z: 0.0 },
      scale: { x: size, y: size, z: size },
      material: unit.selfOwn
        ? MaterialType.IndicatorSelf
        : MaterialType.IndicatorOpponent,
    });

    unit.renderItem.object.add(this.object);
  }
}
