import { Assets } from '~core/client/assets';
import { ModelType } from '~core/client/assets/types';

import bin from './siren.bin';
import gltf from './siren.gltf';

Assets.addModel(ModelType.MobSiren, {
  gltf,
  bin: {
    origin: 'siren.bin',
    path: bin,
  },
});
