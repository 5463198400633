import type { Vector3Like } from 'three';

import type { BuildingVariant } from '../entity/building/types';

export type BuilderPayloadMessageBuild = {
  variant: BuildingVariant;
  position: Vector3Like;
};

/**
 * Messages
 */

export enum BuilderMessage {
  Build = 'Builder:Build',
}

export type BuilderMessagePayload = {
  [BuilderMessage.Build]: {
    variant: BuildingVariant;
    position: Vector3Like;
  };
};
