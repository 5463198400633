import { Color, MeshLambertMaterial, ShaderMaterial } from 'three';

import { Assets } from '~core/client/assets';
import { MaterialType } from '~core/client/assets/materials/types';

import { SHADER_TINT_FRAGMENT } from './shader/fragment';
import { SHADER_TINT_VERTEX } from './shader/vertex';

import textureUnit from './unit.png';

const texture = Assets.createTexture(textureUnit);

Assets.addMaterial(MaterialType.Unit, new MeshLambertMaterial({
  map: texture,
  reflectivity: 0,
}));

Assets.addMaterial(MaterialType.UnitTransparent, new MeshLambertMaterial({
  map: texture,
  reflectivity: 0,
  opacity: 0.5,
  transparent: true,
}));

Assets.addMaterial(MaterialType.UnitFrost, new ShaderMaterial({
  vertexShader: SHADER_TINT_VERTEX,
  fragmentShader: SHADER_TINT_FRAGMENT,
  transparent: true,
  uniforms: {
    targetTexture: { value: texture },
    tintColor: { value: new Color(0x5beeff) },
    intensity: { value: 0.75 },
  },
}));
