import type { BuildingSchema } from '~feature/shared/battle/entity/building/types';

import type { Battle } from '../../..';

import { BUILDING_INSTANCES } from './const';

export class BuildingFactory {
  public static create(battle: Battle, schema: BuildingSchema) {
    const BuildingInstance = BUILDING_INSTANCES[schema.variant];

    return new BuildingInstance(battle, schema);
  }
}
