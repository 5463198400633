import { Assets } from '~core/client/assets';
import { ModelType } from '~core/client/assets/types';

import bin from './wall.bin';
import gltf from './wall.gltf';

Assets.addModel(ModelType.BuildingWall, {
  gltf,
  bin: {
    origin: 'wall.bin',
    path: bin,
  },
});
