import { Assets } from '~core/client/assets';
import { AudioMode, AudioType } from '~core/client/audio/types';

import audioRoar from './roar.mp3';

Assets.addAudio(AudioType.MobBossRoar, {
  path: audioRoar,
  mode: AudioMode.Track3D,
  volume: 0.7,
  limit: 1,
});
