import React, { useCallback } from 'react';

import { Client } from '~core/client/client';
import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRequest } from '~core/client/room/ui/hooks/use-request';
import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { Button } from '~core/client/ui/components/button';
import { Checkbox } from '~core/client/ui/components/checkbox';
import { ErrorPlate } from '~core/client/ui/components/error-plate';
import { useError } from '~core/client/ui/components/error-plate/hooks/use-error';
import { Form } from '~core/client/ui/components/form';
import { Section } from '~core/client/ui/components/section';

import { UserAuthRequest } from '~feature/shared/mixed/user/auth/types';

import { GoogleButton } from '../google-button';
import { InputPassword } from '../inputs/password';
import { InputUsername } from '../inputs/username';

import type { FormSignUpFields } from './types';

export const AuthSingUp: React.FC = () => {
  const room = useRoom();
  const error = useError();
  const language = useLanguage();

  const register = useRequest<string>(UserAuthRequest.Register);

  const handleSubmit = useCallback((values: FormSignUpFields) => {
    register.fetch({
      username: values.username,
      password: values.password,
    }).then((token) => {
      Client.setAuthToken(token);
      room.refreshUI();
    }).catch(({ message }) => {
      error.show(language(message));
    });
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Section direction="vertical" gap={48}>
        <Section direction="vertical" gap={24}>
          <Section direction="vertical" gap={8}>
            <ErrorPlate error={error} />
            <InputUsername />
            <InputPassword />
          </Section>
          <Checkbox name='acceptPolicy'>
            {language('AcceptTerms')}
            <br />
            <a
              href="https://izowave.io/policy"
              target="_blank"
            >
              {language('PrivacyPolicy')}
            </a>
          </Checkbox>
        </Section>
        <Section direction="horizontal" gap={16}>
          <Button
            view='accent'
            size='s'
            loading={register.loading}
          >
            {language('ToSignUp')}
          </Button>
          <GoogleButton />
        </Section>
      </Section>
    </Form>
  );
};
