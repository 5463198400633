import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';

import { Experience } from '~feature/client/battle/ui/components/amounts/experience';
import { useSelfPlayerSchema } from '~feature/client/battle/ui/hooks/use-self-player-schema';

import styles from './styles.module.scss';

export const LevelPanel: React.FC = () => {
  const language = useLanguage();

  const player = useSelfPlayerSchema();

  const level = useSchemaValue(player, 'level');
  const experienceProgress = useSchemaValue(player, 'experienceProgress');
  const experience = useSchemaValue(player, 'experience');

  return (
    <div className={styles.container}>
      <div
        className={styles.level}
        style={{
          '--progress': `${experienceProgress}%`,
        }}
      >
        <Text size='2xl' bold mono>{level}</Text>
      </div>
      <Section direction='vertical' gap={4}>
        <Text size='xs' uppercase>{language('Experience')}</Text>
        <Experience value={experience} size='s' />
      </Section>
    </div>
  );
};

declare module 'react' {
  interface CSSProperties {
    [key: `--${string}`]: string | number
  }
}
