import type { Schema } from '@colyseus/schema';

import type { DeviceType } from '~core/shared/device/types';

import type { InventorySchema } from '~feature/shared/mixed/inventory/types';

export interface UserSchema extends Schema {
  readonly name: string;
  readonly authorized: boolean;
  readonly score: number;
  readonly crystals: number;
  readonly inventory: InventorySchema;
  readonly skillSlots: number;
  readonly adsAvailable: boolean;
}

export type UserConfig = {
  device: DeviceType;
  token?: Nullable<string>;
  newbie?: boolean;
};

export type UserStatistic = {
  maxWave: number;
  totalWaves: number;
  place: number;
  totalUsers: number;
};

export enum UserRequest {
  SetUsername = 'User:SetUsername',
  ApplyAdsReward = 'User:ApplyAdsReward',
  GetStatistic = 'User:GetStatistic',
}

export enum UserError {
  InvalidCredentials = 'InvalidCredentials',
  InvalidToken = 'InvalidToken',
  InvalidUsername = 'InvalidUsername',
  InvalidPassword = 'InvalidPassword',
  ExistUsername = 'ExistUsername',
}
