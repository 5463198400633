import { MathUtils } from 'three';

import { MeshSetChild } from '~core/client/render-item/mesh-set/child';

import type { DecorationSchema } from '~feature/shared/battle/terrain/decoration/types';

import { DECORATION_PRESETS } from './const';

import './resources';

export class Decoration extends MeshSetChild {
  constructor(schema: DecorationSchema) {
    const scales = DECORATION_PRESETS[schema.type].scale;

    super({
      position: schema.position,
      scale: MathUtils.randFloat(...scales),
      rotation: 2 * Math.PI * Math.random(),
    });

    schema.onRemove(() => {
      this.hide();
    });
  }

  public static getModel(schema: DecorationSchema) {
    const model = DECORATION_PRESETS[schema.type].models[schema.index];
    if (!model) {
      throw Error('Invalid decoration model index');
    }

    return model;
  }
}
