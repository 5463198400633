import type { AudioListener } from 'three';

import type { AudioType } from '../types';

export abstract class AudioTrack {
  protected readonly type: AudioType;

  protected readonly listener: AudioListener;

  constructor(type: AudioType, listener: AudioListener) {
    this.type = type;
    this.listener = listener;
  }
}
