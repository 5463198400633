import React, { useRef, useState } from 'react';

import { Icon } from '~core/client/ui/components/icon';
import { useEvent } from '~core/client/ui/hooks/use-event';

import { useMob } from '../../../hooks/use-mob';

export const StateIcon: React.FC = () => {
  const mob = useMob();

  const [visible, setVisible] = useState(Boolean(mob.stateIcon));

  const refIcon = useRef(mob.stateIcon);

  useEvent(mob.onChangeStateIcon, (icon: Nullable<SVGIcon>) => {
    refIcon.current = icon;
    setVisible(Boolean(icon));
  }, []);

  return visible && (
    <Icon type={refIcon.current} />
  );
};
