import { Vector3 } from 'three';
import type { Vector3Like } from 'three';

import { ENTITY_SPEED_MULTIPLIER } from '../const';

export class EntityUtils {
  public static getNextPosition(position: Vector3Like, velocity: Vector3Like, speed: number, delta: number) {
    return new Vector3()
      .copy(velocity)
      .normalize()
      .multiplyScalar(
        delta
        * speed
        * ENTITY_SPEED_MULTIPLIER,
      )
      .add(position);
  }
}
