import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { AudioType } from '~core/client/audio/types';
import { useAudio } from '~core/client/audio/ui/hooks/use-audio';
import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { Text } from '~core/client/ui/components/text';

import { Indicator } from '~feature/client/battle/entity/ui/components/indicator';
import { EntityMessage } from '~feature/shared/battle/entity/types';
import { PlayerMessage } from '~feature/shared/battle/entity/unit/player/types';

import { usePlayer } from '../../hooks/use-player';

import styles from './styles.module.scss';

import IconHeal from './icons/heal.svg';
import IconNoConnect from './icons/no-connect.svg';

export const Indicators: React.FC = () => {
  const player = usePlayer();
  const audio = useAudio();
  const language = useLanguage();

  const connected = useSchemaValue(player.schema, 'connected');

  const onLevelUp = () => {
    if (player.selfOwn) {
      audio.play(AudioType.PlayerLevelUp);
    }
  };

  const onHeal = () => {
    if (player.selfOwn) {
      // audio.play(AudioType.Repair);
    }
  };

  return (
    <>
      {!connected && (
        <IconNoConnect className={styles.noConnect} />
      )}
      <Indicator
        entity={player}
        messageShow={PlayerMessage.LevelUp}
        onDisplay={onLevelUp}
      >
        <Text size='xs' bold uppercase>
          {language('LevelUp')}
        </Text>
      </Indicator>
      <Indicator
        entity={player}
        messageShow={EntityMessage.Heal}
        onDisplay={onHeal}
      >
        <IconHeal />
      </Indicator>
    </>
  );
};
