import { Color, MeshLambertMaterial } from 'three';

import { Assets } from '~core/client/assets';
import { MaterialType } from '~core/client/assets/materials/types';

Assets.addMaterial(MaterialType.Self, new MeshLambertMaterial({
  color: new Color(0x43a3db),
  reflectivity: 0,
}));

Assets.addMaterial(MaterialType.Opponent, new MeshLambertMaterial({
  color: new Color(0xff9b49),
  reflectivity: 0,
}));
