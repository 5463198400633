import React from 'react';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { Input } from '~core/client/ui/components/input';

import IconUsername from './icons/username.svg';

export const InputUsername: React.FC = () => {
  const language = useLanguage();

  return (
    <Input
      name='username'
      label={language('Username')}
      icon={IconUsername}
      validate={(value) => /^[a-zA-Z0-9_-]{0,16}$/.test(value)}
      maxLength={16}
    />
  );
};
