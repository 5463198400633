import React from 'react';

import { InputKeyboard } from '~core/client/input/keyboard';
import { InputTouch } from '~core/client/input/touch';
import type { InputTouchSwipeEvent } from '~core/client/input/touch/types';
import { InputTouchSwipeDirection } from '~core/client/input/touch/types';
import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { useEvent } from '~core/client/ui/hooks/use-event';

import type { Battle } from '~feature/client/battle';
import { ScreenGrid } from '~feature/client/battle/ui/components/stages/started/screen-grid';
import { useScreen } from '~feature/client/battle/ui/hooks/use-screen';
import { ScreenType } from '~feature/client/battle/ui/types';
import { UpgradeCategory } from '~feature/shared/battle/entity/unit/player/upgrades/types';

import { Category } from './category';
import { UPGRADES_TOGGLE_KEY } from './const';

export const UpgradesScreen: React.FC = () => {
  const { builder } = useRoom<Battle>();
  const { visible, toggleScreen } = useScreen(ScreenType.Upgrades);

  const categories = Object.values(UpgradeCategory);

  useEvent(InputTouch.onTouchSwipe, (event: InputTouchSwipeEvent) => {
    if (builder.isBuild()) {
      return;
    }

    if (event.direction === InputTouchSwipeDirection.Up) {
      toggleScreen(true);
    } else if (event.direction === InputTouchSwipeDirection.Down) {
      toggleScreen(false);
    }
  }, [toggleScreen]);

  useEvent(InputKeyboard.onKeyUp, (event: KeyboardEvent) => {
    if (event.key === UPGRADES_TOGGLE_KEY) {
      event.preventDefault();
      toggleScreen(false);
    }
  }, [toggleScreen]);

  useEvent(InputKeyboard.onKeyDown, (event: KeyboardEvent) => {
    if (event.key === UPGRADES_TOGGLE_KEY) {
      event.preventDefault();
      toggleScreen(true);
    }
  }, [toggleScreen]);

  return visible && (
    <ScreenGrid>
      {categories.map((category) => (
        <Category
          key={category}
          category={category}
        />
      ))}
    </ScreenGrid>
  );
};
