// extracted by mini-css-extract-plugin
var _1 = "u4sQw3dfdabHlr1zzqHy";
var _2 = "ZtJBNdrJ3taIyOGjdgOU";
var _3 = "ua7DbLfLLUhDz1zUEg9G";
var _4 = "HY_sLndDmlNBKrhfftrH";
var _5 = "koJRzq_jSo5VDOBdVwT5";
var _6 = "zTREHSTB4ZG1ekX4FF9B";
var _7 = "hYFJ954GpOKR1qw4jQb0";
var _8 = "PLC3Y6bISwZkYIZzyN23";
var _9 = "uZfEeITTrN8TEqaZQglb";
export { _1 as "amount", _2 as "icon", _3 as "l", _4 as "m", _5 as "negative", _6 as "postfix", _7 as "s", _8 as "wrapper", _9 as "xs" }
export default { "amount": _1, "icon": _2, "l": _3, "m": _4, "negative": _5, "postfix": _6, "s": _7, "wrapper": _8, "xs": _9 }
