import type React from 'react';

import { PageAuth } from './auth';
import { PageInventory } from './inventory';
import { PageLeaderboard } from './leaderboard';
import { PagePlay } from './play';
import { PageProfile } from './profile';
import { PageSettings } from './settings';
import { PageType } from './types';

export const PAGES: Record<PageType, React.FC> = {
  [PageType.Play]: PagePlay,
  [PageType.Inventory]: PageInventory,
  [PageType.Settings]: PageSettings,
  [PageType.Leaderboard]: PageLeaderboard,
  [PageType.Auth]: PageAuth,
  [PageType.Profile]: PageProfile,
};

