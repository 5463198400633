import { Assets } from '~core/client/assets';
import { ModelType } from '~core/client/assets/types';

import bin from './golem.bin';
import gltf from './golem.gltf';

Assets.addModel(ModelType.MobGolem, {
  gltf,
  bin: {
    origin: 'golem.bin',
    path: bin,
  },
});
