import React from 'react';

import { Client } from '~core/client/client';
import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRequest } from '~core/client/room/ui/hooks/use-request';
import { Section } from '~core/client/ui/components/section';

import { BattleDifficult } from '~feature/shared/battle/types';
import { RelayRequest } from '~feature/shared/relay/types';

import { Kind } from '../kind';

import { DIFFICULT_ICON } from './const';

export const SelectDifficult: React.FC = () => {
  const language = useLanguage();

  const playSingle = useRequest(RelayRequest.PlaySingle);

  const modes = Object.keys(BattleDifficult) as BattleDifficult[];
  const tutorialCompleted = Client.isTutorialCompleted();

  const handleClick = (difficult: BattleDifficult) => {
    if (playSingle.loading) {
      return;
    }

    playSingle.fetch({ difficult });
  };

  return (
    <Section direction="vertical" gap={10}>
      {modes.map((difficult) => (
        <Kind
          key={difficult}
          label={language(`Difficult${difficult}`)}
          description={language(`Difficult${difficult}Description`)}
          icon={DIFFICULT_ICON[difficult]}
          onClick={() => handleClick(difficult)}
          accent={difficult === BattleDifficult.Tutorial && !tutorialCompleted}
        />
      ))}
    </Section>
  );
};
