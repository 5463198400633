import React from 'react';

import { EntityPanel } from '~feature/client/battle/entity/ui/components/entity-panel';
import { HealthBar } from '~feature/client/battle/entity/ui/components/health-bar';

import type { Mob } from '..';

import { ArmourBar } from './components/armour-bar';
import { Indicators } from './components/indicators';
import { MobContext } from './context';

type Props = {
  target: Mob;
};

export const MobUI: React.FC<Props> = ({ target }) => (
  <MobContext.Provider value={target}>
    <EntityPanel
      renderItem={target.renderItem}
      indicators={
        <Indicators />
      }
      bars={
        <>
          <ArmourBar />
          <HealthBar unit={target} />
        </>
      }
    />
  </MobContext.Provider>
);
