import React from 'react';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { Amount } from '~core/client/ui/components/amount';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';

import { PARAMETER_ICON } from './const';
import type { ParameterType } from './types';

import styles from './styles.module.scss';

type Props = {
  value: number;
  type: ParameterType;
};

export const Parameter: React.FC<Props> = ({ type, value }) => {
  const language = useLanguage();

  return (
    <Section direction="vertical" gap={10} className={styles.wrapper}>
      <Text view="secondary" size="2xs" uppercase spacing>
        {language(`Parameter${type}`)}
      </Text>
      <Amount
        size="m"
        value={value}
        icon={PARAMETER_ICON[type]}
        color="#4ae49d"
      />
    </Section>
  );
};
