import { MaterialType } from '~core/client/assets/materials/types';
import { Logger } from '~core/client/logger';
import { Model } from '~core/client/render-item/model';
import { SceneLayer } from '~core/client/scene/types';

import type { BuildingVariant } from '~feature/shared/battle/entity/building/types';

import type { Battle } from '../..';
import { BUILDING_MODELS, BUILDING_MODEL_UPGRADE_PREFIX } from '../../entity/building/const';

export class BuildingPreview extends Model {
  constructor(battle: Battle, variant: BuildingVariant) {
    super(battle.scene, {
      model: BUILDING_MODELS[variant],
      material: MaterialType.Building,
    });

    this.eachMeshes((mesh) => {
      mesh.layers.set(SceneLayer.Misc);
    });

    const root = this.getRoot();
    if (!root) {
      Logger.warn(`Invalid root node of model for building '${variant}'`);
      return;
    }

    root.children.forEach((object) => {
      if (object.name.indexOf(BUILDING_MODEL_UPGRADE_PREFIX) === 0) {
        object.visible = false;
      }
    });
  }
}
