import { AudioType } from '~core/client/audio/types';
import { Messages } from '~core/client/messages';

import type { WaveMessagePayload, WaveSchema } from '~feature/shared/battle/wave/types';
import { WaveMessage } from '~feature/shared/battle/wave/types';

import type { Battle } from '..';

import './resources';

export class Wave {
  public readonly schema: WaveSchema;

  private readonly battle: Battle;

  private readonly messages: Messages<WaveMessagePayload>;

  constructor(battle: Battle) {
    this.battle = battle;
    this.schema = battle.state.wave;

    this.messages = new Messages(this.battle.origin, this.battle.messagesBuffer);

    this.messages.on(WaveMessage.Start, () => {
      this.battle.scene.audio.play2D(AudioType.WaveStart);
    });

    this.messages.on(WaveMessage.Complete, () => {
      this.battle.scene.audio.play2D(AudioType.WaveComplete);
    });
  }

  public skipTimeleft() {
    this.messages.send(WaveMessage.SkipTimeleft, void {});
  }
}
