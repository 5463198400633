import React from 'react';

import { EntityPanel } from '~feature/client/battle/entity/ui/components/entity-panel';

import type { Droid } from '..';

import { Indicators } from './components/indicators';
import { DroidContext } from './context';

type Props = {
  target: Droid;
};

export const DroidUI: React.FC<Props> = ({ target }) => (
  <DroidContext.Provider value={target}>
    <EntityPanel
      renderItem={target.renderItem}
      indicators={
        <Indicators />
      }
    />
  </DroidContext.Provider>
);
