import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import type { Unit } from '../../../unit';
import { DamageLabel } from '../damage-label';
import { ProgressBar } from '../progress-bar';

import styles from './styles.module.scss';

type Props = {
  unit: Unit;
};

export const HealthBar: React.FC<Props> = ({ unit }) => {
  if (!unit.schema.live) {
    return null;
  }

  const health = useSchemaValue(unit.schema.live, 'health');
  const maxHealth = useSchemaValue(unit.schema.live, 'maxHealth');

  return (
    <div className={styles.wrapper}>
      <ProgressBar
        value={health}
        maxValue={maxHealth}
        color={unit.selfOwn ? '#4ed83e' : '#ff5151'}
      />
      <DamageLabel live={unit.schema.live} />
    </div>
  );
};
