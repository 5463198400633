import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Section } from '~core/client/ui/components/section';

import { PageState } from '../pages/state';
import { PageType } from '../pages/types';

import { ControlItem } from './item';

import styles from './styles.module.scss';

import IconDiscord from './icons/discord.svg';
import IconSettings from './icons/settings.svg';

export const Controls: React.FC = () => {
  const [, setPage] = useRecoilState(PageState);

  const handleClickSettings = useCallback(() => {
    setPage(PageType.Settings);
  }, []);

  return (
    <Section direction='vertical' gap={16} className={styles.wrapper}>
      <ControlItem icon={IconDiscord} link="https://discord.gg/cnFAdMsRxn" />
      <ControlItem icon={IconSettings} onClick={handleClickSettings} />
    </Section>
  );
};
