import { useState } from 'react';

import { useRoom } from '~core/client/room/ui/hooks/use-room';

export function useRequest<T = any, P = any>(type: string) {
  const room = useRoom();

  const [loading, setLoading] = useState(false);

  const fetch = async (payload?: P) => {
    if (loading) {
      throw new Error(`Request ${type} is already pending`);
    }

    setLoading(true);

    return room.sendRequest<T>(type, payload)
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    fetch,
    loading,
  };
}
