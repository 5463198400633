import { useContext } from 'react';

import type { Room } from '../..';
import { RoomContext } from '../context';

export function useRoom<T extends Room<any>>() {
  const room = useContext(RoomContext);

  if (!room) {
    throw Error('Undefined room context');
  }

  return room as T;
}
