import { Color, MeshLambertMaterial } from 'three';

import { Assets } from '~core/client/assets';
import { MaterialType } from '~core/client/assets/materials/types';

Assets.addMaterial(MaterialType.AttackAreaDefault, new MeshLambertMaterial({
  color: new Color(0xffffff),
  reflectivity: 0,
  transparent: true,
  opacity: 0.2,
  depthTest: false,
  depthWrite: false,
}));

Assets.addMaterial(MaterialType.AttackAreaFrost, new MeshLambertMaterial({
  color: new Color(0x5beeff),
  reflectivity: 0,
  transparent: true,
  opacity: 0.2,
  depthTest: false,
  depthWrite: false,
}));
