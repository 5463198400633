import React from 'react';

import { useSelfPlayerSchema } from '~feature/client/battle/ui/hooks/use-self-player-schema';
import type { SkillVariant } from '~feature/shared/battle/entity/unit/player/skill/types';

import { SkillSlot } from './slot';

import styles from './styles.module.scss';

export const SkillPanel: React.FC = () => {
  const player = useSelfPlayerSchema();

  const list = Array.from(player.skills.entries()).sort((a, b) => (
    a[1].type.localeCompare(b[1].type)
  ));

  return (
    <div className={styles.wrapper}>
      {list.map(([variant, skill], index) => (
        <SkillSlot key={variant} variant={variant as SkillVariant} skill={skill} index={index} />
      ))}
    </div>
  );
};
