import { ModelType } from '~core/client/assets/types';

import type { Battle } from '~feature/client/battle';
import type { BuildingTowerFrostSchema } from '~feature/shared/battle/entity/building/variants/tower/frost/types';

import { BuildingTower } from '..';

import './resources';

export class BuildingTowerFrost extends BuildingTower {
  constructor(battle: Battle, schema: BuildingTowerFrostSchema) {
    super(battle, {
      model: ModelType.BuildingTowerFrost,
    }, schema);
  }
}
