import { useRecoilValue } from 'recoil';

import { DICTIONARIES } from '../../const';
import type { DictionaryPhrase } from '../../types';
import { LanguageState } from '../state';

export function useLanguage() {
  const language = useRecoilValue(LanguageState);

  return (key: DictionaryPhrase, format?: Array<string | number>) => {
    const dictionary = DICTIONARIES[language];
    let text = dictionary[key] ?? key;

    if (format) {
      text = text.replace(/%\d+/g, (match) => (
        format[Number(match.slice(1)) - 1]?.toString()
      ));
    }

    return text;
  };
}
