// extracted by mini-css-extract-plugin
var _1 = "ftQ3yR1RbINeRgqT1ayP";
var _2 = "cAGqzyVC1BjJ4igsEEMZ";
var _3 = "wwfCgUdqvyIXhAXZACXS";
var _4 = "bwzOI1TCD5Nn4FFNB6uz";
var _5 = "GF3PCO0zOzJkMLBfr6cX";
var _6 = "yp7UH8LaNfS6wBRxrV2a";
var _7 = "wdgUeilNMtiHrwDPciQO";
var _8 = "CJrpGR4W2VOsEWCKQRjW";
export { _1 as "gradient", _2 as "info", _3 as "place", _4 as "place1", _5 as "place2", _6 as "place3", _7 as "values", _8 as "wrapper" }
export default { "gradient": _1, "info": _2, "place": _3, "place1": _4, "place2": _5, "place3": _6, "values": _7, "wrapper": _8 }
