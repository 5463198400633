import { Assets } from '~core/client/assets';
import { ModelType } from '~core/client/assets/types';

import bin from './spider.bin';
import gltf from './spider.gltf';

Assets.addModel(ModelType.MobSpider, {
  gltf,
  bin: {
    origin: 'spider.bin',
    path: bin,
  },
});
