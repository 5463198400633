import { MaterialType } from '~core/client/assets/materials/types';
import { Circle } from '~core/client/render-item/shape/circle';

import type { Building } from '..';

import './resources';

export class ActionArea extends Circle {
  constructor(building: Building, radius: number) {
    super(building.battle.scene, {
      material: MaterialType.ActionArea,
      radius,
      visible: false,
    });

    building.renderItem.object.add(this.object);
  }
}
