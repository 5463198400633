import React, { useEffect } from 'react';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRequest } from '~core/client/room/ui/hooks/use-request';
import { Text } from '~core/client/ui/components/text';

import { RelayRequest } from '~feature/shared/relay/types';

import styles from './styles.module.scss';

import IconSearching from './icons/searching.svg';

export const SearchingOpponent: React.FC = () => {
  const language = useLanguage();

  const stopSearchingOpponent = useRequest(RelayRequest.StopSearchingOpponent);

  useEffect(() => {
    return () => {
      stopSearchingOpponent.fetch();
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <IconSearching />
      <Text size='m' align='center' bold uppercase className={styles.label}>
        {language('SearchingOpponent')}
      </Text>
    </div>
  );
};
