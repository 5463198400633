import type { Event } from 'make-event';
import { useState } from 'react';

import { useEvent } from './use-event';

export function useEventValue<T>(
  event: Event<T>,
  defaultValue: T,
) {
  const [value, setValue] = useState(defaultValue);

  useEvent(event, setValue, []);

  return value;
}
