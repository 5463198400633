import { PointsMaterial, Color } from 'three';

import { Assets } from '~core/client/assets';
import { MaterialType } from '~core/client/assets/materials/types';

import {
  RELAY_SCENE_SPACE_DUST_LAYERS,
  RELAY_SCENE_SPACE_DUST_OPACITY,
  RELAY_SCENE_SPACE_DUST_SIZE,
} from '../../const';

import textureDust from './dust.png';

const texture = Assets.createTexture(textureDust);

RELAY_SCENE_SPACE_DUST_LAYERS.forEach((depth, i) => {
  // @ts-ignore
  Assets.addMaterial(MaterialType[`Dust${i}`], new PointsMaterial({
    map: texture,
    blending: 1,
    depthTest: depth < 0,
    size: RELAY_SCENE_SPACE_DUST_SIZE,
    transparent: true,
    color: new Color(i % 2 === 0 ? 0x4ee7a4 : 0x72cbff),
    opacity:
      RELAY_SCENE_SPACE_DUST_OPACITY *
      (RELAY_SCENE_SPACE_DUST_LAYERS.length - 1 - i),
  }));
});
