import type { Room as OriginRoom } from 'colyseus.js';

import { AudioType } from '~core/client/audio/types';
import { Client } from '~core/client/client';
import { Room } from '~core/client/room';

import { RelayMessage } from '~feature/shared/relay/types';

import { RelayScene } from './scene';
import type { RelaySchema } from './types';
import { RelayScreen } from './ui';

export class Relay extends Room<RelaySchema> {
  declare public readonly scene: RelayScene;

  constructor(room: OriginRoom<RelaySchema>) {
    super(room);

    this.scene = new RelayScene(this);

    const unlisten = this.state.onChange(() => {
      unlisten();

      this.scene.addDroidModel();
      this.scene.addPlayerModel();

      this.setUI(RelayScreen);
      Client.hideLoading();
    });

    room.onMessage(RelayMessage.JoinBattle, (id) => {
      if (document.hidden) {
        this.scene.audio.play2D(AudioType.Join);
      }

      Client.joinRoomById(id);
    });
  }

  override destroy(): void {
    super.destroy();

    this.scene.destroy();
  }
}
