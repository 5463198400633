import cn from 'classnames';
import React from 'react';

import { Icon } from '~core/client/ui/components/icon';

import type { SkillVariant } from '~feature/shared/battle/entity/unit/player/skill/types';

import { SKILL_ICON } from '../../skill-icon/const';

import styles from './styles.module.scss';

type Props = {
  variant: SkillVariant;
  using?: boolean;
};

export const Label: React.FC<Props> = ({ variant, using }) => (
  <Icon type={SKILL_ICON[variant]} className={cn(styles.icon, {
    [styles.using]: using,
  })} />
);

