import React from 'react';
import { useRecoilValue } from 'recoil';

import { CurrentTutorialStepState } from '~feature/client/battle/tutorial/ui/state';

import { useBuilding } from '../../hooks/use-building';

import styles from './styles.module.scss';

import IconArrow from './icons/arrow.svg';

export const UpgradeHint: React.FC = () => {
  const building = useBuilding();

  const currentTutorialStep = useRecoilValue(CurrentTutorialStepState);

  const visible = (
    building.selfOwn &&
    currentTutorialStep === `Upgrade${building.schema.variant}`
  );

  return visible && (
    <IconArrow className={styles.icon} />
  );
};

