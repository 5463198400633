import { ModelType } from '~core/client/assets/types';

import { BuildingMessage, BuildingVariant } from '~feature/shared/battle/entity/building/types';

export const BUILDING_HOTKEY_UPGRADE = 'KeyE';

export const BUILDING_HOTKEY_REPAIR = 'KeyR';

export const BUILDING_MODEL_UPGRADE_PREFIX = '@Upgrade';

export const BUILDING_REPAIR_ALERT_FACTOR = 0.33;

export const BUILDING_HOTKEYS: Record<string, BuildingMessage> = {
  [BUILDING_HOTKEY_UPGRADE]: BuildingMessage.DoUpgrade,
  [BUILDING_HOTKEY_REPAIR]: BuildingMessage.DoRepair,
};

export const BUILDING_MODELS: Record<BuildingVariant, ModelType> = {
  [BuildingVariant.Base]: ModelType.BuildingBase,
  [BuildingVariant.Ammunition]: ModelType.BuildingAmmunition,
  [BuildingVariant.Generator]: ModelType.BuildingGenerator,
  [BuildingVariant.Radar]: ModelType.BuildingRadar,
  [BuildingVariant.Wall]: ModelType.BuildingWall,
  [BuildingVariant.SpawnerSmall]: ModelType.BuildingSpawnerSmall,
  [BuildingVariant.SpawnerMedium]: ModelType.BuildingSpawnerMedium,
  [BuildingVariant.SpawnerLarge]: ModelType.BuildingSpawnerLarge,
  [BuildingVariant.TowerFire]: ModelType.BuildingTowerFire,
  [BuildingVariant.TowerElectro]: ModelType.BuildingTowerElectro,
  [BuildingVariant.TowerFrost]: ModelType.BuildingTowerFrost,
  [BuildingVariant.TowerLaser]: ModelType.BuildingTowerLaser,
  [BuildingVariant.Trap]: ModelType.BuildingTrap,
};
