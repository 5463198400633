import type React from 'react';

import { BattleStage } from '~feature/shared/battle/types';

import { StageFinished } from './finished';
import { StageStarted } from './started';
import { StageWaitReady } from './wait-ready';

export const BATTLE_STAGE_COMPONENT_MAP: Partial<Record<BattleStage, Nullable<React.FC>>> = {
  [BattleStage.WaitReady]: StageWaitReady,
  [BattleStage.Started]: StageStarted,
  [BattleStage.Finished]: StageFinished,
};
