import { DroidVariant } from '~feature/shared/battle/entity/unit/npc/droid/types';

import IconCombat from './icons/combat.svg';
import IconMechanic from './icons/mechanic.svg';
import IconMedic from './icons/medic.svg';

export const DROID_ICON: Record<DroidVariant, SVGIcon> = {
  [DroidVariant.Mechanic]: IconMechanic,
  [DroidVariant.Medic]: IconMedic,
  [DroidVariant.Combat]: IconCombat,
};
