import React from 'react';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { SDK } from '~core/client/sdk';
import { SDKAdType } from '~core/client/sdk/types';
import { Button } from '~core/client/ui/components/button';

import type { Battle } from '~feature/client/battle';
import { Resources } from '~feature/client/battle/ui/components/amounts/resources';
import { BattleMessage } from '~feature/shared/battle/types';

import { ADS_REWARD_RESOURCES_PER_WAVE } from './const';

import styles from './styles.module.scss';

import IconAds from './icons/ads.svg';

type Props = {
  onComplete: VoidFunction;
};

export const AdsReward: React.FC<Props> = ({ onComplete }) => {
  if (!SDK.enabled) {
    return null;
  }

  const battle = useRoom<Battle>();
  const language = useLanguage();

  const resources = (battle.wave?.schema.number ?? 1) * ADS_REWARD_RESOURCES_PER_WAVE;

  const handleClick = () => {
    SDK.showAd(SDKAdType.Rewarded).then((success) => {
      onComplete();
      if (success) {
        battle.messages.send(BattleMessage.GetReward, { resources });
      }
    });
  };

  return (
    <Button onClick={handleClick} icon={IconAds}>
      {language('Get')}
      <div className={styles.amount}>
        <Resources value={resources} size='m' />
      </div>
    </Button>
  );
};
