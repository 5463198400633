import React from 'react';
import { useRecoilState } from 'recoil';

import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { ButtonBack } from '~core/client/ui/components/button/back';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';

import { useUserData } from '~feature/client/relay/ui/hooks/use-user-data';
import { SKILL_MAX_SLOTS } from '~feature/shared/battle/entity/unit/player/skill/const';

import { Slot } from './slot';
import { SlotBuy } from './slot-buy';
import { InventorySlotSelectState } from './state';

import styles from './styles.module.scss';

export const SlotSelect: React.FC = () => {
  const language = useLanguage();

  const skillSlots = useUserData('skillSlots');

  const [slotSelect, setSlotSelect] = useRecoilState(InventorySlotSelectState);

  const handleClose = () => {
    setSlotSelect(null);
  };

  return slotSelect && (
    <Section direction='vertical' align='center' className={styles.content} gap={64}>
      <Section direction='vertical' align='center' gap={12}>
        <Text size='l' uppercase>
          {language('SelectSlotForSkill')}
        </Text>
        <Text size='2xl' uppercase bold view='primary'>
          {language(`${slotSelect}Name`)}
        </Text>
      </Section>
      <Section direction='horizontal' align='center' gap={32}>
        {Array(skillSlots).fill(null).map((_, slot) => (
          <Slot key={slot} index={slot} />
        ))}
        {skillSlots < SKILL_MAX_SLOTS && (
          <SlotBuy />
        )}
      </Section>
      <ButtonBack onClick={handleClose} />
    </Section>
  );
};
