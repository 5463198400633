/**
 * @collide SceneLayer
 */
export enum BattleSceneLayer {
  Liquid = 2,
  Ground = 3,
  Mounts = 4,
  Unit = 5,
  Building = 6,
  Marker = 7,
  Fog = 8,
}
