import { MaterialType } from '~core/client/assets/materials/types';
import { AudioType } from '~core/client/audio/types';
import { Line } from '~core/client/render-item/shape/line';

import type { Battle } from '~feature/client/battle';
import type { LaserSchema } from '~feature/shared/battle/entity/shot/laser/types';

import { Shot } from '../..';

import './resources';

export class Laser extends Shot {
  declare public readonly schema: LaserSchema;

  declare public readonly renderItem: Line;

  constructor(battle: Battle, schema: LaserSchema) {
    const renderItem = new Line(battle.scene, {
      material: MaterialType.LaserLine,
      position: schema.position,
      positionEnd: schema.positionTarget,
      alpha: 0.5,
    });

    super(battle, {
      renderItem,
      audioEffect: AudioType.ShotLaser,
    }, schema);

    this.schema.positionTarget.onChange(() => {
      this.renderItem.setPositionEnd(schema.positionTarget);
    });
  }
}
