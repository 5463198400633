import React from 'react';
import { useRecoilValue } from 'recoil';

import { Section } from '~core/client/ui/components/section';
import { useRelativeScreen } from '~core/client/ui/hooks/use-relative-screen';

import { Controls } from './components/controls';
import { Logotype } from './components/logotype';
import { Menu } from './components/menu';
import { Page } from './components/pages';
import { PageState } from './components/pages/state';
import { PageType } from './components/pages/types';
import { PlayersOnline } from './components/players-online';
import { UserPanel } from './components/user-panel';
import { VersionTag } from './components/version-tag';

import styles from './styles.module.scss';

export const RelayScreen: React.FC = () => {
  const refScreen = useRelativeScreen();

  const page = useRecoilValue(PageState);

  return (
    <div ref={refScreen} className={styles.screen}>
      {page === PageType.Play ? (
        <>
          <div className={styles.wrapper}>
            <div className={styles.sidebar}>
              <Section direction="vertical" gap={32}>
                <Logotype />
                <UserPanel />
                <Menu />
              </Section>
              <PlayersOnline />
            </div>
            <div className={styles.content}>
              <Page />
            </div>
          </div>
          <Controls />
          <div className={styles.shadow} />
          <VersionTag />
        </>
      ) : (
        <Page />
      )}
    </div>
  );
};
