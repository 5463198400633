import { Progression } from '~core/shared/progression';
import { ProgressionMethod } from '~core/shared/progression/type';

import { UPGRADE_COST_GROWTH, UPGRADE_MAX_LEVEL } from '../const';

export class UpgradeUtils {
  public static costProgression(cost: number) {
    return new Progression({
      defaultValue: cost,
      growth: UPGRADE_COST_GROWTH,
      method: ProgressionMethod.Quadratic,
      maxLevel: UPGRADE_MAX_LEVEL,
      round: 10,
    });
  }
}
