import { useEffect } from 'react';

import { useEvent } from '~core/client/ui/hooks/use-event';

import type { Entity } from '../..';

export function useEntityUpdate(entity: Entity, callback: VoidFunction) {
  useEffect(callback, []);

  useEvent(entity.onUpdate, callback, []);
}
