import { ModelType } from '~core/client/assets/types';

import type { Battle } from '~feature/client/battle';
import type { BuildingSchema } from '~feature/shared/battle/entity/building/types';

import { Building } from '../..';

import './resources';

export class BuildingRadar extends Building {
  constructor(battle: Battle, schema: BuildingSchema) {
    super(battle, {
      model: ModelType.BuildingRadar,
    }, schema);
  }

  override onReady(): void {
    super.onReady();

    this.renderItem.animator.play('rotate', { timeScale: 0.1 });
  }
}
