import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { Text } from '~core/client/ui/components/text';

import { usePlayer } from '../../hooks/use-player';

import styles from './styles.module.scss';

export const LevelLabel: React.FC = () => {
  const player = usePlayer();

  const level = useSchemaValue(player.schema, 'level');

  return (
    <Text size='xs' bold mono align='center' className={styles.value}>
      {level}
    </Text>
  );
};
