import { Assets } from '~core/client/assets';
import { ModelType } from '~core/client/assets/types';

import bin from './tower-laser.bin';
import gltf from './tower-laser.gltf';

Assets.addModel(ModelType.BuildingTowerLaser, {
  gltf,
  bin: {
    origin: 'tower-laser.bin',
    path: bin,
  },
});
