import type { Vector3Like } from 'three';

import { PREDICTION_MAX_BUFFER_SIZE } from './const';
import type { PredictionBufferRecord } from './types';

export class Prediction {
  private tick: number = 0;

  private readonly buffer: PredictionBufferRecord[] = [];

  public addState(velocity: Vector3Like, delta: number) {
    if (this.buffer.length === PREDICTION_MAX_BUFFER_SIZE) {
      this.buffer.splice(0, 1);
    }

    this.buffer.push({
      tick: ++this.tick,
      velocity: { ...velocity },
      delta,
    });

    return this.tick;
  }

  public useState(tick: number, callback: (velocity: Vector3Like, delta: number) => void) {
    let j = 0;
    while (j < this.buffer.length) {
      const state = this.buffer[j];
      if (state.tick <= tick) {
        this.buffer.splice(j, 1);
      } else {
        callback(state.velocity, state.delta);
        j++;
      }
    }
  }
}
