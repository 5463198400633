import { MaterialType } from '~core/client/assets/materials/types';
import { ModelType } from '~core/client/assets/types';
import { Line } from '~core/client/render-item/shape/line';

import type { Battle } from '~feature/client/battle';
import type { DroidMechanicSchema } from '~feature/shared/battle/entity/unit/npc/droid/variants/builder/types';

import { Droid } from '../..';

import './resources';

export class DroidMechanic extends Droid {
  declare public readonly schema: DroidMechanicSchema;

  private readonly laser: Line;

  constructor(battle: Battle, schema: DroidMechanicSchema) {
    super(battle, {
      model: ModelType.DroidMechanic,
    }, schema);

    this.laser = new Line(this.battle.scene, {
      material: MaterialType.RepairLine,
      position: this.renderItem.position.clone()
        .add({ x: 0, y: 0.4, z: 0 }),
      positionEnd: this.schema.positionTarget,
      visible: this.schema.repairing,
      alpha: 0.5,
    });

    this.schema.listen('activeLaser', (activeLaser) => {
      this.laser.setVisible(activeLaser);
    });
  }

  override destroy(): void {
    super.destroy();

    this.laser.destroy();
  }

  override onSceneUpdate(): void {
    super.onSceneUpdate();

    if (this.laser.visible) {
      this.laser.setPosition(
        this.renderItem.position.clone().add({ x: 0, y: 0.4, z: 0 }),
      );
      this.laser.setPositionEnd(this.schema.positionTarget);
    }
  }

  override rotateToVelocity() {
    if (this.schema.repairing) {
      this.rotateToPosition(this.schema.positionTarget);
    } else {
      super.rotateToVelocity();
    }
  }
}

