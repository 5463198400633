import cn from 'classnames';
import React, { useCallback } from 'react';

import { Interactive } from '~core/client/ui/components/interactive';

import { useInput } from '../input/hooks/use-input';
import { Text } from '../text';

import styles from './styles.module.scss';

type Props = {
  name: string;
  defaultChecked?: boolean;
  children: React.ReactNode;
};

export const Checkbox: React.FC<Props> = ({
  name,
  defaultChecked = false,
  children,
}) => {
  const { invalid, value, setValue } = useInput(name, defaultChecked);

  const handleClick = useCallback(() => {
    setValue(!value);
  }, [value]);

  return (
    <div className={styles.checkbox}>
      <Interactive
        onClick={handleClick}
        className={cn(styles.marker, {
          [styles.checked]: value,
          [styles.invalid]: invalid,
        })}
      />
      <Text size='s'>{children}</Text>
    </div>
  );
};
