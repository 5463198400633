import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect } from 'react';

import { AudioType } from '~core/client/audio/types';
import { useAudio } from '~core/client/audio/ui/hooks/use-audio';
import { Client } from '~core/client/client';
import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { SDK } from '~core/client/sdk';
import { SDKAdType } from '~core/client/sdk/types';
import { Interactive } from '~core/client/ui/components/interactive';
import { Text } from '~core/client/ui/components/text';
import { useNextEffect } from '~core/client/ui/hooks/use-next-effect';

import type { Battle } from '~feature/client/battle';
import { BattleDifficult } from '~feature/shared/battle/types';

import { formatTime } from './utils/format-time';

import styles from './styles.module.scss';

import IconPause from './icons/pause.svg';
import IconSkip from './icons/skip.svg';

export const WaveInfoPanel: React.FC = () => {
  const battle = useRoom<Battle>();
  if (!battle.wave) {
    return null;
  }

  const audio = useAudio();
  const language = useLanguage();

  const battlePaused = useSchemaValue(battle.state, 'paused');

  const going = useSchemaValue(battle.wave.schema, 'going');
  const number = useSchemaValue(battle.wave.schema, 'number');
  const timeleft = useSchemaValue(battle.wave.schema, 'timeleft');
  const mobsLeft = useSchemaValue(battle.wave.schema, 'mobsLeft');
  const paused = useSchemaValue(battle.wave.schema, 'paused');

  const timeleftSeconds = Math.ceil(timeleft / 1000);
  const tutorialCompleted = (
    number === 6 &&
    battle.state.difficult === BattleDifficult.Tutorial
  );

  const handleClickSkip = () => {
    battle.wave?.skipTimeleft();
  };

  useNextEffect(async () => {
    if (SDK.enabled && (number - 1) % 5 === 0) {
      battle.setPause(true);
      await SDK.showAd(SDKAdType.Commercial);
      battle.setPause(false);
    }
  }, [number]);

  useEffect(() => {
    if (tutorialCompleted) {
      Client.completeTutorial();
    }
  }, [tutorialCompleted]);

  useEffect(() => {
    if (!paused && timeleftSeconds <= 3 && timeleftSeconds > 0) {
      audio.play(AudioType.WaveTick);
    }
  }, [paused, timeleftSeconds]);

  return (
    <div className={styles.container}>
      {paused ? (
        <div className={cn(styles.frame, styles.paused)}>
          <IconPause className={styles.icon} />
        </div>
      ) : (
        <div className={cn(styles.frame, styles.number, {
          [styles.going]: going,
        })}>
          <Text size='xl' mono bold>{number}</Text>
        </div>
      )}
      <div className={styles.info}>
        {going ? (
          <>
            <Text size='l' mono bold>
              {mobsLeft.toString().padStart(2, '0')}
            </Text>
            <Text size='2xs' view='secondary' uppercase>
              {language('EnemiesLeft')}
            </Text>
          </>
        ) : (
          <>
            <Text size='l' mono bold>
              {formatTime(timeleft)}
            </Text>
            <Text size='2xs' view='secondary' uppercase>
              {language('UntilWaveStart')}
            </Text>
          </>
        )}
      </div>
      {!going && !paused && (
        <Interactive
          onClick={handleClickSkip}
          disabled={battlePaused}
          className={cn(styles.skip, {
            [styles.active]: !battlePaused,
          })}
        >
          <IconSkip className={styles.icon} />
        </Interactive>
      )}
    </div>
  );
};
