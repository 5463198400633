import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { Client } from '~core/client/client';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';

import type { Player } from '..';
import { EntityPanel } from '../../../ui/components/entity-panel';
import { HealthBar } from '../../../ui/components/health-bar';

import { Indicators } from './components/indicators';
import { LevelLabel } from './components/level-label';
import { SkillLabels } from './components/skill-labels';
import { StaminaBar } from './components/stamina-bar';
import { PlayerContext } from './context';

import styles from './styles.module.scss';

type Props = {
  target: Player;
};

export const PlayerUI: React.FC<Props> = ({ target }) => {
  const name = useSchemaValue(target.schema, 'name');

  return (
    <PlayerContext.Provider value={target}>
      <EntityPanel
        renderItem={target.renderItem}
        indicators={<Indicators />}
        meta={
          <Section direction="vertical" align="center" gap={8}>
            {target.schema.id !== Client.sessionId && (
              <SkillLabels />
            )}
            <Section direction="horizontal" align="center" gap={4}>
              <LevelLabel />
              {name && (
                <Text size='xs' className={styles.name}>{name}</Text>
              )}
            </Section>
          </Section>
        }
        bars={
          <div className={styles.bars}>
            <HealthBar unit={target} />
            <StaminaBar />
          </div>
        }
      >
      </EntityPanel>
    </PlayerContext.Provider>
  );
};
