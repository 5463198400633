import { ModelType } from '~core/client/assets/types';

import type { Battle } from '~feature/client/battle';
import type { BuildingSchema } from '~feature/shared/battle/entity/building/types';

import { Building } from '../..';

import './resources';

export class BuildingWall extends Building {
  constructor(battle: Battle, schema: BuildingSchema) {
    super(battle, {
      model: ModelType.BuildingWall,
    }, schema);
  }
}
