import cn from 'classnames';
import React from 'react';

import { Device } from '~core/client/device';
import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { Overlay } from '~core/client/ui/components/overlay';
import { Section } from '~core/client/ui/components/section';

import type { Battle } from '~feature/client/battle';
import { BuilderScreen } from '~feature/client/battle/builder/ui/components/builder-screen';
import { ButtonAttack } from '~feature/client/battle/entity/unit/player/ui/components/button-attack';
import { DamageEffect } from '~feature/client/battle/entity/unit/player/ui/components/damage-effect';
import { PlayerInfoPanel } from '~feature/client/battle/entity/unit/player/ui/components/info-panel';
import { Joystick } from '~feature/client/battle/entity/unit/player/ui/components/joystick';
import { LevelPanel } from '~feature/client/battle/entity/unit/player/ui/components/level-panel';
import { SkillPanel } from '~feature/client/battle/entity/unit/player/ui/components/skill-panel';
import { UpgradesScreen } from '~feature/client/battle/entity/unit/player/ui/components/upgrades-screen';
import { Minimap } from '~feature/client/battle/minimap/ui';
import { TutorialHints } from '~feature/client/battle/tutorial/ui/components/hints';
import { WaveInfoPanel } from '~feature/client/battle/wave/ui/components/info-panel';
import { WaveNotice } from '~feature/client/battle/wave/ui/components/notice';
import { BattleMode } from '~feature/shared/battle/types';

import { useScreen } from '../../../hooks/use-screen';
import { ScreenType } from '../../../types';
import { DebugMessages } from '../../debug-messages';
import { Metrics } from '../../metrics';

import { Alert } from './alert';
import { MenuButton } from './menu-button';
import { MenuScreen } from './menu-screen';
import { NewBuildingsScreen } from './new-buildings-screen';
import { ScreenButton } from './screen-button';

import styles from './styles.module.scss';

export const StageStarted: React.FC = () => {
  const battle = useRoom<Battle>();
  const { screen } = useScreen();

  const allowControls = battle.state.mode === BattleMode.Online;

  return (
    <div className={styles.screen}>
      <div className={styles.grid}>
        <div className={cn(styles.column, styles.left)}>
          <Section direction="vertical" gap={32} className={styles.front}>
            <LevelPanel />
            <div className={styles.buttons}>
              <ScreenButton type={ScreenType.Upgrades} />
              <ScreenButton type={ScreenType.Builder} />
            </div>
          </Section>
          {__MODE === 'development' && (
            <Metrics />
          )}
          {Device.isMobile && (
            <div className={cn({
              [styles.front]: allowControls,
            })}>
              <Joystick />
            </div>
          )}
        </div>
        <div className={cn(styles.column, styles.center)}>
          <Section direction='vertical' align='center' gap={16}>
            <Section direction='horizontal' gap={16} className={styles.front}>
              <PlayerInfoPanel />
              {battle.state.mode === BattleMode.Single && (
                <WaveInfoPanel />
              )}
            </Section>
            {battle.state.mode === BattleMode.Single && (
              <WaveNotice />
            )}
          </Section>
          {__MODE === 'development' && Device.isMobile && (
            <DebugMessages />
          )}
          <Section direction='vertical' align='center' gap={32}>
            <Alert />
            {Device.isDesktop && (
              <SkillPanel />
            )}
          </Section>
        </div>
        <div className={cn(styles.column, styles.right)}>
          <Section direction="vertical" className={styles.front}>
            <MenuButton />
            <Minimap />
          </Section>
          {Device.isMobile && (
            <div className={cn({
              [styles.front]: allowControls,
            })}>
              <ButtonAttack />
              <SkillPanel />
            </div>
          )}
        </div>
      </div>
      <Overlay visible={Boolean(screen)} blur={battle.state.mode === BattleMode.Single}>
        <BuilderScreen />
        <UpgradesScreen />
        <MenuScreen />
        <NewBuildingsScreen />
      </Overlay>
      <TutorialHints />
      <DamageEffect />
    </div>
  );
};
