import { useSchemaListener } from 'colyseus-schema-hooks';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Device } from '~core/client/device';
import { useRoom } from '~core/client/room/ui/hooks/use-room';
import { useBatch } from '~core/client/ui/hooks/use-batch';
import { useEventValue } from '~core/client/ui/hooks/use-event-value';

import type { Battle } from '~feature/client/battle';
import { useScreen } from '~feature/client/battle/ui/hooks/use-screen';
import { TutorialStep, TutorialStepState } from '~feature/shared/battle/tutorial/types';

import { CurrentTutorialStepState, ActiveTutorialStepsState } from '../../state';

import { TutorialContent } from './content';

import styles from './styles.module.scss';

export const TutorialHints: React.FC = () => {
  const { builder, state } = useRoom<Battle>();

  const steps = state.tutorial?.steps;
  if (!steps) {
    return;
  }

  const { screen } = useScreen();

  const [, setActiveSteps] = useRecoilState(ActiveTutorialStepsState);
  const currentStep = useRecoilValue(CurrentTutorialStepState);

  const build = useEventValue(builder.onToggleBuild, false);

  const active = currentStep && !build && !screen && (
    // TODO: Make abstracted
    currentStep !== TutorialStep.Attack ||
    Device.isDesktop
  );

  const update = useBatch(() => {
    const activeSteps = new Set<TutorialStep>();
    steps.forEach((state, step) => {
      if (state === TutorialStepState.Active) {
        activeSteps.add(step);
      }
    });

    setActiveSteps(activeSteps);
  });

  useSchemaListener(steps, {
    onAdd: update,
    onChange: update,
    onRemove: update,
  }, [steps]);

  return active && (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <TutorialContent step={currentStep} />
      </div>
    </div>
  );
};
