import { ModelType } from '~core/client/assets/types';

import type { Battle } from '~feature/client/battle';
import type { MobSchema } from '~feature/shared/battle/entity/unit/npc/mob/types';

import { Mob } from '../..';

import './resources';

export class MobTurtle extends Mob {
  constructor(battle: Battle, schema: MobSchema) {
    super(battle, {
      model: ModelType.MobTurtle,
    }, schema);
  }
}

