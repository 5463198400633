import React from 'react';
import { useRecoilState } from 'recoil';

import { AudioType } from '~core/client/audio/types';
import { useAudio } from '~core/client/audio/ui/hooks/use-audio';
import { useLanguage } from '~core/client/language/ui/hooks/use-language';
import { useRequest } from '~core/client/room/ui/hooks/use-request';
import { SDK } from '~core/client/sdk';
import { SDKAdType } from '~core/client/sdk/types';
import { Crystals } from '~core/client/ui/components/amount/crystals';
import { Button } from '~core/client/ui/components/button';
import { ButtonBack } from '~core/client/ui/components/button/back';
import { Icon } from '~core/client/ui/components/icon';
import { Section } from '~core/client/ui/components/section';
import { Text } from '~core/client/ui/components/text';

import { InventoryRequest } from '~feature/shared/mixed/inventory/types';
import { InventoryUtils } from '~feature/shared/mixed/inventory/utils';

import { INVENTORY_ITEM_ICON } from '../../../const';
import { InventorySlotSelectState } from '../slot-select/state';

import { Parameters } from './parameters';
import { InventoryPurchaseState } from './state';

import styles from './styles.module.scss';

import IconAds from './icons/ads.svg';

export const Purchase: React.FC = () => {
  const language = useLanguage();
  const audio = useAudio();

  const getCommercialItem = useRequest(InventoryRequest.GetCommercialItem);
  const buyItem = useRequest(InventoryRequest.BuyItem);

  const [data, setPurchase] = useRecoilState(InventoryPurchaseState);
  const [, setSlotSelect] = useRecoilState(InventorySlotSelectState);

  const commercial =
    SDK.enabled && data && InventoryUtils.isItemCommercial(data.item);

  const handleGetCommercial = async () => {
    if (!data) {
      return;
    }

    const success = await SDK.showAd(SDKAdType.Rewarded);
    if (success) {
      await getCommercialItem.fetch({
        item: data.item,
      });

      audio.play(AudioType.Purchase);
    }

    setSlotSelect(data.item);
    setPurchase(null);
  };

  const handleBuy = async () => {
    if (!data) {
      return;
    }

    if (!data.allow) {
      audio.play(AudioType.Error);
      return;
    }

    await buyItem.fetch({
      item: data.item,
    });

    audio.play(AudioType.Purchase);

    setSlotSelect(data.item);
    setPurchase(null);
  };

  const handleBack = () => {
    setPurchase(null);
  };

  return (
    data && (
      <Section
        direction="vertical"
        align="center"
        className={styles.content}
        gap={64}
      >
        <Section direction="horizontal" align="center" gap={32}>
          <div className={styles.meta}>
            <div className={styles.frame}>
              <Icon
                type={INVENTORY_ITEM_ICON[data.item]}
                className={styles.icon}
              />
            </div>
          </div>
          <Section direction="vertical" gap={12}>
            <Text size="2xl" view="primary" bold uppercase>
              {language(`${data.item}Name`)}
            </Text>
            <Text size="l">{language(`${data.item}Description`)}</Text>
            <Parameters item={data.item} />
          </Section>
        </Section>
        <Section direction="horizontal" gap={16}>
          {commercial && (
            <Button
              onClick={handleGetCommercial}
              view="accent"
              icon={IconAds}
              loading={getCommercialItem.loading}
            >
              {language('Get')}
            </Button>
          )}
          <Button
            onClick={handleBuy}
            view={commercial ? undefined : data.allow ? 'accent' : 'negative'}
            loading={buyItem.loading}
          >
            {language('Buy')} <Crystals value={data.cost} size="s" />
          </Button>
          <ButtonBack onClick={handleBack} />
        </Section>
      </Section>
    )
  );
};
