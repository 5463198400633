import React from 'react';
import { useRecoilState } from 'recoil';

import { AudioType } from '~core/client/audio/types';
import { useAudio } from '~core/client/audio/ui/hooks/use-audio';
import { useRequest } from '~core/client/room/ui/hooks/use-request';
import { Icon } from '~core/client/ui/components/icon';
import { Interactive } from '~core/client/ui/components/interactive';

import { InventoryItemType } from '~feature/shared/mixed/inventory/item/types';
import { InventoryRequest } from '~feature/shared/mixed/inventory/types';

import { INVENTORY_ITEM_ICON } from '../../../../const';
import { useInventoryItem } from '../../../../hooks/use-inventory-item';
import { InventorySlotSelectState } from '../state';

import styles from './styles.module.scss';

type Props = {
  index: number;
};

export const Slot: React.FC<Props> = ({ index }) => {
  const audio = useAudio();

  const updateSlot = useRequest(InventoryRequest.UpdateSlot);

  const current = useInventoryItem(InventoryItemType.Skill, index);
  const [slotSelect, setSlotSelect] = useRecoilState(InventorySlotSelectState);

  const handleClick = async () => {
    audio.play(AudioType.Select);

    await updateSlot.fetch({
      item: slotSelect,
      slot: index,
    });

    setSlotSelect(null);
  };

  return (
    <Interactive
      onClick={handleClick}
      disabled={updateSlot.loading}
      className={styles.frame}
    >
      {current ? (
        <Icon type={INVENTORY_ITEM_ICON[current]} className={styles.icon} />
      ) : (
        <div className={styles.dot} />
      )}
    </Interactive>
  );
};
